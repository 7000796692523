<script setup lang="ts">
import { BN } from "@flarenetwork/flarejs";
import { useWalletStore } from "@/stores/wallet";
import AppEmblem from "./icons/AppEmblem.vue";
import {
  getStateOfValidatorRewards,
  bnToFloat,
  claimValidatorReward,
  // @ts-ignore
} from "@/helpers/Flare";
import { ref, computed, onMounted } from "vue";

// @ts-ignore
import CheckBox from "@/components/CheckBox.vue";
// @ts-ignore
import ProgressModal from "@/components/ProgressModal.vue";

const wallet = useWalletStore();

const totalReward = ref(new BN(0));
const claimedReward = ref(new BN(0));

const wrapClaim = ref(false);
const progressModal = ref(null);

onMounted(async () => {
  await updateRewardBalances();
});

async function updateRewardBalances() {
  let { totalReward: _totalReward, claimedReward: _claimedReward } =
    await getStateOfValidatorRewards(wallet);

  totalReward.value = _totalReward;
  claimedReward.value = _claimedReward;
}

const claimableReward = computed(() =>
  totalReward.value.sub(claimedReward.value)
);

function claim() {
  const claimAllTransaction = claimValidatorReward(
    claimableReward.value,
    wrapClaim.value
  );
  let msg = `Claiming ${wrapClaim.value ? "& Wrapping " : ""}${bnToFloat(
    claimableReward.value.toString()
  ).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6,
  })} ${wallet.tokenName("native")}`;
  // @ts-ignore
  progressModal.value.confirm(claimAllTransaction, msg);
}

async function complete() {
  await updateRewardBalances();
  wallet.updateBalancesV2();
}
</script>

<template>
  <ProgressModal
    ref="progressModal"
    id="progressModal-validator-rewards"
    @complete="complete"
  />

  <div
    class="rounded-lg w-full border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 mb-2 py-2 px-4"
  >
    <div class="text-sm font-bold text-gray-500 dark:text-gray-200 pb-1">
      Staked Validators
    </div>
    <div v-for="validator of wallet.staking.nodes" :key="validator.nodeID">
      <div class="mb-2">
        <div class="flex justify-between items-center">
          <div
            class="flex font-semibold items-center gap-x-1"
            v-if="
              validator.nodeID == 'NodeID-HZwFckGTbucqTtE7qcv6z85mnScUfAeW6'
            "
          >
            <AppEmblem class="w-5 h-5 mb-[2px]" />
            FTSO AU
          </div>
          <div class="font-semibold" v-else>Name</div>
          <div>
            {{
              Number(validator.stakeAmount).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 6,
              })
            }}
            FLR
          </div>
        </div>
        <p class="text-sm text-gray-500 dark:text-gray-200">
          {{ validator.nodeID }}
        </p>
      </div>
    </div>
    <div
      v-if="!wallet.staking.nodes?.length"
      class="text-gray-500 dark:text-gray-200 text-sm"
    >
      No delegations to validators found...
    </div>
  </div>

  <div
    class="rounded-lg w-full border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 mb-2 py-2 px-4"
  >
    <div class="text-sm font-bold text-gray-500 dark:text-gray-200 pb-1">
      Rewards
    </div>

    <div
      class="w-full grid grid-cols-2 text-sm text-gray-500 dark:text-gray-300"
    >
      <div>Claimable:</div>
      <div class="text-right">
        {{
          bnToFloat(claimableReward.toString()).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 6,
          })
        }}
        FLR
      </div>
      <div>Total Claimed:</div>
      <div class="text-right">
        {{
          bnToFloat(claimedReward.toString()).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 6,
          })
        }}
        FLR
      </div>
      <div>Total Rewards:</div>
      <div class="text-right">
        {{
          bnToFloat(totalReward.toString()).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 6,
          })
        }}
        FLR
      </div>
    </div>
  </div>

  <!-- Checkbox  -->
  <div class="flex gap-x-3 my-3 justify-between">
    <div class="text-sm text-gray-600 dark:text-gray-400">
      Wrap Tokens On Claim
    </div>
    <CheckBox v-model="wrapClaim" />
  </div>

  <!-- Buttons -->

  <div>
    <ConnectWallet v-if="!wallet.account" class="w-full" />

    <div
      v-else-if="Number(claimableReward) == 0"
      class="rounded-xl text-center py-2 text-gray-500 border dark:border-slate-500 select-none"
    >
      No Rewards
    </div>

    <button
      v-else
      @click="claim()"
      class="w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
    >
      Claim All
    </button>
  </div>
</template>
