<script setup lang="ts">
import TheModal from "@/components/TheModal.vue";
import RoundedContainer from "@/components/RoundedContainer.vue";
import { useTruncateString as truncate } from "@/helpers/TruncateString";
import {
  ProviderListItem,
  providersList as providerListJson,
} from "@/helpers/ProvidersList";
import { ref, onMounted, computed } from "vue";
import type { Ref } from "vue";
import { ethers } from "ethers";
import { useNotificationStore } from "@/stores/notification";

const notification = useNotificationStore();

defineExpose({ show, hide });
const emit = defineEmits(["select"]);

const modal = ref();
const providersList: Ref<Array<ProviderListItem>> = ref([]);
const search = ref("");
const customDataProviderAddress = ref("");
onMounted(() => {
  hide();
  providersList.value = providerListJson.providers.filter(
    // @ts-ignore
    (i) => i.chainId == window.ethereum.chainId
  );
  if (!providersList.value.length) return;
  let ftsoau = providersList.value.filter((i) => i.name == "FTSO AU")[0];
  providersList.value = providersList.value.filter((i) => i.name != "FTSO AU");
  providersList.value.unshift(ftsoau);
});

const filteredList = computed(() => {
  return providersList.value.filter((i) =>
    i.name.toLowerCase().includes(search.value.toLowerCase())
  );
});

function select(provider: { name: string; address: string }) {
  emit("select", provider);
  hide();
}
function show() {
  modal.value.show();
}
function hide() {
  modal.value.hide();
}

function useCustomAddress() {
  if (!ethers.utils.isAddress(customDataProviderAddress.value)) {
    return notification.create({
      type: "error", // 'success' or 'error' or 'alert'
      title: "Problem with custom address",
      message: "The provided address is not valid.",
      linkUrl: null,
      linkText: "",
      timeout: 5000,
    });
  }
  let provider = {
    name: "Data Provider",
    address: customDataProviderAddress.value,
    logoURI: null,
  };
  emit("select", provider);
  hide();
  customDataProviderAddress.value = "";
}
</script>

<style scoped>
.scrollbox {
  overflow: scroll;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 200px, 100% 200px, 100% 10px, 100% 10px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}
</style>
<template>
  <TheModal ref="modal" id="delegationProviderModal">
    <div class="relative">
      <p
        class="relative font-semibold text-gray-600 dark:text-gray-100 select-none pb-6"
      >
        Providers
      </p>
    </div>
    <div>
      <RoundedContainer class="mb-4">
        <input
          v-model="search"
          type="text"
          placeholder="Search"
          class="h-8 w-full focus:outline-none bg-transparent"
        />
      </RoundedContainer>
    </div>
    <div class="overflow-y-scroll md:scrollbox h-96">
      <!-- todo: optimise loading (all images are loaded, load providers in chunks) -->
      <div
        @click="select(provider)"
        class="flex items-center justify-between border-b border-gray-100 h-20 md:h-16 px-3 hover:bg-gray-50 dark:hover:bg-slate-700 cursor-pointer select-none"
        v-for="provider in filteredList"
        :key="provider.address"
      >
        <div class="flex items-center tracking-tight">
          <img class="w-6 mr-4" :src="provider.logoURI" alt="" loading="lazy" />
          {{ provider.name }}
        </div>
        <div class="text-sm text-gray-400 font-['Courier']">
          {{ truncate(provider.address, 16, "...") }}
        </div>
      </div>
      <div class="mt-4">
        <div class="text-xs text-gray-400 pb-2">
          Use Custom Delegation Address
        </div>
        <div class="flex">
          <RoundedContainer class="w-full mr-2">
            <input
              v-model="customDataProviderAddress"
              type="text"
              class="h-8 w-full focus:outline-none bg-transparent"
            />
          </RoundedContainer>
          <button
            @click="useCustomAddress()"
            class="w-1/4 rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold px-2 text-sm md:text-base"
          >
            Delegate
          </button>
        </div>
      </div>
    </div>
  </TheModal>
</template>
