import { createApp } from "vue";
import { createPinia } from "pinia";
import "./assets/main.css";
import VueGtag from "vue-gtag-next";
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";

const app = createApp(App);

app.use(createPinia());

app.use(VueGtag, {
  property: {
    id: "G-78TDH064S6",
    params: {
      id: "3981502058",
      send_page_view: false,
    },
  },
});

// Sentry.init({
//   app,
//   dsn: "https://20825024424027fb6714db2a57d3ba8e@o4505266896044032.ingest.sentry.io/4506178731376645",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         "https://app.ftso.au",
//         "https://feature-pchain-staking--delightful-griffin-addd4a.netlify.app",
//       ],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 0.01, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 0.01, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

app.use(router);
app.mount("#app");
