<script setup>
import RoundedContainer from "./RoundedContainer.vue";
import IconMenu from "./icons/IconMenu.vue";
import IconMoon from "./icons/IconMoon.vue";
import IconHelp from "./icons/IconHelp.vue";
import IconExit from "./icons/IconExit.vue";
import IconBug from "./icons/IconBug.vue";
import IconBack from "./icons/IconBack.vue";
import { onMounted } from "vue";
import { useThemeStore } from "@/stores/theme";

const theme = useThemeStore();

const props = defineProps({
  id: {
    required: true,
  },
});

const buttonId = `${props.id}-menu-button`;
const menuId = `${props.id}-menu`;

onMounted(() => {
  document.addEventListener("click", ({ target }) => {
    if (
      target.id !== menuId &&
      target.id !== buttonId &&
      target.closest(".icon") === null
    ) {
      hide();
    }
  });
});

function toggle() {
  let menu = document.getElementById(menuId);
  menu.classList.toggle("hidden");
}
function hide() {
  let menu = document.getElementById(menuId);
  menu.classList.add("hidden");
}
function show() {
  let menu = document.getElementById(menuId);
  menu.style.classList.remove("hidden");
}
function goTo(url, newTab) {
  if (newTab) return window.open(url, "_blank").focus();
  window.location.replace(url);
}
</script>

<template>
  <div class="relative">
    <button :id="buttonId" @click="toggle()">
      <RoundedContainer :id="buttonId" class="h-8 flex items-center">
        <IconMenu
          :id="buttonId"
          class="h-4 w-4 text-gray-800 dark:text-gray-200"
        />
      </RoundedContainer>
    </button>
    <RoundedContainer
      :id="menuId"
      class="hidden absolute w-40 right-0 mt-2 text-gray-500 dark:text-gray-300"
    >
      <ul class="capitalize select-none">
        <li
          @click="theme.toggle()"
          class="flex items-center justify-between cursor-pointer group"
        >
          <span class="group-hover:text-black dark:group-hover:text-gray-100"
            >{{ theme.mode == "light" ? "Dark" : "Light" }} Mode</span
          >
          <IconMoon
            class="w-3 text-gray-500 dark:text-gray-300 group-hover:text-black dark:group-hover:text-gray-100"
          />
        </li>
        <li
          @click="
            goTo(
              'https://www.ftso.au/songbird-network/2021/11/29/wrap-delegate-claim-troubleshooting-guide.html',
              true
            )
          "
          class="flex items-center justify-between cursor-pointer group"
        >
          <span class="group-hover:text-black dark:group-hover:text-gray-100"
            >Get Help</span
          >
          <IconHelp
            class="w-3 text-gray-500 dark:text-gray-300 group-hover:text-black dark:group-hover:text-gray-100"
          />
        </li>
        <!-- <li
          @click="goTo('https://app.ftso.com.au', false)"
          class="flex items-center justify-between cursor-pointer group"
        >
          <span class="group-hover:text-black dark:group-hover:text-gray-100"
            >Legacy App</span
          >
          <IconBack
            class="w-3 text-gray-500 dark:text-gray-300 group-hover:text-black dark:group-hover:text-gray-100"
          />
        </li> -->

        <li
          @click="goTo('http://ftso.au/')"
          class="flex items-center justify-between cursor-pointer group"
        >
          <span class="group-hover:text-black dark:group-hover:text-gray-100"
            >Main Site</span
          >
          <IconExit
            class="w-4 text-gray-500 dark:text-gray-300 group-hover:text-black dark:group-hover:text-gray-100"
          />
        </li>
      </ul>
    </RoundedContainer>
  </div>
</template>

<style scoped>
li:not(:last-child) {
  margin-bottom: 3px;
}
li:hover {
}
li {
  padding: 4px;
}
</style>
