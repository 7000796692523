<script setup>
import { ref, onMounted } from "vue";
import { useGtag } from "vue-gtag-next";
import { useIsNumberKey as isNumberKey } from "@/helpers/IsNumberKey";
import { usePrettifyNumber as prettifyNumber } from "@/helpers/PrettifyNumber";
import { wrapNativeTokens, unwrapNativeTokens } from "@/helpers/Flare";
import { useWalletStore } from "@/stores/wallet";
import { useNotificationStore } from "@/stores/notification";
import TheModal from "@/components/TheModal.vue";
import NetworkIcon from "@/components/icons/NetworkIcon.vue";
import RoundedContainer from "@/components/RoundedContainer.vue";
import ProgressModal from "@/components/ProgressModal.vue";
import ConnectWallet from "@/components/ConnectWallet.vue";
import ConnectWalletCover from "@/components/ConnectWalletCover.vue";

onMounted(async () => {
  // Wait for wallet to connect (only relevant if previously connected)
  useWalletStore()
    .onReady()
    .then(() => {
      initialise();
    })
    .catch(() => {
      console.warn("Ethereum not initialised");
    });
  if (wallet.ethereum) {
    wallet.ethereum.on("accountsChanged", () => {
      initialise();
    });
  }
});

const { event } = useGtag();
const wallet = useWalletStore();
const notification = useNotificationStore();

const mode = ref("wrap");
const inputToken = ref("native");
const outputToken = ref("wrappedNative");
const inputAmount = ref(null);

const wrapConfirmationModal = ref(null);
const progressModal = ref(null);

async function initialise() {
  inputAmount.value = null;
  wallet.updateBalances();
}

function switchMode() {
  inputAmount.value = null;
  if (mode.value === "wrap") {
    mode.value = "unwrap";
    inputToken.value = "wrappedNative";
    outputToken.value = "native";
  } else {
    mode.value = "wrap";
    inputToken.value = "native";
    outputToken.value = "wrappedNative";
  }
}

function verifyInput() {
  if (
    parseFloat(inputAmount.value) > parseFloat(wallet.balance[inputToken.value])
  ) {
    // todo: logic could be improved for better ux, notify in all cases?
    if (parseFloat(wallet.balance[inputToken]) < 1) {
      inputAmount.value = null;
      // Viusal Notificaiton
      return notification.create({
        type: "alert",
        title: "Wrap Notice",
        message:
          "Restricted from wrapping 100% of tokens to leave gas to unwrap.",
        linkUrl: null,
        linkText: null,
        timeout: 5000,
      });
    }

    return (inputAmount.value = wallet.balance[inputToken.value] - 1);
  }
}

function inputMax() {
  if (mode.value === "unwrap")
    return (inputAmount.value = wallet.balance.wrappedNative);

  let max = wallet.balance.native - 1;
  if (max < 0) return (inputAmount.value = null);

  inputAmount.value = max;
}

function confirm() {
  wrapConfirmationModal.value.hide();

  if (mode.value == "wrap") {
    let wrapTransaction = wrapNativeTokens(wallet, inputAmount.value);
    let msg = `Wrapping ${parseFloat(inputAmount.value).toFixed(
      4
    )} ${wallet.tokenName(inputToken.value, wallet.network)}`;
    progressModal.value.confirm(wrapTransaction, msg);
  }

  if (mode.value == "unwrap") {
    let unwrapTransaction = unwrapNativeTokens(wallet, inputAmount.value);
    let msg = ` ${this.mode == "wrap" ? "Wrapping" : "Unwrapping"}
    ${inputAmount.value} ${wallet.tokenName(inputToken.value, wallet.network)}`;
    progressModal.value.confirm(unwrapTransaction, msg);
  }
}

function complete(tx) {
  // Analytics
  event("wrap_complete", {
    screen_name: "Wrap",
    label: "wrap complete",
  });

  // Viusal Notificaiton
  notification.create({
    type: "success", // 'success' or 'error' or 'alert'
    title: "Transaction Successful",
    message: `Completed ${mode.value} of ${Number(inputAmount.value).toFixed(
      4
    )} tokens.`,
    linkUrl: `https://${
      wallet.network == "flare"
        ? "flare-explorer.flare.network"
        : "songbird-explorer.flare.network"
    }/tx/${tx.transactionHash}`,
    linkText: `View on ${wallet.network} Explorer`,
    timeout: 5000,
  });

  // Reload Interface
  initialise();
}
</script>

<template>
  <main>
    <!-- Confirmation Modal -->
    <ProgressModal
      ref="progressModal"
      id="progressModal"
      @complete="complete"
    />

    <!-- Wrap Modal -->
    <TheModal ref="wrapConfirmationModal" id="wrapConfirmationModal">
      <div class="capitalize font-semibold">
        {{ mode }} {{ wallet.tokenName(inputToken) }}
      </div>
      <div class="text-sm text-gray-600 dark:text-gray-300">
        You are about to {{ mode }} {{ wallet.tokenName(inputToken) }} into
        {{ wallet.tokenName(outputToken) }} at a rate of 1:1. This process is
        reversible.
      </div>
      <div class="md:flex items-center justify-center gap-x-4 pt-2">
        <rounded-container class="mt-2 w-full">
          <div class="flex">
            <div class="">
              {{ prettifyNumber(inputAmount) }}
            </div>
            <div class="ml-auto">
              {{ wallet.tokenName(inputToken) }}
            </div>
          </div>
        </rounded-container>

        <svg
          class="w-6 h-6 fill-current dark:text-white rotate-90 mt-2 md:rotate-0 block mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 512.002 512.002"
          style="enable-background: new 0 0 512.002 512.002"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M388.425,241.951L151.609,5.79c-7.759-7.733-20.321-7.72-28.067,0.04c-7.74,7.759-7.72,20.328,0.04,28.067l222.72,222.105
			L123.574,478.106c-7.759,7.74-7.779,20.301-0.04,28.061c3.883,3.89,8.97,5.835,14.057,5.835c5.074,0,10.141-1.932,14.017-5.795
			l236.817-236.155c3.737-3.718,5.834-8.778,5.834-14.05S392.156,245.676,388.425,241.951z"
              />
            </g>
          </g>
        </svg>

        <RoundedContainer class="mt-2 w-full">
          <div class="flex">
            <div class="">{{ prettifyNumber(inputAmount) }}</div>
            <div class="ml-auto">
              {{ wallet.tokenName(outputToken) }}
            </div>
          </div>
        </RoundedContainer>
      </div>

      <div
        @click="confirm()"
        class="rounded-xl text-center bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer mt-4"
      >
        Confirm
      </div>
    </TheModal>
    <!-- View -->
    <div class="flex justify-center">
      <RoundedContainer class="w-full md:w-96 relative">
        <ConnectWalletCover />

        <div class="p-2">
          <p
            @click="switchMode()"
            class="font-medium text-gray-600 dark:text-gray-100 cursor-pointer select-none"
          >
            <span
              :class="mode == 'wrap' ? '' : 'text-gray-300 dark:text-gray-400'"
              >Wrap</span
            >
            <span class="text-gray-600 dark:text-gray-100 px-1">/</span>
            <span
              :class="
                mode == 'unwrap' ? '' : 'text-gray-300 dark:text-gray-400'
              "
              >Unwrap</span
            >
          </p>
          <div class="relative pb-2 pt-4">
            <div
              class="justify-center items-center border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 rounded-xl"
            >
              <div class="w-full flex px-3 pt-3 pb-2 text-lg select-none">
                <div class="flex">
                  <div
                    class="flex justify-center items-center h-8 rounded-full py-2 gap-x-2"
                  >
                    <NetworkIcon
                      :network="wallet.network ?? 'flare'"
                      class="mx-2 w-5 text-pink-600"
                    />
                    <div>
                      <span
                        class="font-medium text-gray-700 dark:text-gray-100"
                        >{{ wallet.tokenName(inputToken) }}</span
                      >
                    </div>
                  </div>
                </div>

                <input
                  v-model="inputAmount"
                  :disabled="!wallet.account"
                  tabindex="0"
                  @blur="verifyInput()"
                  @keydown="isNumberKey($event)"
                  @paste="isNumberKey($event)"
                  inputmode="decimal"
                  autocomplete="off"
                  autocorrect="off"
                  type="text"
                  pattern="^[0-9]*[.,]?[0-9]*$"
                  placeholder="0.0"
                  minlength="1"
                  maxlength="79"
                  spellcheck="false"
                  :max="flr"
                  min="1"
                  class="py-0 h-7 pr-0 right-0 border-none hide-spinners display-none focus:ring-0 text-right flex-auto w-full text-lg bg-transparent dark:text-white focus:outline-none"
                />
              </div>
              <div class="text-xs text-gray-400 dark:text-gray-300 pl-4 pb-2">
                <span @click="inputMax()" class="cursor-pointer"
                  >Balance: {{ wallet.balance[inputToken].toFixed(6) }}
                </span>
              </div>
            </div>
            <div
              class="justify-center items-center border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 rounded-xl mt-1"
            >
              <div class="w-full flex px-3 pt-3 pb-2 text-lg select-none">
                <div class="flex-auto">
                  <div
                    class="flex justify-center items-center h-8 rounded-full py-2 gap-x-2"
                  >
                    <NetworkIcon
                      :network="wallet.network ?? 'flare'"
                      class="mx-2 w-5 text-black"
                    />

                    <span
                      class="font-medium text-gray-700 dark:text-gray-100"
                      >{{ wallet.tokenName(outputToken) }}</span
                    >
                  </div>
                </div>

                <!-- todo: add max input to balance -->
                <input
                  v-model="inputAmount"
                  :disabled="!wallet.account"
                  tabindex="1"
                  @blur="verifyInput()"
                  @keydown="isNumberKey($event)"
                  @paste="isNumberKey($event)"
                  inputmode="decimal"
                  autocomplete="off"
                  autocorrect="off"
                  type="text"
                  pattern="^[0-9]*[.,]?[0-9]*$"
                  placeholder="0.0"
                  minlength="1"
                  maxlength="79"
                  spellcheck="false"
                  min="1"
                  class="py-0 h-7 pr-0 right-0 border-none hide-spinners display-none focus:ring-0 text-right flex-auto w-full text-lg bg-transparent dark:text-white focus:outline-none"
                />
              </div>
              <div class="text-xs text-gray-400 dark:text-gray-300 pl-4 pb-2">
                <span class=""
                  >Balance: {{ wallet.balance[outputToken].toFixed(6) }}</span
                >
              </div>
            </div>
            <!-- Center Switcher -->
            <div
              @click="switchMode()"
              class="absolute rounded-full border-4 border-white dark:border-slate-600 bg-gray-50 dark:bg-slate-500 ring-white dark:ring-slate-600 h-7 w-7 -mt-3 left-1/2 top-1/2 flex justify-center items-center transform rotate-90 ring-1 cursor-pointer"
            >
              <svg
                class="w-3 fill-current dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512.002 512.002"
                style="enable-background: new 0 0 512.002 512.002"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M388.425,241.951L151.609,5.79c-7.759-7.733-20.321-7.72-28.067,0.04c-7.74,7.759-7.72,20.328,0.04,28.067l222.72,222.105
			L123.574,478.106c-7.759,7.74-7.779,20.301-0.04,28.061c3.883,3.89,8.97,5.835,14.057,5.835c5.074,0,10.141-1.932,14.017-5.795
			l236.817-236.155c3.737-3.718,5.834-8.778,5.834-14.05S392.156,245.676,388.425,241.951z"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <!-- Buttons -->
          <div class="py-2">
            <!-- todo: Only restrict wnative -->
            <ConnectWallet v-if="!wallet.account" />

            <div
              v-else-if="mode == 'wrap' && wallet.balance[inputToken] <= 0.5"
              class="rounded-xl text-center py-2 text-gray-400 border dark:border-slate-500 select-none"
            >
              Insufficient
              <span>{{ wallet.tokenName(inputToken) }}</span>
              Balance
            </div>
            <div
              v-else-if="inputAmount == 0 || !inputAmount"
              class="rounded-xl text-center py-2 text-gray-400 border dark:border-slate-500 select-none capitalize"
            >
              Enter {{ mode }} Amount
            </div>
            <button
              v-else
              :disabled="inputAmount == 0 || inputAmount == null"
              @click="wrapConfirmationModal.show()"
              class="w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {{ mode }}
            </button>
          </div>
        </div>
      </RoundedContainer>
    </div>
  </main>
</template>
