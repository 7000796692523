<script setup>
import RoundedContainer from "@/components/RoundedContainer.vue";
import IconMenu from "./icons/IconMenu.vue";
import { useTruncateString as truncate } from "@/helpers/TruncateString";
import { useWalletStore } from "@/stores/wallet";

const wallet = useWalletStore();
</script>

<template>
  <div class="flex items-center gap-x-2">
    <div
      v-if="!wallet.account"
      @click="wallet.connect()"
      class="cursor-pointer text-center rounded-xl bg-white dark:bg-slate-500 px-3 py-1 border border-gray-100 dark:border-slate-600 w-full"
    >
      Connect Wallet
    </div>
    <RoundedContainer v-if="wallet.account">
      <span>
        {{ truncate(wallet.account, 11, "...") }}
      </span>
    </RoundedContainer>
  </div>
</template>
