<script setup>
const props = defineProps({
  modelValue: Boolean,
});

const emit = defineEmits(["update:modelValue"]);

const updateValue = (event) => {
  emit("update:modelValue", event.target.checked);
};
</script>

<template>
  <div class="cursor-pointer relative inline-block">
    <input
      @input="updateValue"
      :checked="modelValue"
      type="checkbox"
      name="toggle"
      id="toggle1"
      class="focus:outline-none checkbox w-3 h-3 rounded-md bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
    />
    <label
      for="toggle1"
      class="toggle-label block w-10 h-5 overflow-hidden rounded-md bg-gray-300 dark:bg-gray-700 cursor-pointer"
    ></label>
  </div>
</template>

<style>
.checkbox:checked {
  /* Apply class right-0*/
  right: 0;
}
.checkbox:checked + .toggle-label {
  /* Apply class bg-indigo-700 */
  background-color: #f97316;
}
</style>
