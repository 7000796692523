<script setup>
import ConnectWallet from "@/components/ConnectWallet.vue";
import RoundedContainer from "@/components/RoundedContainer.vue";
import NetworkIcon from "@/components/icons/NetworkIcon.vue";
import DelegationSlot from "@/components/DelegationSlot.vue";
import { useWalletStore } from "@/stores/wallet";
import { getRewardEpochTimestamps } from "@/helpers/Flare";
import { ref, onMounted } from "vue";
import ConnectWalletCover from "../components/ConnectWalletCover.vue";

const wallet = useWalletStore();
const rewardEpochTimestamps = ref({ start: null, end: null });

onMounted(async () => {
  // Wait for wallet to connect (only relevant if previously connected)
  useWalletStore()
    .onReady()
    .then(() => {
      initialise();
    })
    .catch(() => {
      console.warn("Ethereum not initialised");
    });

  if (wallet.ethereum) {
    wallet.ethereum.on("accountsChanged", () => {
      initialise();
    });
  }
});

async function initialise() {
  await wallet.updateBalances();
  let [_accountLockedVotePower, _accountDelegations, _rewardEpochTimestamps] =
    await Promise.all([
      wallet.updateDelegations(),
      wallet.updateLockedVotePower(),
      getRewardEpochTimestamps(),
    ]);

  rewardEpochTimestamps.value = _rewardEpochTimestamps;
}

function complete(tx) {
  // g-tag("event", "delegate_complete", {
  //   app_name: "ftsoau-app",
  //   screen_name: "Delegate",
  //   label: "delegate complete",
  // });
  this.$gtag.event("delegate_complete", {
    app_name: "ftsoau-app",
    screen_name: "Delegate",
    label: "delegate complete",
  });

  this.initilise();
}
function getTimeRemaining(endtime) {
  if (!endtime) return "0d 0hr 0min";
  const total = parseInt(endtime * 1000) - Date.now();
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return `${days}d ${hours}hr ${minutes}min`;
}
</script>

<template>
  <main>
    <!-- View -->
    <div class="flex justify-center">
      <RoundedContainer class="w-full md:w-auto relative">
        <ConnectWalletCover />

        <div class="p-2">
          <p
            class="font-medium text-gray-600 dark:text-gray-100 cursor-pointer select-none"
          >
            Delegate
          </p>
          <div
            class="my-4 p-4 border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 rounded-xl"
          >
            <div class="flex items-center justify-between pb-2">
              <div class="flex items-center">
                <NetworkIcon
                  :network="wallet.network"
                  class="w-5 h-5 text-black"
                />
                <span class="font-semibold px-3">{{
                  wallet.tokenName("wrappedNative")
                }}</span>
              </div>
              <span class="">{{
                wallet.balance.wrappedNative.toFixed(4)
              }}</span>
            </div>
            <div
              class="w-full grid grid-cols-2 text-sm text-gray-400 dark:text-gray-300"
            >
              <div>Allocated:</div>
              <div class="text-right">
                {{ wallet.ftso.bipsAllocated / 100 }}%
              </div>
              <div>Active:</div>
              <span class="text-right">{{
                parseFloat(wallet.ftso.lockedVotePower).toFixed(4)
              }}</span>
              <div>Next Epoch:</div>
              <div class="text-right">
                {{ getTimeRemaining(rewardEpochTimestamps.end) }}
              </div>
            </div>
          </div>
          <div v-if="wallet.account" class="pb-2 grid gap-y-2">
            <DelegationSlot
              :slot="0"
              :item="wallet.ftso.delegations[0]"
              @updated="initialise()"
            />
            <DelegationSlot
              :slot="1"
              :item="wallet.ftso.delegations[1]"
              :locked="wallet.ftso.delegations[0].bips == 10000"
              @updated="initialise()"
            />
          </div>
          <div v-else class="w-80">
            <ConnectWallet />
          </div>
        </div>
      </RoundedContainer>
    </div>
  </main>
</template>
