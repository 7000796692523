export const maxAllowedDelegation = 3;

export function getStatusMessage(val: string): string {
  if (statusMessages[val]) return statusMessages[val];
  return val;
}

export const statusMessages: any = {
  REQUEST_USER_SIGNATURE: "Waiting for user signature...",
  ISSUED_TX_WAITING_CONFIRM:
    "Transaction submitted, waiting for confirmation...",
  MAKING_UNSIGNED_PVM_TX: "Preparing PVM transaction...",
  MAKING_UNSIGNED_TX: "Preparing transaction...",
  MAKING_SIGNED_TX: "Preparing signed transaction...",
  EXPORT_ERROR: "Something wen't wrong during export.",
  IMPORT_ERROR: "Something wen't wrong during import.",
  REQUEST_EXPORT_CP: "Preparing export from C-Chain to P-Chain.",
  REQUEST_IMPORT_CP: "Preparing import from C-Chain to P-Chain.",
  REQUEST_EXPORT_PC: "Preparing export from P-Chain to C-Chain.",
  REQUEST_IMPORT_PC: "Preparing import from P-Chain to C-Chain.",
  CROSS_CHAIN_CP_SUCCESS:
    "Successfully transferred tokens from C-Chain to P-Chain.",
  CROSS_CHAIN_PC_SUCCESS:
    "Successfully transferred tokens from P-Chain to C-Chain.",
  ISSUING_TX: "Transaction pending submission...",
  STAKE_SUCCESS: "Successfully staked tokens. ",
  ERROR_USER: "An error orccured on client side.",
};

export const errorMessages: any = {
  "export tx flow check failed due to: insufficient funds":
    "Insufficient funds.",
};
