<script setup lang="ts">
import { useWalletStore } from "@/stores/wallet";
// @ts-ignore
import { bindAddressPchain } from "@/helpers/Flare";
// @ts-ignore
import ProgressModal from "./ProgressModal.vue";
import { ref } from "vue";

const progressModal = ref(null);
const wallet = useWalletStore();
async function bindAddress() {
  const tx = await bindAddressPchain(wallet);
  // @ts-ignore
  progressModal.value.confirm(tx, "Binding C & P Chain Addresses");
}

function complete() {
  wallet.addressBinderBound = true;
  wallet.updateBalancesV2();
}
</script>

<template>
  <ProgressModal
    ref="progressModal"
    id="progressModal-rewards"
    @complete="complete"
  />
  <button
    @click="bindAddress"
    class="w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
  >
    Bind Address
  </button>
  <div
    class="flex items-start gap-x-4 my-2 p-4 border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 rounded-xl text-gray-500 dark:text-gray-300 text-sm"
  >
    <div class="w-6">
      <IconInfo class="w-5 text-gray-500 pt-1" />
    </div>
    Your C Chain address must be bound to your public key and P Chain address
    before staking.
  </div>
</template>
