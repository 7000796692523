<script setup>
import AppEmblem from "@/components/icons/AppEmblem.vue";
import RoundedContainer from "@/components/RoundedContainer.vue";
import TheModal from "@/components/TheModal.vue";
import { ref } from "vue";
import { useNotificationStore } from "@/stores/notification";

defineExpose({ confirm });
const emit = defineEmits(["complete"]);
const props = defineProps({
  id: {
    required: true,
  },
});

const notification = useNotificationStore();

const innerProgressModal = ref(null);
const message = ref("Waiting for user action");
const statusMessage = ref("Waiting for user confirmation");

let intent = "DEFAULT";

async function confirm(tx, msg = "Waiting for user action.", _intent) {
  intent = _intent;
  message.value = msg;
  show();
  try {
    tx = await tx;
  } catch (error) {
    fail(error);
    return;
  }
  statusMessage.value = "Waiting For Network Confirmation";
  tx.wait()
    .then((tx) => success(tx))
    .catch((error) => fail(error));
}
function show() {
  innerProgressModal.value.show();
}
function hide() {
  innerProgressModal.value.hide();
}
function success(tx) {
  emit("complete", tx, intent);
  hide();
}
function fail(error) {
  console.error(error);
  hide();
  let reason = "Something went wrong.";
  if (error.message.includes("transfer amount exceeds balance")) {
    reason = "Input amount too high, try lowering amount.";
  }
  if (error.message.includes("Cannot delegate to self")) {
    reason = "You are unable to delegate to yourself.";
  }
  if (error.code == "ACTION_REJECTED") {
    reason = "User rejected transaction.";
  }
  if (error.code == "-32603") {
    reason = "Transaction underpriced.";
  }
  // console.log({ code: error.code, msg: error.message });

  notification.create({
    type: "error", // 'success' or 'error' or 'alert'
    title: "Transaction Failed",
    message: reason,
    linkUrl: null,
    linkText: null,
    timeout: 5000,
  });
}
</script>

<template>
  <TheModal ref="innerProgressModal">
    <div class="relative flex justify-center py-6">
      <div
        class="loader border-t-orange-500 dark:border-t-white dark:border-l-white dark:border-r-white border-l-orange-500 border-r-orange-500 border-b-white dark:border-b-slate-800 ease-linear rounded-full border-8 border-t-8 h-32 w-32"
      ></div>
      <div class="absolute pt-9 animate-pulse">
        <AppEmblem class="w-14 h-14" />
      </div>
    </div>
    <div class="text-center">
      <div class="py-2 text-lg">{{ statusMessage }}</div>
      <RoundedContainer class="my-2">{{ message }}</RoundedContainer>
      <div class="py-2 text-sm text-gray-400">
        Follow prompts from your wallet
      </div>
    </div>
  </TheModal>
</template>

<style scoped>
.loader {
  -webkit-animation: spinner 2.5s linear infinite;
  animation: spinner 2.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
