import { ref } from "vue";
import { BN, Buffer } from "@flarenetwork/flarejs/dist";
import {
  UTXOSet,
  UnsignedTx,
  Tx,
} from "@flarenetwork/flarejs/dist/apis/platformvm";
import { UnixNow } from "@flarenetwork/flarejs/dist/utils";
import { UnsignedTxJson, WalletState } from "@/types";
import {
  serializeUnsignedTx,
  delegationAddressCount,
  signUnsignedTxHash,
} from "../Utils";
import { maxAllowedDelegation } from "../Constants";
import { WalletStore } from "@/types/global";
import { issueSignedPvmTx } from "./pvmAtomicTx";

type AddDelegatorParams = [
  UTXOSet,
  string[],
  string[],
  string[],
  string,
  BN,
  BN,
  BN,
  string[],
  BN,
  number,
  Buffer | undefined,
  BN
];

async function checkMaximumAllowedDelegation(wallet: WalletState) {
  const numberOfDelegation = await delegationAddressCount(wallet);
  if (numberOfDelegation >= maxAllowedDelegation) {
    throw new Error(
      `Exceeded maximum allowed delegation of ${maxAllowedDelegation}`
    );
  }
}
/**
 * @description - Delegate funds to a given validator
 * @param ctx - context with constants initialized from user keys
 * @param nodeID - id of the node you are running (can get it via rpc call)
 * @param stakeAmount - the amount of funds to stake during the node's validation
 * @param startTime - start time of the node's validation
 * @param endTime - end time of the node's validation
 */
export async function addDelegator(
  wallet: WalletStore,
  nodeID: string,
  stakeAmount: BN,
  startTime: BN,
  endTime: BN,
  threshold?: number,
  status = ref("")
) {
  status.value = "PVM_CHECK_DELEGATIONS";
  await checkMaximumAllowedDelegation(wallet);
  status.value = "MAKING_UNSIGNED_PVM_TX";

  const unsignedTxJson = await getUnsignedAddDelegator(
    wallet,
    nodeID,
    stakeAmount,
    startTime,
    endTime,
    threshold
  );

  const txHash = "0x" + unsignedTxJson.signatureRequests[0].message;

  status.value = "REQUEST_USER_SIGNATURE";
  const signature = await signUnsignedTxHash(
    wallet.accountKeys.addressCchain,
    txHash
  );

  status.value = "MAKING_SIGNED_TX";
  const signedTxJson = { ...unsignedTxJson, signature };

  status.value = "ISSUING_TX";
  return await issueSignedPvmTx(wallet, signedTxJson);

  //   const tx: Tx = unsignedTx.sign(wallet.pKeychain);
  //   const txid: string = await wallet.pchain.issueTx(tx);
  //   return { txid: txid };
}

/**
 * @description - Generate an unisgned transaction for the add-delegator transaction
 * @param ctx - context with constants initialized from user keys
 * @param nodeID - id of the node you are running (can get it via rpc call)
 * @param stakeAmount - the amount of funds to stake during the node's validation
 * @param startTime - start time of the node's validation
 * @param endTime - end time of the node's validation
 */
export async function getUnsignedAddDelegator(
  wallet: WalletState,
  nodeID: string,
  stakeAmount: BN,
  startTime: BN,
  endTime: BN,
  threshold?: number
): Promise<UnsignedTxJson> {
  await checkMaximumAllowedDelegation(wallet);
  const params = await getAddDelegatorParams(
    wallet,
    nodeID,
    stakeAmount,
    startTime,
    endTime,
    threshold
  );
  const unsignedTx: UnsignedTx = await wallet.pchain.buildAddDelegatorTx(
    ...params
  );
  return {
    transactionType: "delegate",
    serialization: serializeUnsignedTx(unsignedTx),
    signatureRequests: unsignedTx.prepareUnsignedHashes(wallet.cKeychain),
    unsignedTransactionBuffer: unsignedTx.toBuffer().toString("hex"),
  };
}

/**
 * @description - Generate the parameters for getUnsignedAddDelegator and addDelegator
 * @param ctx - context with constants initialized from user keys
 * @param nodeID - id of the node you are running (can get it via rpc call)
 * @param stakeAmount - the amount of funds to stake during the node's validation
 * @param startTime - start time of the node's validation
 * @param endTime - end time of the node's validation
 */
export async function getAddDelegatorParams(
  wallet: WalletState,
  nodeID: string,
  stakeAmount: BN,
  startTime: BN,
  endTime: BN,
  threshold: number = 1
): Promise<AddDelegatorParams> {
  const locktime: BN = new BN(0);
  const asOf: BN = UnixNow();
  const platformVMUTXOResponse: any = await wallet.pchain.getUTXOs(
    "P-" + wallet.accountKeys.addressPchain!
  );
  const utxoSet: UTXOSet = platformVMUTXOResponse.utxos;
  return [
    utxoSet,
    ["P-" + wallet.accountKeys.addressPchain!],
    ["P-" + wallet.accountKeys.addressPchain!],
    ["P-" + wallet.accountKeys.addressPchain!],
    nodeID,
    startTime,
    endTime,
    stakeAmount,
    ["P-" + wallet.accountKeys.addressPchain!],
    locktime,
    threshold,
    undefined,
    asOf,
  ];
}
