<script setup>
// @ts-ignore
import ConnectWallet from "../components/ConnectWallet.vue";
// @ts-ignore
import ProgressModal from "../components/ProgressModal.vue";
// @ts-ignore
import CheckBox from "./CheckBox.vue";
import { useWalletStore } from "@/stores/wallet";
import { useNotificationStore } from "@/stores/notification";
import {
  getRewardEpochTimestamps,
  getStateOfRewards,
  getEpochsWithUnclaimedRewards,
  getCurrentRewardEpoch,
  getFtsoProviderMeta,
  claimFtsoReward,
  getBalance,
  getFtsoPrice,
  contract,
  // @ts-ignore
} from "@/helpers/Flare";
import { useGtag } from "vue-gtag-next";
import { ref, onMounted } from "vue";
// import type { Ref } from "vue";

onMounted(async () => {
  // Wait for wallet to connect (only relevant if previously connected)
  useWalletStore()
    .onReady()
    .then(() => {
      initialise();
    })
    .catch(() => {
      console.warn("Ethereum not initialised");
    });
});

const { event } = useGtag();
const wallet = useWalletStore();
const notification = useNotificationStore();

const progressModal = ref(null);

const tokenPrice = ref(0);
const rewardManagerBalance = ref(0);
const rewardEpochTimeRemaining = ref(null);

const unclaimedEpochs = ref([]);
const unclaimedRewardsByProvider = ref({});
const unclaimedTotal = ref(0);
const accruingRewardsByProvider = ref({});
const accruingTotal = ref(0);

const wrapClaim = ref(true);

async function initialise() {
  useWalletStore().ethereum.on("accountsChanged", () => {
    initialise();
  });
  unclaimedRewardsByProvider.value = {};
  unclaimedTotal.value = 0;
  accruingRewardsByProvider.value = {};
  accruingTotal.value = 0;
  if (!wallet.network) return;
  let [_tokenPrice, _rewardManagerBalance, _rewardEpochTimestamps] =
    await Promise.all([
      getFtsoPrice(wallet.network == "songbird" ? "SGB" : "FLR"),
      getBalance(contract[wallet.network].address.FtsoRewardManager),
      getRewardEpochTimestamps(),
      calculateUnclaimedRewards(),
      calculateAccruingRewards(),
    ]);

  tokenPrice.value = _tokenPrice;
  rewardManagerBalance.value = _rewardManagerBalance;
  rewardEpochTimeRemaining.value = getTimeRemaining(_rewardEpochTimestamps.end);
}

async function calculateUnclaimedRewards() {
  unclaimedEpochs.value = await getEpochsWithUnclaimedRewards(wallet);
  let unclaimedRewardStates = await Promise.all(
    unclaimedEpochs.value.map((epoch) => getStateOfRewards(wallet, epoch))
  );

  unclaimedRewardStates.forEach((rewardState) => {
    for (var i in rewardState.dataProviders) {
      if (rewardState.claimed[i]) continue;

      let dataProvider = rewardState.dataProviders[i];
      let reward = rewardState.rewardAmounts[i];

      if (
        !Object.keys(unclaimedRewardsByProvider.value).includes(dataProvider)
      ) {
        unclaimedRewardsByProvider.value[dataProvider] = reward;
      } else {
        unclaimedRewardsByProvider.value[dataProvider] += reward;
      }

      unclaimedTotal.value += reward;
    }
  });
}
async function calculateAccruingRewards() {
  let currentRewardEpoch = await getCurrentRewardEpoch();
  let accruingRewardState = await getStateOfRewards(wallet, currentRewardEpoch);

  for (var i in accruingRewardState.dataProviders) {
    if (accruingRewardState.claimed[i]) continue;

    let dataProvider = accruingRewardState.dataProviders[i];
    let reward = accruingRewardState.rewardAmounts[i];

    if (!Object.keys(accruingRewardsByProvider.value).includes(dataProvider)) {
      accruingRewardsByProvider.value[dataProvider] = reward;
    } else {
      accruingRewardsByProvider.value[dataProvider] += reward;
    }

    accruingTotal.value += reward;
  }
}

async function claim() {
  const greatestEpoch = unclaimedEpochs.value.at(-1);
  const claimAllTransaction = claimFtsoReward(greatestEpoch, wrapClaim.value);

  let msg = `Claiming ${
    wrapClaim.value ? "& Wrapping " : ""
  }${unclaimedTotal.value.toFixed(4)} ${wallet.tokenName("native")}`;
  progressModal.value.confirm(claimAllTransaction, msg);
}
function complete(tx) {
  // Analytics
  event("claim_rewards_complete", {
    screen_name: "Rewards",
    label: "claim rewards complete",
  });

  // Viusal Notificaiton
  notification.create({
    type: "success", // 'success' or 'error' or 'alert'
    title: "Transaction Successful",
    message: `Completed claim of ${unclaimedTotal.value.toFixed(
      4
    )} ${wallet.tokenName("native")}`,
    linkUrl: `https://${
      wallet.network == "flare"
        ? "flare-explorer.flare.network"
        : "songbird-explorer.flare.network"
    }/tx/${tx.transactionHash}`,
    linkText: `View on ${wallet.network} Explorer`,
    timeout: 5000,
  });

  initialise();
}
function getTimeRemaining(endtime) {
  const total = parseInt(endtime * 1000) - Date.now();
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return `${days}d ${hours}hr ${minutes}min`;
}
</script>

<template>
  <main>
    <!-- Confirmation Modal -->
    <ProgressModal
      ref="progressModal"
      id="progressModal-rewards"
      @complete="complete"
    />

    <div
      class="my-4 p-4 border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 rounded-xl"
    >
      <div
        class="w-full grid grid-cols-2 text-sm text-gray-500 dark:text-gray-300"
      >
        <div>Claimable:</div>
        <div class="text-right">
          {{ unclaimedTotal.toFixed(4) }} {{ wallet.tokenName("native") }}
        </div>
        <div>Epoch Earnings:</div>
        <div class="text-right">
          {{ accruingTotal.toFixed(4) }} {{ wallet.tokenName("native") }}
        </div>
        <div>Next Claim:</div>
        <div class="text-right">{{ rewardEpochTimeRemaining }}</div>
      </div>
    </div>
    <template v-if="unclaimedTotal == 0">
      <div
        v-for="provider of Object.keys(accruingRewardsByProvider)"
        class="rounded-xl text-center py-2 px-4 mb-2 border dark:border-slate-500 select-none cursor-pointer hover:shadow-sm flex justify-between"
        :key="provider"
      >
        <div class="flex items-center gap-x-2">
          <img :src="getFtsoProviderMeta(provider).logoURI" class="w-6" />
          <span class="text-sm text-gray-600 dark:text-gray-300">{{
            getFtsoProviderMeta(provider).name
          }}</span>
        </div>
        <div>
          {{ accruingRewardsByProvider[provider].toFixed(4) }}
          <span class="text-gray-600 dark:text-gray-400 text-sm">{{
            wallet.tokenName("native")
          }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="provider of Object.keys(unclaimedRewardsByProvider)"
        :key="provider"
        class="rounded-xl text-center py-2 px-4 mb-2 border dark:border-slate-500 select-none cursor-pointer hover:shadow-sm flex justify-between"
      >
        <div class="flex items-center gap-x-2">
          <img :src="getFtsoProviderMeta(provider).logoURI" class="w-6" />
          <span class="text-sm text-gray-600 dark:text-gray-300">{{
            getFtsoProviderMeta(provider).name
          }}</span>
        </div>
        <div>
          {{ unclaimedRewardsByProvider[provider].toFixed(4) }}
          <span class="text-gray-600 dark:text-gray-400 text-sm">{{
            wallet.tokenName("native")
          }}</span>
        </div>
      </div>
    </template>

    <div class="mb-4">
      <div
        class="border-t w-full border-gray-200 dark:border-gray-500 pt-2 mt-4 flex justify-between"
      >
        <div class="font-semibold text-gray-600 dark:text-gray-400">Total:</div>
        <!-- <div class="grid grid-cols-2 gap-x-1">
          <div class="col-span-2 sm:col-span-1">
            {{
              unclaimedTotal
                ? unclaimedTotal.toFixed(4)
                : accruingTotal.toFixed(4)
            }}
            <span class="text-gray-500 text-sm">{{
              wallet.tokenName("native")
            }}</span>
          </div>
          <div
            class="col-span-2 sm:col-span-1 text-right sm:border-l sm:pl-3 border-gray-200"
            v-if="tokenPrice"
          >
            ${{
              unclaimedTotal
                ? parseFloat(unclaimedTotal * tokenPrice).toFixed(2)
                : parseFloat(accruingTotal * tokenPrice).toFixed(2)
            }}

            <span class="text-gray-500 text-sm">USD</span>
          </div>
        </div> -->
        <div class="">
          <div>
            {{
              unclaimedTotal
                ? unclaimedTotal.toFixed(4)
                : accruingTotal.toFixed(4)
            }}
            <span class="text-sm text-gray-600 dark:text-gray-400">{{
              wallet.tokenName("native")
            }}</span>
          </div>
          <div class="text-xs text-right text-gray-600 dark:text-gray-400">
            (${{
              unclaimedTotal
                ? parseFloat(unclaimedTotal * tokenPrice).toFixed(2)
                : parseFloat(accruingTotal * tokenPrice).toFixed(2)
            }}
            USD)
          </div>
        </div>
      </div>
      <div class="text-right"></div>
    </div>
    <!-- Checkbox  -->
    <div class="flex gap-x-3 my-3 justify-between">
      <div class="text-sm text-gray-600 dark:text-gray-400">
        Wrap Tokens On Claim
      </div>
      <CheckBox v-model="wrapClaim" />
    </div>
    <!-- Buttons -->
    <div class="pb-2">
      <ConnectWallet v-if="!wallet.account" class="w-full" />

      <div
        v-else-if="unclaimedTotal == 0 && accruingTotal > 0"
        class="rounded-xl text-center py-2 text-gray-500 border dark:border-slate-500 select-none"
      >
        Claim in {{ rewardEpochTimeRemaining }}
      </div>

      <div
        v-else-if="rewardManagerBalance < unclaimedTotal"
        class="rounded-xl text-center py-2 text-gray-500 border dark:border-slate-500 select-none"
      >
        <a
          target="_blank"
          href="https://www.ftso.au/songbird-network/2021/11/29/wrap-delegate-claim-troubleshooting-guide.html#unable-to-claim-rewards"
          >Reward Pool Recharging</a
        >
      </div>
      <div
        v-else-if="accruingTotal == 0"
        class="rounded-xl text-center py-2 text-gray-500 border dark:border-slate-500 select-none"
      >
        No Rewards
      </div>

      <button
        v-else
        @click="claim()"
        class="w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
      >
        Claim All
      </button>
    </div>
  </main>
</template>
