<script setup lang="ts">
import { ref } from "vue";
import type { Ref } from "vue";
import { useWalletStore } from "@/stores/wallet";
import { decimalToInteger } from "@/helpers/Utils";
import { statusMessages } from "@/helpers/Constants";
import { BN } from "@flarenetwork/flarejs";
import { VMChains } from "@/types";
import {
  importTokens as importTokensPC,
  exportTokens as exportTokensCP,
  waitAtomicTxStatus as waitAtomicTxStatusC,
} from "@/helpers/flare/evmAtomicTx";

import {
  importTokens as importTokensCP,
  exportTokens as exportTokensPC,
  waitAtomicTxStatus as waitAtomicTxStatusP,
} from "@/helpers/flare/pvmAtomicTx";
import { useNotificationStore } from "@/stores/notification";
import CrossChainInput from "@/components/CrossChainInput.vue";
import ProgressModalV2 from "@/components/ProgressModalV2.vue";

const notification = useNotificationStore();
const wallet = useWalletStore();
const status = ref("WAITING");
const inputAmount: Ref<number | undefined> = ref();
const inputChain: Ref<VMChains> = ref("pchain");
const txFee: Ref<BN> = ref(new BN(25));
const cChainTxId: Ref<string> = ref("");
const pChainTxId: Ref<string> = ref("");
const confirmSwitch: Ref<boolean> = ref(false);

const progressModal: Ref<any> = ref();
const crossChainInput: Ref<any> = ref();

const handleCrossChainInput = (values: {
  inputAmount: string;
  inputChain: VMChains;
}) => {
  inputAmount.value = parseFloat(values.inputAmount);
  inputChain.value = values.inputChain;
};

async function crossChainTransfer(destination: VMChains) {
  if (!inputAmount.value) throw new Error("Input amount undefined.");
  const fee = decimalToInteger(txFee.value.toString().replace(/,/g, ""), 9);
  var amount = decimalToInteger(
    inputAmount.value.toString().replace(/,/g, ""),
    9
  );
  progressModal.value.show();

  switch (destination) {
    case "pchain":
      // Move tokens from C Chain to P Chain
      var importFee = wallet.pchain.getDefaultTxFee();

      status.value = "REQUEST_EXPORT_CP";

      // Export Tokens
      try {
        cChainTxId.value = await exportTokensCP(
          wallet,
          new BN(amount).add(importFee),
          new BN(fee),
          status
        );
        await waitAtomicTxStatusC(wallet, cChainTxId.value);
      } catch (error: any) {
        notification.create({
          type: "error", // 'success' or 'error' or 'alert'
          title: "Something wen't wrong...",
          message: error.message,
          linkUrl: null,
          linkText: "",
          timeout: 5000,
        });
        progressModal.value.waitAndFail({ message: error.message });
        resetForm();
        return (status.value = "EXPORT_ERROR");
      }

      status.value = "REQUEST_IMPORT_CP";

      // Import Tokens
      try {
        pChainTxId.value = await importTokensCP(wallet, 1, status);
        await waitAtomicTxStatusP(wallet, pChainTxId.value);
      } catch (error: any) {
        notification.create({
          type: "error", // 'success' or 'error' or 'alert'
          title: "Something wen't wrong...",
          message: error.message,
          linkUrl: null,
          linkText: "",
          timeout: 5000,
        });

        progressModal.value.waitAndFail({ message: error.message });
        resetForm();
        return (status.value = "IMPORT_ERROR");
      }

      wallet.updateBalancesV2();
      progressModal.value.waitAndSucceed({
        message: `You have transferred ${inputAmount.value} FLR to the P-Chain.`,
      });
      status.value = "CROSS_CHAIN_CP_SUCCESS";

      break;
    case "cchain":
      status.value = "REQUEST_EXPORT_PC";
      try {
        pChainTxId.value = await exportTokensPC(wallet, new BN(amount), status);
        await waitAtomicTxStatusP(wallet, pChainTxId.value);
      } catch (error: any) {
        notification.create({
          type: "error", // 'success' or 'error' or 'alert'
          title: "Something wen't wrong...",
          message: error.message,
          linkUrl: null,
          linkText: "",
          timeout: 5000,
        });
        console.log(error.message);
        progressModal.value.waitAndFail({ message: error.message });
        resetForm();
        return (status.value = "EXPORT_ERROR");
      }

      status.value = "REQUEST_IMPORT_PC";

      try {
        pChainTxId.value = await importTokensPC(wallet, undefined, status);
        await waitAtomicTxStatusC(wallet, pChainTxId.value);
      } catch (error: any) {
        notification.create({
          type: "error", // 'success' or 'error' or 'alert'
          title: "Something wen't wrong...",
          message: error.message,
          linkUrl: null,
          linkText: "",
          timeout: 5000,
        });
        progressModal.value.waitAndFail({ message: error.message });
        resetForm();
        return (status.value = "IMPORT_ERROR");
      }

      wallet.updateBalancesV2();
      progressModal.value.waitAndSucceed({
        message: `You have transferred ${inputAmount.value} FLR to the C-Chain.`,
      });
      status.value = "CROSS_CHAIN_PC_SUCCESS";

      break;

    default:
      break;
  }
  resetForm();
}

function resetForm() {
  confirmSwitch.value = false;
  inputAmount.value = undefined;
  crossChainInput.value.reset();
}
const actionMessage = {
  pchain: "C-Chain to P-Chain Token Transfer",
  cchain: "P-Chain to C-Chain Token Transfer",
};
</script>

<template>
  <ProgressModalV2
    ref="progressModal"
    :action="actionMessage[inputChain]"
    :message="statusMessages[status] || status"
  />

  <div class="mb-4">
    <CrossChainInput
      ref="crossChainInput"
      @update:modelValue="handleCrossChainInput"
      :modelValue="{
        inputChain: inputAmount,
        inputAmount: inputChain,
      }"
      :locked="confirmSwitch"
    />
  </div>
  <button
    v-if="!confirmSwitch"
    @click="confirmSwitch = true"
    :disabled="!inputAmount"
    class="w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
  >
    Confirm
  </button>

  <div class="grid grid-cols-3 gap-x-4">
    <button
      v-if="confirmSwitch"
      @click="confirmSwitch = !confirmSwitch"
      class="w-full rounded-xl text-center font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Cancel
    </button>

    <button
      v-if="confirmSwitch"
      @click="crossChainTransfer(inputChain)"
      class="col-span-2 w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Transfer
    </button>
  </div>
</template>
@/helpers/Utils
