<!-- This icon is from <https://github.com/Templarian/MaterialDesign>, distributed under Apache 2.0 (https://www.apache.org/licenses/LICENSE-2.0) license-->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1763.76 511.99">
    <g class="dark:hidden">
      <path
        d="M315.71,333.1A226.54,226.54,0,0,0,296,261.53a275.08,275.08,0,0,0-37.5-59.82c-4.29-5.26-8.64-10.3-10.89-12.62l-5.18-5.66c-1.27-1.45-2.33-2.62-3.38-3.65l-1.55-1.57c-1.7-1.7-3.44-3.47-4.89-5.24l-.36-.42c-.71-.77-1.46-1.54-2.31-2.4a45.59,45.59,0,0,1-3.46-3.76l-.44-.52c-7.89-8.68-14.53-16.56-20.29-24.1a160.35,160.35,0,0,1-15.46-23.86A109.5,109.5,0,0,1,180.09,89a176.73,176.73,0,0,1-3.58-32.43,272.07,272.07,0,0,1,1-29.53c.49-5.7,1-10.75,1.58-15.21A10.58,10.58,0,0,0,169.82.07a10.74,10.74,0,0,0-3.91.27,119.89,119.89,0,0,0-15.35,5.18A140,140,0,0,0,119.1,23.67a148.76,148.76,0,0,0-33.63,36A157,157,0,0,0,61.87,118c-4.45,23.08-4,49.13,1.38,73.3,5.06,23.4,14.86,47.29,28.39,69.17a250.3,250.3,0,0,0,20.78,28.91c2,2.51,7.59,9,7.65,9.08l2.22,2.55c1.55,1.76,2.66,3.09,3.8,4.44s1.91,2.28,2.87,3.3l.16.17c10.59,12.66,18,21.95,24.2,30.17,6.81,9.05,12.79,17.82,18.29,26.81a152,152,0,0,1,13.16,26.37A123.66,123.66,0,0,1,191.69,423a183.45,183.45,0,0,1,0,32.81c-1,11.3-2.74,21.61-4.13,29.16-1,5.17-2.05,9.93-3,14A10.61,10.61,0,0,0,197,511.78a110.07,110.07,0,0,0,14.49-3.85,141.09,141.09,0,0,0,32.39-15.51,149.12,149.12,0,0,0,36.27-32.56A163.78,163.78,0,0,0,309,405,185.69,185.69,0,0,0,315.71,333.1Z"
        fill="#ff7500"
      />
      <path
        d="M108.25,397.9a125.8,125.8,0,0,0-17.11-27.31c-1.75-2.14-3.86-4.6-4.89-5.67l-2.44-2.66c-.63-.72-1.09-1.22-1.58-1.71l-.67-.67c-.77-.77-1.54-1.56-2.2-2.34-.11-.14-.24-.28-.36-.42l-.92-.95c-.53-.55-1.06-1.08-1.51-1.64a4.82,4.82,0,0,0-.36-.43c-3.49-3.82-6.49-7.38-9.13-10.83a74.78,74.78,0,0,1-7.1-11,49.87,49.87,0,0,1-4.65-13.2,79.91,79.91,0,0,1-1.62-14.79,114.28,114.28,0,0,1,.32-11.7,10.58,10.58,0,0,0-15.1-10.31,67.27,67.27,0,0,0-11.44,7,68.16,68.16,0,0,0-15.33,16.41A71.52,71.52,0,0,0,1.38,332.34,84.44,84.44,0,0,0,2,365.82a102,102,0,0,0,13,31.63,114.16,114.16,0,0,0,9.39,13.08c.89,1.1,2.8,3.31,3.65,4.3h0l1,1.07c.75.84,1.27,1.46,1.78,2.09s.83,1,1.18,1.36l.17.18c3.26,3.9,7.47,9,11.06,13.79,3.12,4.14,5.85,8.14,8.34,12.23a68.27,68.27,0,0,1,6,12,56.93,56.93,0,0,1,3.17,14.1,84.15,84.15,0,0,1,0,14.89c-.28,3.34-.75,6.86-1.42,10.77a10.59,10.59,0,0,0,14.4,11.59,65.17,65.17,0,0,0,10.77-5.61A68.3,68.3,0,0,0,101,488.44a74.68,74.68,0,0,0,13.17-25,85.9,85.9,0,0,0,3.09-32.83A103.81,103.81,0,0,0,108.25,397.9Z"
        fill="#ff7500"
      />
      <path
        d="M413.1,184.33H577.83v40.6H461.06v53.13H563.41v40.13H461.06v96.86h-48Z"
        fill="#1e1e1e"
      />
      <path
        d="M777.05,184v40.91H707.92V415.05H659.34V224.93H589.9V184Z"
        fill="#1e1e1e"
      />
      <path
        d="M840.05,343.74q2.25,15.83,8.81,23.67,12,14.26,41.13,14.26,17.43,0,28.32-3.76Q939,370.7,939,351.1q0-11.43-10.12-17.71-10.11-6.11-32.07-10.81l-25-5.49q-36.87-8.14-50.66-17.71-23.35-16-23.35-50,0-31,22.84-51.57t67.12-20.53q37,0,63.07,19.36t27.37,56.19H931.75q-1.29-20.85-18.64-29.62-11.58-5.81-28.76-5.8-19.12,0-30.54,7.52t-11.41,21q0,12.39,11.25,18.5,7.23,4.08,30.7,9.56l40.54,9.56q26.65,6.27,40,16.77,20.65,16.31,20.66,47.18,0,31.67-24.48,52.58T891.88,421q-45.63,0-71.77-20.61T794,343.74Z"
        fill="#1e1e1e"
      />
      <path
        d="M1197.26,394.52q-26.17,27-75.7,27t-75.71-27q-35.11-33.07-35.11-95.3,0-63.48,35.11-95.29,26.18-27,75.71-27t75.7,27q35,31.81,35,95.29Q1232.22,361.45,1197.26,394.52Zm-30.17-35.11q16.85-21.15,16.85-60.19,0-38.87-16.85-60.1t-45.53-21.24q-28.69,0-45.77,21.16t-17.08,60.18q0,39,17.08,60.19t45.77,21.16q28.68,0,45.53-21.16Z"
        fill="#1e1e1e"
      />
      <path
        d="M1412.54,184h54.62l81.74,231h-52.35l-15.24-47.49h-85.08L1380.57,415h-50.5Zm-3.18,143.73h59.17l-29.19-90.91Z"
        fill="#1e1e1e"
      />
      <path
        d="M1578.29,184h49.06V325.91q0,23.8,5.62,34.77,8.74,19.43,38.11,19.42,29.2,0,38-19.42,5.63-11,5.62-34.77V184h49.06V326q0,36.83-11.42,57.36Q1731,421,1671.08,421t-81.37-37.62q-11.42-20.52-11.42-57.36Z"
        fill="#1e1e1e"
      />
    </g>
    <g class="hidden dark:inline-block">
      <path
        d="M315.71,333.1A226.54,226.54,0,0,0,296,261.53a275.08,275.08,0,0,0-37.5-59.82c-4.29-5.26-8.64-10.3-10.89-12.62l-5.18-5.66c-1.27-1.45-2.33-2.62-3.38-3.65l-1.55-1.57c-1.7-1.7-3.44-3.47-4.89-5.24l-.36-.42c-.71-.77-1.46-1.54-2.31-2.4a45.59,45.59,0,0,1-3.46-3.76l-.44-.52c-7.89-8.68-14.53-16.56-20.29-24.1a160.35,160.35,0,0,1-15.46-23.86A109.5,109.5,0,0,1,180.09,89a176.73,176.73,0,0,1-3.58-32.43,272.07,272.07,0,0,1,1-29.53c.49-5.7,1-10.75,1.58-15.21A10.58,10.58,0,0,0,169.82.07a10.74,10.74,0,0,0-3.91.27,119.89,119.89,0,0,0-15.35,5.18A140,140,0,0,0,119.1,23.67a148.76,148.76,0,0,0-33.63,36A157,157,0,0,0,61.87,118c-4.45,23.08-4,49.13,1.38,73.3,5.06,23.4,14.86,47.29,28.39,69.17a250.3,250.3,0,0,0,20.78,28.91c2,2.51,7.59,9,7.65,9.08l2.22,2.55c1.55,1.76,2.66,3.09,3.8,4.44s1.91,2.28,2.87,3.3l.16.17c10.59,12.66,18,21.95,24.2,30.17,6.81,9.05,12.79,17.82,18.29,26.81a152,152,0,0,1,13.16,26.37A123.66,123.66,0,0,1,191.69,423a183.45,183.45,0,0,1,0,32.81c-1,11.3-2.74,21.61-4.13,29.16-1,5.17-2.05,9.93-3,14A10.61,10.61,0,0,0,197,511.78a110.07,110.07,0,0,0,14.49-3.85,141.09,141.09,0,0,0,32.39-15.51,149.12,149.12,0,0,0,36.27-32.56A163.78,163.78,0,0,0,309,405,185.69,185.69,0,0,0,315.71,333.1Z"
        fill="#fff"
      />
      <path
        d="M108.25,397.9a125.8,125.8,0,0,0-17.11-27.31c-1.75-2.14-3.86-4.6-4.89-5.67l-2.44-2.66c-.63-.72-1.09-1.22-1.58-1.71l-.67-.67c-.77-.77-1.54-1.56-2.2-2.34-.11-.14-.24-.28-.36-.42l-.92-.95c-.53-.55-1.06-1.08-1.51-1.64a4.82,4.82,0,0,0-.36-.43c-3.49-3.82-6.49-7.38-9.13-10.83a74.78,74.78,0,0,1-7.1-11,49.87,49.87,0,0,1-4.65-13.2,79.91,79.91,0,0,1-1.62-14.79,114.28,114.28,0,0,1,.32-11.7,10.58,10.58,0,0,0-15.1-10.31,67.27,67.27,0,0,0-11.44,7,68.16,68.16,0,0,0-15.33,16.41A71.52,71.52,0,0,0,1.38,332.34,84.44,84.44,0,0,0,2,365.82a102,102,0,0,0,13,31.63,114.16,114.16,0,0,0,9.39,13.08c.89,1.1,2.8,3.31,3.65,4.3h0l1,1.07c.75.84,1.27,1.46,1.78,2.09s.83,1,1.18,1.36l.17.18c3.26,3.9,7.47,9,11.06,13.79,3.12,4.14,5.85,8.14,8.34,12.23a68.27,68.27,0,0,1,6,12,56.93,56.93,0,0,1,3.17,14.1,84.15,84.15,0,0,1,0,14.89c-.28,3.34-.75,6.86-1.42,10.77a10.59,10.59,0,0,0,14.4,11.59,65.17,65.17,0,0,0,10.77-5.61A68.3,68.3,0,0,0,101,488.44a74.68,74.68,0,0,0,13.17-25,85.9,85.9,0,0,0,3.09-32.83A103.81,103.81,0,0,0,108.25,397.9Z"
        fill="#fff"
      />
      <path
        d="M413.1,184.33H577.83v40.6H461.06v53.13H563.41v40.13H461.06v96.86h-48Z"
        fill="#fff"
      />
      <path
        d="M777.05,184v40.91H707.92V415.05H659.34V224.93H589.9V184Z"
        fill="#fff"
      />
      <path
        d="M840.05,343.74q2.25,15.83,8.81,23.67,12,14.26,41.13,14.26,17.43,0,28.32-3.76Q939,370.7,939,351.1q0-11.43-10.12-17.71-10.11-6.11-32.07-10.81l-25-5.49q-36.87-8.14-50.66-17.71-23.35-16-23.35-50,0-31,22.84-51.57t67.12-20.53q37,0,63.07,19.36t27.37,56.19H931.75q-1.29-20.85-18.64-29.62-11.58-5.81-28.76-5.8-19.12,0-30.54,7.52t-11.41,21q0,12.39,11.25,18.5,7.23,4.08,30.7,9.56l40.54,9.56q26.65,6.27,40,16.77,20.65,16.31,20.66,47.18,0,31.67-24.48,52.58T891.88,421q-45.63,0-71.77-20.61T794,343.74Z"
        fill="#fff"
      />
      <path
        d="M1197.26,394.52q-26.17,27-75.7,27t-75.71-27q-35.11-33.07-35.11-95.3,0-63.48,35.11-95.29,26.18-27,75.71-27t75.7,27q35,31.81,35,95.29Q1232.22,361.45,1197.26,394.52Zm-30.17-35.11q16.85-21.15,16.85-60.19,0-38.87-16.85-60.1t-45.53-21.24q-28.69,0-45.77,21.16t-17.08,60.18q0,39,17.08,60.19t45.77,21.16q28.68,0,45.53-21.16Z"
        fill="#fff"
      />
      <path
        d="M1412.54,184h54.62l81.74,231h-52.35l-15.24-47.49h-85.08L1380.57,415h-50.5Zm-3.18,143.73h59.17l-29.19-90.91Z"
        fill="#fff"
      />
      <path
        d="M1578.29,184h49.06V325.91q0,23.8,5.62,34.77,8.74,19.43,38.11,19.42,29.2,0,38-19.42,5.63-11,5.62-34.77V184h49.06V326q0,36.83-11.42,57.36Q1731,421,1671.08,421t-81.37-37.62q-11.42-20.52-11.42-57.36Z"
        fill="#fff"
      />
    </g>
  </svg>
</template>
