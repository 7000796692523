<script setup>
import { ref } from "vue";
import IconAdd from "@/components/icons/IconAdd.vue";
import IconLock from "@/components/icons/IconLock.vue";
import RoundedContainer from "@/components/RoundedContainer.vue";
import DelegationConfigureModal from "@/components/DelegationConfigureModal.vue";
import DelegationProvidersModal from "@/components/DelegationProvidersModal.vue";
import { useTruncateString } from "@/helpers/TruncateString";
import { getFtsoProviderMeta } from "@/helpers/Flare";
import { useWalletStore } from "@/stores/wallet";

const wallet = useWalletStore();
const emit = defineEmits(["updated"]);
const props = defineProps({
  slot: {
    default: 0,
  },
  locked: {
    default: false,
  },
});

const delegationConfigureModal = ref(null);
const delegationProvidersModal = ref(null);

function slotClicked() {
  if (props.locked) return;
  if (
    !wallet.ftso.delegations[props.slot].address ||
    wallet.ftso.delegations[props.slot].bips == 0
  ) {
    return delegationProvidersModal.value.show();
  }
  return delegationConfigureModal.value.show();
}

function providerSelected(value) {
  // todo: improve provider selection logic (mutates a persistant state, change to a temporary state)
  wallet.ftso.delegations[props.slot].address = value.address;
  delegationConfigureModal.value.show();
}
</script>

<template>
  <teleport to="body">
    <DelegationConfigureModal
      ref="delegationConfigureModal"
      :configuration="wallet.ftso.delegations[props.slot]"
      @updated="emit('updated')"
    />
  </teleport>
  <teleport to="body">
    <DelegationProvidersModal
      ref="delegationProvidersModal"
      @select="providerSelected"
    />
  </teleport>
  <div
    @click="slotClicked()"
    class="rounded-xl text-center py-1 border dark:border-slate-500 select-none cursor-pointer hover:shadow-sm"
  >
    <div class="flex items-center h-14 w-80 pl-4">
      <template
        v-if="
          !wallet.ftso.delegations[props.slot].address ||
          !wallet.ftso.delegations[props.slot].bips
        "
      >
        <IconLock class="w-5 text-gray-300 dark:text-gray-400" v-if="locked" />
        <IconAdd v-else class="w-5 text-gray-300 dark:text-gray-400" />

        <span
          v-if="locked"
          class="text-gray-300 dark:text-gray-400 font-semibold pl-5"
          >Allocated Maximum</span
        >
        <span v-else class="text-gray-300 dark:text-gray-400 font-semibold pl-5"
          >Delegation Slot {{ parseInt(slot) + 1 }}</span
        >
      </template>
      <template v-else>
        <div class="flex items-center w-full">
          <img
            v-if="wallet.ftso.delegations[props.slot].address"
            class="w-7"
            :src="
              getFtsoProviderMeta(wallet.ftso.delegations[props.slot].address)
                .logoURI
            "
            alt=""
          />
          <div class="text-left pl-4">
            <div v-if="wallet.ftso.delegations[props.slot].address">
              {{
                getFtsoProviderMeta(wallet.ftso.delegations[props.slot].address)
                  .name
              }}
            </div>
            <div class="text-xs text-gray-400">
              {{
                useTruncateString(
                  wallet.ftso.delegations[props.slot].address,
                  16,
                  "..."
                )
              }}
            </div>
          </div>
          <div class="ml-auto pr-6">
            {{ wallet.ftso.delegations[props.slot].bips / 100 }}%
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
