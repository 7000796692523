<script setup>
import AppLayout from "@/layouts/AppLayout.vue";
import { onMounted, ref } from "vue";
import { RouterLink, RouterView } from "vue-router";
import { useWalletStore } from "@/stores/wallet";
import { useThemeStore } from "@/stores/theme";
import IconCaution from "@/components/icons/IconCaution.vue";
import { useErrorStore } from "@/stores/error";

const wallet = useWalletStore();
const theme = useThemeStore();
const errorStore = useErrorStore();

onMounted(async () => {
  // Check if previously connected and initialise if found
  wallet.checkConnection();

  // Check for theme and set
  if (localStorage.theme == "dark") {
    theme.setTheme("dark");
  } else {
    theme.setTheme("light");
  }

  useWalletStore()
    .onReady()
    .then(() => {
      startBalanceUpdater();
    })
    .catch(() => {
      console.warn("Ethereum not initialised");
    });
});

function startBalanceUpdater() {
  setInterval(() => {
    useWalletStore().updateBalancesV2();
  }, 5000);
}
</script>

<template>
  <div
    v-if="errorStore.error"
    class="w-full bg-red-500 py-3 px-2 text-white flex justify-center text-sm gap-x-2 font-medium z-50 relative"
  >
    <IconCaution class="w-4" />{{ errorStore.message }}
  </div>
  <app-layout>
    <RouterView />
  </app-layout>
</template>
