<script setup>
import CheckBox from "./CheckBox.vue";
import ConnectWallet from "./ConnectWallet.vue";
import ConnectWalletCover from "./ConnectWalletCover.vue";
import RoundedContainer from "./RoundedContainer.vue";
import ProgressModal from "./ProgressModal.vue";
import { useWalletStore } from "@/stores/wallet";
import { useNotificationStore } from "@/stores/notification";
import {
  getFlareDropNextDropDate,
  getFlareDropClaimableMonths,
  getFlareDropNextClaimableMonth,
  getFlareDropClaimableAmount,
  claimFlareDrop,
  getFtsoPrice,
  getBalance,
  contract,
} from "@/helpers/Flare";
import { useGtag } from "vue-gtag-next";
import { ref, onMounted, computed } from "vue";

onMounted(async () => {
  // Wait for wallet to connect (only relevant if previously connected)
  useWalletStore()
    .onReady()
    .then(() => {
      initialise();
    })
    .catch(() => {
      console.warn("Ethereum not initialised");
    });
});

const { event } = useGtag();
const wallet = useWalletStore();
const notification = useNotificationStore();

const progressModal = ref(null);

const nextFlareDropRemaining = ref("");
const claimableRewards = ref([]);
const nonEmptyClaimableRewards = ref([]);
const flarePrice = ref(0);

const wrapClaim = ref(true);

defineExpose({
  nonEmptyClaimableRewards,
});

async function initialise() {
  useWalletStore().ethereum.on("accountsChanged", () => {
    initialise();
  });
  if (!wallet.network) return;
  let [nextDropDate, claimableMonths, ftsoPrice] = await Promise.all([
    getFlareDropNextDropDate(),
    getFlareDropClaimableMonths(),
    getFtsoPrice("FLR"),
  ]);

  nextFlareDropRemaining.value = getTimeRemaining(nextDropDate);
  flarePrice.value = Number(ftsoPrice);

  let claimableRewardsPromise = claimableMonths.map((month) =>
    getFlareDropClaimableAmount(Number(month))
  );

  claimableRewards.value = (await Promise.all(claimableRewardsPromise)).map(
    (month) => Number(month) / 10 ** 18
  );
  nonEmptyClaimableRewards.value = claimableRewards.value
    .map((reward, index) => ({ month: index + 1, reward }))
    .filter((month) => month.reward > 0);
}

async function claim() {
  // This claims month by month, resolve to claim all available months (next claimable starts from start to end)
  const claimableMonthLatest = await getFlareDropNextClaimableMonth();
  const claimTransaction = claimFlareDrop(
    Number(claimableMonthLatest),
    wrapClaim.value
  );
  let msg = `Claiming ${
    wrapClaim.value ? "& Wrapping " : ""
  }${claimableAmount.value.toFixed(4)} FLR`;
  progressModal.value.confirm(claimTransaction, msg);
}

function complete(tx) {
  // Analytics
  event("claim_flaredrop_complete", {
    screen_name: "Rewards",
    label: "claim flaredrop complete",
  });

  // Viusal Notificaiton
  notification.create({
    type: "success", // 'success' or 'error' or 'alert'
    title: "Transaction Successful",
    message: `Completed claim of ${claimableAmount.value.toFixed(4)} FLR`,
    linkUrl: `https://${
      wallet.network == "flare"
        ? "flare-explorer.flare.network"
        : "songbird-explorer.flare.network"
    }/tx/${tx.transactionHash}`,
    linkText: `View on ${wallet.network} Explorer`,
    timeout: 5000,
  });

  initialise();
}

const claimableAmount = computed(() =>
  nonEmptyClaimableRewards.value.reduce((a, b) => a + b.reward, 0)
);

function getTimeRemaining(endtime) {
  const total = parseInt(endtime * 1000) - Date.now();
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return `${days}d ${hours}hr ${minutes}min`;
}
</script>

<template>
  <main>
    <!-- Confirmation Modal -->
    <ProgressModal
      ref="progressModal"
      id="progressModal-rewards"
      @complete="complete"
    />
    <div
      class="my-4 p-4 border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 rounded-xl"
    >
      <div
        class="w-full grid grid-cols-2 text-sm text-gray-500 dark:text-gray-300"
      >
        <div>Claimable:</div>
        <div class="text-right">{{ claimableAmount?.toFixed(4) }}</div>
        <div>Month Earnings:</div>
        <div class="text-right">{{ claimableRewards.at(-1)?.toFixed(4) }}</div>
        <div>Next FlareDrop:</div>
        <div class="text-right">{{ nextFlareDropRemaining }}</div>
      </div>
    </div>
    <div
      v-for="item of nonEmptyClaimableRewards"
      class="rounded-xl text-center py-2 px-4 mb-2 border dark:border-slate-500 select-none flex justify-between"
    >
      <div class="flex items-center justify-between w-full">
        <div class="text-sm text-gray-600 dark:text-gray-300">
          Month {{ item.month }}
        </div>
        <div>
          {{ item.reward?.toFixed(4) }}
          <span class="text-gray-600 dark:text-gray-400 text-sm">FLR</span>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div
        class="border-t w-full border-gray-200 dark:border-gray-500 pt-2 mt-4 flex justify-between"
      >
        <div class="font-semibold text-gray-600 dark:text-gray-400">Total:</div>
        <!-- <div class="grid grid-cols-2 gap-x-1">
          <div class="col-span-2 sm:col-span-1">
            {{ claimableAmount.toFixed(4) }}
            <span class="text-gray-500 text-sm">FLR</span>
          </div>
          <div
            class="col-span-2 sm:col-span-1 text-right sm:border-l sm:pl-3 border-gray-200"
          >
            ${{ (claimableAmount * flarePrice).toFixed(4) }}

            <span class="text-gray-500 text-sm">USD</span>
          </div>
        </div> -->
        <div>
          <div>
            {{ claimableAmount.toFixed(4) }}
            <span class="text-gray-600 dark:text-gray-400 text-sm">FLR</span>
          </div>
          <div class="text-xs text-gray-600 dark:text-gray-400 text-right">
            (${{ (claimableAmount * flarePrice).toFixed(3) }} USD)
          </div>
        </div>
      </div>
    </div>

    <!-- Checkbox  -->
    <div class="flex gap-x-3 my-3 justify-between">
      <div class="text-sm text-gray-600 dark:text-gray-400">
        Wrap Tokens On Claim
      </div>
      <CheckBox v-model="wrapClaim" />
    </div>
    <!-- Buttons -->
    <div class="pb-2">
      <ConnectWallet v-if="!wallet.account" class="w-full" />

      <button
        v-if="claimableAmount"
        @click="claim()"
        class="w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
      >
        Claim
      </button>
      <div
        v-else="claimableRewards.at(-1)"
        class="rounded-xl text-center py-2 text-gray-500 border dark:border-slate-500 select-none"
      >
        Claim in {{ nextFlareDropRemaining }}
      </div>
      <!-- <div
        v-else
        class="rounded-xl text-center py-2 text-gray-500 border dark:border-slate-500 select-none"
      >
        No Rewards
      </div> -->
    </div>
  </main>
</template>
