<script setup>
import TheModal from "@/components/TheModal.vue";
import RoundedContainer from "@/components/RoundedContainer.vue";
import ProgressModal from "@/components/ProgressModal.vue";
import DelegationProvidersModal from "@/components/DelegationProvidersModal.vue";

import { useTruncateString as truncate } from "@/helpers/TruncateString";
import { useIsNumberKey as isNumberKey } from "@/helpers/IsNumberKey";
import { getFtsoProviderMeta, setDelegation } from "@/helpers/Flare";
import { ref, onMounted, computed } from "vue";
import { useWalletStore } from "@/stores/wallet";
import { useNotificationStore } from "@/stores/notification";
const modal = ref(null);
const progressModal = ref(null);
const newDelegatedPercentage = ref(0);

const wallet = useWalletStore();
const notification = useNotificationStore();

const emit = defineEmits(["updated"]);
const props = defineProps({
  configuration: {
    type: Object,
    required: true,
  },
});

const maxPercentage = computed(
  () => (10000 - wallet.ftso.bipsAllocated + props.configuration.bips) / 100
);

defineExpose({ show, hide });

function undelegate() {
  // Make undelegate TX
  // setDelegation
  let undelegateTransaction = setDelegation(wallet, {
    address: props.configuration.address,
    bips: 0,
  });

  let msg = `Undelegating from ${
    getFtsoProviderMeta(props.configuration.address).name
  }`;
  progressModal.value.confirm(undelegateTransaction, msg, "UNDELEGATE");
  hide();
}

function delegate() {
  let newDelegatedBips = newDelegatedPercentage.value * 100;
  if (props.configuration.bips === newDelegatedBips) return this.hide(); // No change was made
  let delegateTransaction = setDelegation(wallet, {
    address: props.configuration.address,
    bips: newDelegatedPercentage.value * 100,
  });

  let msg = `Delegating ${newDelegatedPercentage.value}% to ${
    getFtsoProviderMeta(props.configuration.address).name
  }`;
  progressModal.value.confirm(delegateTransaction, msg, "DELEGATE");
  hide();
}

function complete(tx, intent) {
  let title, message;
  if (intent === "DELEGATE") {
    title = "Delegate Successful";
    message = `Delegation to ${
      getFtsoProviderMeta(props.configuration.address).name
    } complete.`;
  }
  if (intent === "UNDELEGATE") {
    title = "Undelegate Successful";
    message = `Undelegated from ${
      getFtsoProviderMeta(props.configuration.address).name
    }.`;
  }

  notification.create({
    type: "success", // 'success' or 'error' or 'alert'
    title,
    message,
    linkUrl: `https://${
      wallet.network == "flare"
        ? "flare-explorer.flare.network"
        : "songbird-explorer.flare.network"
    }/tx/${tx.transactionHash}`,
    linkText: `View on ${wallet.network} Explorer`,
    timeout: 5000,
  });
  emit("updated");
}

function show() {
  // newDelegatedPercentage.value = props.configuration.bips / 100;
  newDelegatedPercentage.value = maxPercentage.value;
  modal.value.show();
}
function hide() {
  modal.value.hide();
}
function verifyInput() {
  if (parseInt(newDelegatedPercentage.value) > maxPercentage)
    newDelegatedPercentage.value = maxPercentage;
}
</script>
<template>
  <!-- Confirmation Modal -->
  <ProgressModal
    ref="progressModal"
    :id="`progressModal-${configuration.slot}`"
    @complete="complete"
  />

  <!-- Providers Modal -->
  <DelegationProvidersModal
    ref="delegationProvidersModal"
    @select="providerSelected"
  />

  <TheModal ref="modal" :id="`delegationConfigureModal-${configuration.slot}`">
    <div>
      <p
        class="relative font-semibold text-gray-600 dark:text-gray-100 select-none pb-6"
      >
        Configure
      </p>
    </div>
    <div class="relative flex items-center h-14 pl-4 w-full">
      <template v-if="props.configuration.address">
        <div class="flex items-center w-full">
          <img
            class="w-7"
            :src="getFtsoProviderMeta(configuration.address).logoURI"
            alt=""
          />
          <div class="text-left pl-4">
            <div class="">
              {{ getFtsoProviderMeta(configuration.address).name }}
            </div>
            <div class="text-xs text-gray-400">
              {{ truncate(props.configuration.address, 16, "...") }}
            </div>
          </div>
          <div class="ml-auto text-lg">
            <RoundedContainer class="select-none">
              <input
                @keyup="verifyInput()"
                @keydown="isNumberKey($event)"
                class="bg-transparent text-right pr-1 focus:outline-none w-12"
                max="100"
                min="0"
                v-model="newDelegatedPercentage"
              />%
            </RoundedContainer>
            <div class="text-center text-sm text-gray-500 pt-1">
              Max:
              {{ maxPercentage }}%
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="flex justify-between pt-4 gap-x-3">
      <button
        @click="undelegate()"
        :disabled="!configuration.bips"
        class="w-48 rounded-xl text-center border border-red-600 bg-red-600 hover:bg-red-700 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
      >
        Undelegate
      </button>

      <button
        @click="delegate()"
        class="w-72 rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed"
      >
        Save
      </button>
    </div>
  </TheModal>
</template>
