<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    class="fill-current"
  >
    <g id="question_mark">
      <path
        d="m18 25h-4c-.553 0-1 .447-1 1v4c0 .553.447 1 1 1h4c.553 0 1-.447 1-1v-4c0-.553-.447-1-1-1zm8-14c0-5.514-4.486-10-10-10s-10 4.486-10 10c0 .553.447 1 1 1h4c.553 0 1-.447 1-1 0-2.206 1.794-4 4-4s4 1.794 4 4-1.794 4-4 4h-2c-.553 0-1 .447-1 1v7c0 .553.447 1 1 1h4c.553 0 1-.447 1-1v-2.456c4.159-1.296 7-5.111 7-9.544z"
      />
    </g>
  </svg>
</template>
