import { defineStore } from "pinia";
import { ethers } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import {
  getWrappedNativeBalance,
  getNativeBalance,
  getAccountLockedVotePower,
  getAccountDelegations,
  getCurrentRewardEpoch,
  getStateOfRewards,
  // @ts-ignore
} from "@/helpers/Flare.js";

import { Avalanche } from "@flarenetwork/flarejs";
import {
  AccountDelegations,
  accountDelegations,
  integerToDecimal,
  toBN,
  unPrefix0x,
} from "@/helpers/Utils";
import { Network, TokenKey, WalletState } from "../types";
import { Provider } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";
import { GetBalanceResponse } from "@flarenetwork/flarejs/dist/apis/platformvm";
import { useErrorStore } from "./error";

let signer: Signer;
let provider: Provider;

// @ts-ignore
const flare = new Avalanche("flare-rpc.ftso.au", undefined, "https", 14);

// @ts-ignore
// if (Number(window.ethereum.chainId) == 162) {
//   console.log("Switching to localflare");
//   flare = new Avalanche("localhost", 9650, "http", 162);
// }

const cchain = flare.CChain();
const pchain = flare.PChain();

export const useWalletStore = defineStore({
  id: "wallet",
  state: (): WalletState => {
    return {
      isReady: false,
      ethereum: null,
      account: null,
      balance: {
        pnative: 0,
        pnativeUnlocked: 0,
        pnativeLocked: 0,
        pnativeStaked: 0,
        native: 0,
        wrappedNative: 0,
      },
      staking: {
        nodes: [],
      },
      ftso: {
        delegations: [
          { address: null, bips: 0, slot: 0 },
          { address: null, bips: 0, slot: 1 },
        ],
        rewardState: {
          dataProviders: [],
          rewardAmounts: [],
          claimed: [],
          claimable: [],
        },
        lockedVotePower: 0,
        bipsAllocated: 0,
      },
      accountKeys: {
        publicKey: undefined,
        addressPchain: undefined,
        addressPchainEncoded: undefined,
        addressCchain: undefined,
      },
      addressBinderBound: false,
      flare: flare,
      cchain: cchain,
      pchain: pchain,
      cKeychain: cchain.keyChain(),
      pKeychain: pchain.keyChain(),
    };
  },
  getters: {
    provider: () => provider,
    signer: () => signer,
    network: (state): Network | null => {
      // @ts-ignore
      if (!state.ethereum) return null;

      // @ts-ignore
      const chainId = Number(state.ethereum.chainId);
      switch (chainId) {
        case 19:
          return "songbird";
        case 14:
          return "flare";
        case 162:
          return "localflare";
        default:
          return null;
      }
    },
    tokenName: (state) => {
      return (input: TokenKey): string => {
        if (!state.network) {
          return "flr";
        }
        const tokenNameMap = {
          songbird: {
            native: "SGB",
            wrappedNative: "WSGB",
          },
          flare: {
            native: "FLR",
            wrappedNative: "WFLR",
          },
        };

        // @ts-ignore
        return tokenNameMap[state.network!][input];
      };
    },
  },
  actions: {
    async onReady() {
      return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(false);
        }, 5000);

        const interval = setInterval(() => {
          if (this.isReady) {
            clearInterval(interval);
            clearTimeout(timeout);
            resolve(true);
          }
        }, 50);
      });
    },
    async updateBalances() {
      if (!this.network) return;

      const [nativeBalance, wrappedNativeBalance] = await Promise.all([
        getNativeBalance(this),
        getWrappedNativeBalance(this),
      ]);
      this.balance.native = Number(nativeBalance);

      this.balance.wrappedNative = Number(wrappedNativeBalance);
    },
    async updateBalancesV2() {
      const balancePromises: [
        Promise<ethers.BigNumber>,
        Promise<GetBalanceResponse>?,
        Promise<AccountDelegations>?
      ] = [this.signer.getBalance()];

      if (this.accountKeys.addressPchain) {
        balancePromises.push(
          this.pchain.getBalance("P-" + this.accountKeys.addressPchain!),
          accountDelegations(this)
        );
      }

      const [cBalance, pBalances, stakedDelegations] = await Promise.all(
        balancePromises
      );

      if (pBalances) {
        const pbalance = toBN(pBalances.balance)!.toString();
        const pbalanceUnlocked = toBN(pBalances.unlocked)!.toString();
        const pbalanceLocked = toBN(pBalances.lockedNotStakeable)!.toString();

        this.balance.pnative = Number(integerToDecimal(pbalance, 9));
        this.balance.pnativeUnlocked = Number(
          integerToDecimal(pbalanceUnlocked, 9)
        );
        this.balance.pnativeLocked = Number(
          integerToDecimal(pbalanceLocked, 9)
        );
      }

      if (stakedDelegations) {
        this.balance.pnativeStaked = stakedDelegations.stakeSum;
        this.staking.nodes = stakedDelegations.nodes;
      }

      this.balance.native = Number(integerToDecimal(cBalance.toString(), 18));
    },
    async updateDelegations() {
      if (!this.network) return;

      const _accountDelegations = await getAccountDelegations(this);
      this.ftso.delegations = [
        { address: null, bips: 0, slot: 0 },
        { address: null, bips: 0, slot: 1 },
      ];
      for (let index = 0; index < _accountDelegations.count; index++) {
        this.ftso.delegations[index].address =
          _accountDelegations.delegateAddresses[index];
        this.ftso.delegations[index].bips = _accountDelegations.bips[index];
      }
      this.ftso.bipsAllocated = _accountDelegations.bips.reduce(
        (a: number, b: number) => a + b,
        0
      );
    },
    async updateLockedVotePower() {
      if (!this.network) return;

      this.ftso.lockedVotePower = await getAccountLockedVotePower(this);
    },
    async updateFtsoRewardState() {
      if (!this.network) return;

      const currentRewardEpoch = await getCurrentRewardEpoch();
      this.ftso.rewardState = await getStateOfRewards(this, currentRewardEpoch);
    },
    async connect() {
      const ethereumProvider = await detectEthereumProvider();

      provider = new ethers.providers.Web3Provider(ethereumProvider as any);

      if (provider) {
        try {
          // this.account = (await provider.send("eth_requestAccounts", []))[0];
          const accounts = await (provider as any).send(
            "eth_requestAccounts",
            []
          );
          // @ts-ignore
          this.ethereum = provider.provider;
          signer = (provider as any).getSigner();
          this.handleAccountsChanged(accounts);
        } catch (error) {
          console.error("metamask error", error);
        }
      } else {
        // if the provider is not detected, detectEthereumProvider resolves to null
        console.error("Please install MetaMask!");
      }

      this.ethereum?.on("accountsChanged", (accounts) => {
        this.handleAccountsChanged(accounts);
      });

      this.ethereum?.on("chainChanged", () => {
        location.reload();
      });

      this.isReady = true;
    },
    async connectChainsAndKeys() {
      if (!this.accountKeys.publicKey)
        throw new Error("Public key not defined in wallet.");
      this.cKeychain.importKey(
        `PublicKey-${unPrefix0x(this.accountKeys.publicKey)}`
      );
      this.pKeychain.importKey(
        `PublicKey-${unPrefix0x(this.accountKeys.publicKey)}`
      );
    },
    handleAccountsChanged(accounts: Array<string>) {
      if (!accounts.length) return (this.account = null);
      this.account = accounts[0];
      this.accountKeys = {
        publicKey: undefined,
        addressPchain: undefined,
        addressPchainEncoded: undefined,
        addressCchain: undefined,
      };

      this.balance = {
        pnative: 0,
        pnativeUnlocked: 0,
        pnativeLocked: 0,
        pnativeStaked: 0,
        native: 0,
        wrappedNative: 0,
      };

      this.staking = {
        nodes: [],
      };
      this.updateBalances();
    },
    async checkConnection() {
      try {
        const ethereum = await detectEthereumProvider();
        if (!ethereum) {
          useErrorStore().makeError(
            "INFO",
            "Ethereum client not found, use Web3 compatible browser."
          );
        }
        // @ts-ignore
        if (ethereum?.selectedAddress) this.connect();
      } catch (error) {
        console.warn("MetaMask: Ethereum not detected.");
      }
    },
  },
});
