import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import WrapView from "../views/WrapView.vue";
import DelegateView from "../views/DelegateView.vue";
import RewardsView from "../views/RewardsView.vue";
import StakeView from "../views/StakeView.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: (to) => {
        // the function receives the target route as the argument
        // we return a redirect path/location here.
        return { path: "/wrap" };
      },
    },
    {
      path: "/wrap",
      name: "wrap",
      component: WrapView,
    },
    {
      path: "/delegate",
      name: "delegate",
      component: DelegateView,
    },
    {
      path: "/rewards",
      name: "rewards",
      component: RewardsView,
    },
    {
      path: "/stake",
      name: "stake",
      component: StakeView,
    },
  ],
});

trackRouter(router);

export default router;
