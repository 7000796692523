import { WalletStore } from "../types/global";
import { ValidatorItem } from "./Utils";

export interface ValidatatorListItem {
  name: string;
  address: string;
}

export type ValidatorList = Array<ValidatatorListItem>;
export interface PairedValidatorList
  extends ValidatorItem,
    ValidatatorListItem {}

export async function getValidatorList(wallet: WalletStore) {
  const { validators } = await wallet.pchain.getCurrentValidators();
  const minTimestamp = Math.floor(+new Date() / 1000) + 14 * 24 * 60 * 60;
  const pairedValidators = validators
    .filter((val: ValidatorItem) => Number(val.endTime) > minTimestamp)
    .map((val: ValidatorItem) => {
      for (const valMeta of validatorsList) {
        if (valMeta.nodeId.toLowerCase() == val.nodeID.toLowerCase()) {
          return {
            name: valMeta.name,
            address: valMeta.nodeId,
            ...val,
          };
        }
      }
      return {
        name: "Unknown",
        address: val.nodeID,
        ...val,
      };
    });

  return pairedValidators.filter((item: any) => item !== undefined);
}

export const validatorsList = [
  {
    name: "FTSO.EU",
    ftsoAddress: "0xb0421af2cFfB21D8a0Be4087448146E4f9cbD306",
    nodeId: "NodeID-B9o4BTqdc1qkrAn5BxLHL7pimWf9UwBkd",
  },
  {
    name: "Poseidon FTSO",
    ftsoAddress: "0x02a256FF137D680aC591f1959D64a8669006c95c",
    nodeId: "NodeID-CtSfUZd8nvr4RDaQnKRiP9cae15Nrb1bX",
  },
  {
    name: "NorTSO",
    ftsoAddress: "0x00c0fFEf480E392f5Fe7af592214855Ff872fa80",
    nodeId: "NodeID-3RJrjDMzeZQVDyHDjZ6u1X3Y3WCKY5nbb",
  },
  {
    name: "AlphaOracle",
    ftsoAddress: "0x47B6EfFE71ABD4e8CdCC56f2341BEb404f804b87",
    nodeId: "NodeID-KRkND3cB1bYaWt71CSQdCUjRSR95d3cNz",
  },
  {
    name: "Ugly Kitty",
    ftsoAddress: "0x35149714467F2FE71b46eEb4d11689ED8728Bd25",
    nodeId: "NodeID-LcbbZYhBHn8HVjTzPASuHtFVAckcdctWS",
  },
  {
    name: "Evolve",
    ftsoAddress: "0x9b5F4A2177135A2A6DC83893D5d58e684419aB24",
    nodeId: "NodeID-FXJQRCN2nEqBYVsorBS5WHriwihjzv7G6",
  },
  {
    name: "FTSO London",
    ftsoAddress: "0x4429306b301a0EB573Fa5758BB40AA581255c624",
    nodeId: "NodeID-P6nNyHc4QwyKoVSS7c9XatGp4Sityq7rM",
  },
  {
    name: "FTSO UK",
    ftsoAddress: "0x5F911C2C681f678e5A3a8D54f950d6b192CC16E3",
    nodeId: "NodeID-LKS1hi95iVbqGDyU1vbkyPLd4wknJfqF9",
  },
  {
    name: "Oracle Daemon 1",
    ftsoAddress: "0xfe532cB6Fb3C47940aeA7BeAd4d61C5e041D950e",
    nodeId: "NodeID-2a7BPY7UeJv2njMuyUHfBSTeQCYZj6bwV",
  },
  {
    name: "Oracle Daemon 2",
    ftsoAddress: "0xfe532cB6Fb3C47940aeA7BeAd4d61C5e041D950e",
    nodeId: "NodeID-B1iKSMU2WwchpMakQ9Xn3UDivEavzKLf8",
  },
  {
    name: "Lena Instruments",
    ftsoAddress: "0xdde9BcB57cbA00A9718b67b4074ec6B62C542957",
    nodeId: "NodeID-5p5cZG1uChANjECyfNkz7wswftikMfeSd",
  },
  {
    name: "Use Your Spark",
    ftsoAddress: "0xa288054b230DCbb8689Ac229D6DBd7Df39203181",
    nodeId: "NodeID-MzMscku8ayAd2pBEmH8xWiAXBwtPgS2S5",
  },
  {
    name: "AureusOx",
    ftsoAddress: "0x9269fb79B098AB314de8A1E2AFb8705678520443",
    nodeId: "NodeID-3phxSiKoYMj1dNSJysZhHYQKUbLX786jy",
  },
  {
    name: "4DadsFTSO",
    ftsoAddress: "0xC522E6A633545872f1afc0cdD7b2D96d97E3dE67",
    nodeId: "NodeID-CoNH4gyEwB9gTrEmozwh14Zr8hs6wokRS",
  },
  {
    name: "Flare Dienst",
    ftsoAddress: "0xDe40311b4b538392e0e7dCC134426C397c956cA9",
    nodeId: "NodeID-5V2bQbryZTU4pAe59KNHNBru1BwEfcXqg",
  },
  {
    name: "Scandinodes",
    ftsoAddress: "0xB6d771E1892EB2c04b136C13Abe23390022e8206",
    nodeId: "NodeID-2EZag8YdrYLHLvbA9CFLPz6MhNU45nyTE",
  },
  {
    name: "A-FTSO",
    ftsoAddress: "0xAF7504242064532CbD3370aD7591452B1D09BBdc",
    nodeId: "NodeID-6cnsMcUNTJwCcDuAyPwJqEaVa6yMrk8eq",
  },
  {
    name: "FTSO AU 1",
    ftsoAddress: "0x4990320858AE3528B645C60059281a66C3488888",
    nodeId: "NodeID-HZwFckGTbucqTtE7qcv6z85mnScUfAeW6",
  },
  {
    name: "FTSO AU 2",
    ftsoAddress: "0x4990320858AE3528B645C60059281a66C3488888",
    nodeId: "NodeID-Bo98cjoi5LvF6jXAMHZZLQYutyeGutBQ1",
  },
  {
    name: "ACDT",
    ftsoAddress: "0xAc2884A4479Bf7c21AA0462d52bc9c76c3a9A3dD",
    nodeId: "NodeID-JA63moobNNMLby4ENNUiJqVJA2cdpks5J",
  },
  {
    name: "LightFTSO",
    ftsoAddress: "0xA9C69eb9De79188A9ABa46c5336607F88A80eC89",
    nodeId: "NodeID-4e5tHaeoLvpXjtY5uMXtuYJgEWZ2JiC4D",
  },
  {
    name: "Bifrost Wallet 1",
    ftsoAddress: "0x9A46864A3b0a7805B266C445289C3fAD1E48f18e",
    nodeId: "NodeID-EgJba99Q5iDcL81A27E111WANn9eZNj4v",
  },
  {
    name: "Bifrost Wallet 2",
    ftsoAddress: "0x9A46864A3b0a7805B266C445289C3fAD1E48f18e",
    nodeId: "NodeID-NzPsG7kxDpS49cqWhvwaNXbJYPvVgTu6N",
  },
  {
    name: "Google Cloud",
    ftsoAddress: "0x50a1bca4d5e91b3bcea7891742248f5ba5acf0e5",
    nodeId: "NodeID-Gy2c6Y1RNz2PZfAnTwHZMZ4RZ53nKPf1Q",
  },
  {
    name: "TempestFTSO",
    ftsoAddress: "0x96616c93747baBE136bB56310bE2AE665b18dA63",
    nodeId: "NodeID-98RaC6gbJMhin1NXsBFUoicEqiF7VKWbL",
  },
  {
    name: "Flare Oracle 1",
    ftsoAddress: "0xF0F095bbd5e2E33e9c1703cdEDd0015280406E90",
    nodeId: "NodeID-J8Zbi3b3AjTEUmHdE4KSApEU1U36iaeDS",
  },
  {
    name: "Flare Oracle 2",
    ftsoAddress: "0xF0F095bbd5e2E33e9c1703cdEDd0015280406E90",
    nodeId: "NodeID-4hyFQEeDqpG9yyEnafTwqQQTTFex32KG2",
  },
  {
    name: "FTSOCAN",
    ftsoAddress: "0x9e55a49D251324B1623dc2A81894D1AfBfB8bbdC",
    nodeId: "NodeID-KzPd2Vx5WomGtur91B9K9R7to3mYyYga",
  },
  {
    name: "WitterFTSO",
    ftsoAddress: "0x4A74e79738009711a6ddB6653614d43ba9F431b9",
    nodeId: "NodeID-NbTToANYcdsreLC5PBcE9gsMBpBY7N7s1",
  },
  {
    name: "FTSO Plus",
    ftsoAddress: "0x3D2c08eD9B2333cbce2b8A219e02F4Aa31ebcCd3",
    nodeId: "NodeID-MN7sJduRdGUs3aWyYUmym2CFVsptzgKcJ",
  },
  {
    name: "Flare.Space 1",
    ftsoAddress: "0x111246F191a2A20012723369d3CEc77777E774E9",
    nodeId: "NodeID-ECqqsWu9jpUvZgPyf9yPZZcyWmJjWyN1T",
  },
  {
    name: "Flare.Space 2",
    ftsoAddress: "0x111246F191a2A20012723369d3CEc77777E774E9",
    nodeId: "NodeID-7vz3A92AkLi9YRYTauPgvkdiMmhnuDRzj",
  },
  {
    name: "AFOracle 1",
    ftsoAddress: "0xAf05Ac13F4a4e754a496B46bbd611F5FFDb42606",
    nodeId: "NodeID-FDR3CXdwP3H3Zz9QKfL61fk56zkPEgdFV",
  },
  {
    name: "AFOracle 2",
    ftsoAddress: "0xAf05Ac13F4a4e754a496B46bbd611F5FFDb42606",
    nodeId: "NodeID-EPLACd344ouymyJAQYXZBvbmU3NJ6ooeB",
  },
  {
    name: "Xdrops Oracle",
    ftsoAddress: "0x112B1130BD78B5A520adeace6130151E07a270a7",
    nodeId: "NodeID-B6sAUBEYn2fvHMeUnsUkh1yNrvTzKu8gZ",
  },
  {
    name: "Grungies",
    ftsoAddress: "0x085841B253590281cc5c5222b09D4e59a605E774",
    nodeId: "NodeID-J4nyqo44xFUxhAi9gDJXAkYyxP5z2a7UG",
  },
  {
    name: "Ivy Oracle",
    ftsoAddress: "0x64D998BC81424131E5aF05071263fDeBD1a82986",
    nodeId: "NodeID-5jV2rg4CGt6ewiDbB9QeWDEptVudskJeY",
  },
  {
    name: "Flare Beacon",
    ftsoAddress: "0xdd33Ba13F32C90D678422251B52B005335fB7A4c",
    nodeId: "NodeID-CrsvTSFLB8G3ziPaVjgH1pj8NQxSZkECD",
  },
  {
    name: "Atlas TSO",
    ftsoAddress: "0x07702A7494F760B0b3642463BdD2B7A13cFDDbb2",
    nodeId: "NodeID-MsF6Xc1PsMWtcxVudCepWb5kUeF1EF8RX",
  },
  {
    name: "African Proofs",
    ftsoAddress: "0x7808b9E0F7c488172B54B30f98C2FcF36D903B2c",
    nodeId: "NodeID-FLPF99Gi1c79NGHw7GYyhttWPVxpCq713",
  },
  {
    name: "HEWG",
    ftsoAddress: "0xB6d68Ea6C4dE734Ec481F92AFD1C35F712441b73",
    nodeId: "NodeID-2hCHs6H4Vde8mdGGKidMvT4Fjit7goCBT",
  },
  {
    name: "Sparkles",
    ftsoAddress: "0x3D985CFF3C4680428d5ce093dBc4919a57F6E2f6",
    nodeId: "NodeID-9Y4mWN6L4MPLA2tvAwMXDJXeAJYfD5Try",
  },
  {
    name: "True FTSO",
    ftsoAddress: "0xb6deD9D9CA19af10C67f9A8be8ca75e38E166faA",
    nodeId: "NodeID-Ckyh9cpfkkXSo5PYNNFactcrKkY2XhkXR",
  },
  {
    name: "UGaenn",
    ftsoAddress: "0xe3a76233885E355cFaF141D7dd3D92705C9db4D5",
    nodeId: "NodeID-KtTdea6FeDDy5pERrZKHkkhtJFCbJNv1a",
  },
  {
    name: "MBF",
    ftsoAddress: "0x4E94Dbff86b7f1F5ac9FD849E8101A4d52E947eC",
    nodeId: "NodeID-NCBqn9P8oAEkYn6McyQjhF67WarSTttz5",
  },
  {
    name: "EDP FTSO",
    ftsoAddress: "0xad918962795547a8c997F96f7BAbB822612a5FfE",
    nodeId: "NodeID-EqTrzH8b9ax2oYwrKFkXqWAZjuK8uHFxS",
  },
  {
    name: "Aternety",
    ftsoAddress: "0xD3956F862a4960bB4937e596a2BaeCFfCbb4b3e0",
    nodeId: "NodeID-P4T4uKqveVxjZZXVdADHENdnCKnsCFvDE",
  },
  {
    name: "PriceKraken",
    ftsoAddress: "0xB95f930711DA83226416FFaAB084249B2e01e1F2",
    nodeId: "NodeID-7niiMEHRfGLgT6VZ7bwb6Xa6vpGn8upVw",
  },
  {
    name: "Tailwind",
    ftsoAddress: "0xC8c1F188a891b8627e2cBcdc8a67A2Ed01aB8f10",
    nodeId: "NodeID-JF4XWnC9PjZVXQuqLcpQvxztdMez3CpkR",
  },
  {
    name: "Knot Nodes",
    ftsoAddress: "0xF33A0Ac50f2E85737af577ea68583f264C7A1f78",
    nodeId: "NodeID-JFiAG1SiqUFh3EnkY8oXcXUg8RuT2guZp",
  },
  {
    name: "Scintilla",
    ftsoAddress: "0xeFb939Ebe430efD987c2650367a1b2c9f070d3f1",
    nodeId: "NodeID-EBcyABp9i2wEgZbhiwnyLaqBwFFTdZXyd",
  },
  {
    name: "sToadz 1",
    ftsoAddress: "0x729589694a78FF2D8BACf75b7AC4389bd53ee533",
    nodeId: "NodeID-2xbUrUGcj9HuntjA4YPHQFWiMuQz428SL",
  },
  {
    name: "sToadz 2",
    ftsoAddress: "0x729589694a78FF2D8BACf75b7AC4389bd53ee533",
    nodeId: "NodeID-F297gLgRxBndijAYvvvBGtQTwYCrScMQT",
  },
  {
    name: "sToadz 3",
    ftsoAddress: "0x729589694a78FF2D8BACf75b7AC4389bd53ee533",
    nodeId: "NodeID-NV7ZpcJvqzmvvT4PaeHWbTELWKFT2Jqxw",
  },
  {
    name: "SolidiFi 1",
    ftsoAddress: "0x4CfB79399cd840414a2BeF2c7C7A0cE3b9b0F89d",
    nodeId: "NodeID-EJ7V3trSnp93txcLP3h4ULJvBhvUtP66c",
  },
  {
    name: "SolidiFi 2",
    ftsoAddress: "0x4CfB79399cd840414a2BeF2c7C7A0cE3b9b0F89d",
    nodeId: "NodeID-KxxMhtjoToLqBkLTFbc3q65SDcCJsDt9S",
  },
  {
    name: "Aimlezz",
    ftsoAddress: "0xD1eDC6Dd3Ba8b0881A44002Ac501a69E924b8F00",
    nodeId: "NodeID-DcUJTi6u5Xoutgr1A25iMu4Rp5JEbsEqr",
  },
  {
    name: "Bushi FTSO",
    ftsoAddress: "0xC7cF3238D2ca63d01Ad4d42B4cCB9dB8b0adE702",
    nodeId: "NodeID-JHnfNSNgSThSJEFG8aq99FRAE4a7Ko7UP",
  },
  {
    name: "Solarius",
    ftsoAddress: "0x8863eAD675DfF5Cf260D5FdC079d50996D1F3cd4",
    nodeId: "NodeID-2q436GJKA5faTceNaAbu7CYxftv3K8fS4",
  },
  {
    name: "Sun-Dara",
    ftsoAddress: "0x1E8F916CE03F4ce86186531a8994d366581Ed4be",
    nodeId: "NodeID-Cg8PLmdZ9JmEtUBCZhq2jt2JDS5gDaSsQ",
  },
  {
    name: "Burst FTSO",
    ftsoAddress: "0x670a7Fd22cAa9C2Fb5B798078d084B29AB4bC63C",
    nodeId: "NodeID-9jaZDNBCn4AwMzRqFBhxfem2ZZtiArRm2",
  },
  {
    name: "DataVector 1",
    ftsoAddress: "0xCaA49C97318b6Bb62b7F9241891D70F87FC05D35",
    nodeId: "NodeID-7joNh7X4xsHBSXCpLQig22nSqU6xp41k8",
  },
  {
    name: "DataVector 2",
    ftsoAddress: "0xCaA49C97318b6Bb62b7F9241891D70F87FC05D35",
    nodeId: "NodeID-3qpQ3cVcU8UxvmCtkwGV4ZB3RYcKoDBRn",
  },
  {
    name: "FlareFTSO",
    ftsoAddress: "0x58048528D3d3aea14Ec95eb5e98b18dE51780e27",
    nodeId: "NodeID-J9JZSkVJWCUYZ43n36q4fCixu7Tj4QR1P",
  },
  {
    name: "Envision",
    ftsoAddress: "0x9b42B895D2A10D048eaf4996fDf93aEBf59167BF",
    nodeId: "NodeID-DAVrAR2VCxe7rnmkDaVUbW8jWd9b86cS",
  },
  {
    name: "Oracle Beast",
    ftsoAddress: "0x5E2aFfA528DB55feE8cf8cCC41d0A5bb8BaCedC3",
    nodeId: "NodeID-8G1uigBjUrTgzxRrsnoNN2CzZtYvLqpLH",
  },
  {
    name: "Comfy Nodes",
    ftsoAddress: "0x9C44c70180642cF4Cfd06d77b84Bb8F864ea5697",
    nodeId: "NodeID-FERvNWfCH4mjCSoFnjF4YouDBuYZCpfKm",
  },
  {
    name: "FTShizO",
    ftsoAddress: "0xa41d19F4258a388c639B7CcD938FCE3fb7D05e86",
    nodeId: "NodeID-KppZbRMtfNTQZGdmHdkbHTJHgNPBCNxJj",
  },
  {
    name: "Wonder FTSO",
    ftsoAddress: "0x4c1F288cAFECbbDac653C2170337c38e62c400E9",
    nodeId: "NodeID-C3FRhsMBh3KNLKiVbc8xLY7gnVzmJ7EY9",
  },
  {
    name: "FocusTSO 1",
    ftsoAddress: "0xb084575CaCedf7DEdd78329656715651F8f66712",
    nodeId: "NodeID-D3QKd6XxLnrKowkziZJSG4RNLtffz8ESk",
  },
  {
    name: "FocusTSO 2",
    ftsoAddress: "0xb084575CaCedf7DEdd78329656715651F8f66712",
    nodeId: "NodeID-G5kkF5H1AGaY3n9h9PCXdiFqjyP1jYhJW",
  },
  {
    name: "DeFi Oracles",
    ftsoAddress: "0x3fE77e9be1ECcDe815311f9bcc40814F4eC6AE09",
    nodeId: "NodeID-9WF8MvjNhmR45AdNjVfAWp27KKq7KCGpm",
  },
  {
    name: "FlarePortal",
    ftsoAddress: "0x9225db8B30A59D8Dd15448E2E5918BD160262b5D",
    nodeId: "NodeID-Nwi6MgscyMFQciLyabSFWpWHBQATBVGfk",
  },
  {
    name: "Flareis",
    ftsoAddress: "0xf8B1Dcf2594AfD082aae088661bF574CB9BbDC61",
    nodeId: "NodeID-66rV9yEWSVGLC8fs9ynKptVf9f6bSbHC8",
  },
  {
    name: "Luganodes",
    ftsoAddress: "0xB53F6CFaB63CAbFc255c702ebeB273510DB541d7",
    nodeId: "NodeID-MWNqxgXd8itY143jaNyq8aMYTeV1RXJgS",
  },
  {
    name: "Sensei Node",
    ftsoAddress: "0xe08898B7B8b18dBCdDcC6339c8b9c19EFfA81413",
    nodeId: "NodeID-xi5DWBUKqBJUWvHiDEr9hpVNLGbxRtu2",
  },
  {
    name: "Restake",
    ftsoAddress: "0xbF55a6eb78487F49197806025CA82DdaF633204e",
    nodeId: "NodeID-JZuVSDnBui1u82RmkbkWKJ1JxfdxuVYR9",
  },
  {
    name: "Kiln",
    ftsoAddress: "0x6df84895f1f1f6F6767C59324F94089d4097051A",
    nodeId: "NodeID-FHPDnySFVqde5bGvEXsFnvZGhXwcyRfNY",
  },
  {
    name: "INFStones 1",
    ftsoAddress: "0xB1Aa0F2691Db6bBb2969EfC7BE70787F58DD2461",
    nodeId: "NodeID-5JkXthgpWVDq6utrtV1YVG7aspsHjhezM",
  },
  {
    name: "INFStones 2",
    ftsoAddress: "0xB1Aa0F2691Db6bBb2969EfC7BE70787F58DD2461",
    nodeId: "NodeID-4zWvBNmsbaS9UKQf6wcWgWGWRfitcGAuV",
  },
  {
    name: "Figment",
    ftsoAddress: "0x08233630C4C9e2c1f865B5519e70047EaBAeFAe2",
    nodeId: "NodeID-Q9KeAQKSyxtJj8mfum951Q5akkoFBJLTs",
  },
  {
    name: "FTSO Express",
    ftsoAddress: "0xc0452CEcee694Ab416d19E95a0907f022DEC5664",
    nodeId: "NodeID-CeX1NfNPHCdaJXUVtkLysShaZCtsHRK3D",
  },
  {
    name: "Into The Block",
    ftsoAddress: "0x2e8320B92B640cF8CBdEaFFBe70d4bC407303b9F",
    nodeId: "NodeID-8qSyua2WVQ4uNRc1wwPKmSUWY421bifiq",
  },
  {
    name: "Gateway.fm",
    ftsoAddress: "0xf26Be97eB0d7a9fBf8d67f813D3Be411445885ce",
    nodeId: "NodeID-LxpVHRQAYXPRf7q5oz1iT4N1z9XHz5rHU",
  },
  {
    name: "InGen",
    ftsoAddress: "0xdd7B2Bac728f027f23aDD7128711ecB60f761aD5",
    nodeId: "NodeID-4h6PNpK5oo9pjmE8zKSbm31S2LStg4tyF",
  },
  {
    name: "Flare Node 1",
    ftsoAddress: "0x0000000000000000000000000000000000000000",
    nodeId: "NodeID-C6i8mruq11VdxGQ7tiUBgrRqoLBot86df",
  },
  {
    name: "Flare Node 2 ",
    ftsoAddress: "0x0000000000000000000000000000000000000000",
    nodeId: "NodeID-NCfvDEdDv8tuVDxWLB1ShJToQ7qAcca2B",
  },
  {
    name: "StakeCapital",
    ftsoAddress: "0x9Dfc1c62B7F7a3D4D412Fc502Cba37157446aEe9",
    nodeId: "NodeID-FUA8LX6gJhNmiJVT5eycNsd2jKBHL7FBY",
  },
  {
    name: "A41",
    ftsoAddress: "0x90C6423ec3Ea40591bAdb177171B64c7e6556028",
    nodeId: "NodeID-A7jk7Z5GA2aVe5ecuupNXWtSLfVjLDWFC",
  },
  {
    name: "ANKR",
    ftsoAddress: "0xB7fD410Bb86f427b2d2C75eFe6e35e621465913b",
    nodeId: "NodeID-MfzBQ6MbriE31UWm9NjSnEgLiR1cAkEnN",
  },
  {
    name: "Northstake",
    ftsoAddress: "0x0000000000000000000000000000000000000000",
    nodeId: "NodeID-3v6zserYt9MD58FCmsPo2Qto5AwYAkm6s",
  },
  {
    name: "Foundry Digital",
    ftsoAddress: "0x0000000000000000000000000000000000000000",
    nodeId: "NodeID-JhEPasSSZPBmu1pKfLeuYYf5Y7oPEziZy",
  },
  {
    name: "Chainbase",
    ftsoAddress: "0x0000000000000000000000000000000000000000",
    nodeId: "NodeID-7HLcuEFt7c1qTggK8AkLim9ekhPu2NYLM",
  },
  {
    name: "DoraHacks",
    ftsoAddress: "0x0000000000000000000000000000000000000000",
    nodeId: "NodeID-4DvGBELqDhT2xS3gP4xA4RKAKrShWVYRG",
  },
  {
    name: "Starlink Oracle",
    ftsoAddress: "0xEb190a42eff3bCCAf65A432F2B7D2b1CaBE30c25",
    nodeId: "NodeID-GPBkpSGC7YpRbd1TsNZk84C2TQfNja6FQ",
  },
  {
    name: "FTSO4All",
    ftsoAddress: "0x0937e91c116767ffd2256abc15b67561bad5d1c2",
    nodeId: "NodeID-9vmnHcK2XCBDf7uNfkq6CqRGpE6FJsN5K",
  },
];
