<script setup lang="ts">
import { Ref, onMounted } from "vue";
import { ref, watch } from "vue";
import { useWalletStore } from "@/stores/wallet";
import { BN } from "@flarenetwork/flarejs";
// @ts-ignore
import { useIsNumberKey as isNumberKey } from "@/helpers/IsNumberKey";
import { calculateFeeEstimate } from "@/helpers/flare/evmAtomicTx";
import { ethers } from "ethers";

import IconSpinner from "@/components/icons/IconSpinner.vue";

const wallet = useWalletStore();
const inputAmount: Ref<number | undefined> = ref();
const inputChain: Ref<"pchain" | "cchain"> = ref("pchain");
const feeEstimate: Ref<number> = ref(0);

onMounted(async () => {
  useWalletStore()
    .onReady()
    .then(() => {
      getFeeEstimate();
      setTimeout(async () => {
        getFeeEstimate();
      }, 10000);
    });
});

async function getFeeEstimate() {
  let type: "export" | "import" =
    inputChain.value == "pchain" ? "export" : "import";
  feeEstimate.value = await calculateFeeEstimate(
    wallet,
    new BN(Number(inputAmount.value)),
    type
  );
}

function verifyInput() {
  if (!inputAmount.value) return;

  if (wallet.balance["native"] < 1) {
    inputAmount.value = 0;
    inputAmount.value = wallet.balance["native"] - 1;
  }

  switch (inputChain.value) {
    case "pchain":
      if (inputAmount.value > wallet.balance["native"]) {
        inputAmount.value = Number(wallet.balance["native"].toFixed(4));
      }
      break;

    case "cchain":
      if (inputAmount.value > wallet.balance["pnative"]) {
        inputAmount.value = Number(wallet.balance["pnative"].toFixed(4));
      }
      break;
    default:
      break;
  }
}

defineExpose({ reset });

defineProps({
  locked: { type: Boolean, default: false },
  modelValue: Object,
});

const emit = defineEmits(["update:modelValue"]);

watch(inputChain, () => (inputAmount.value = undefined));

function reset() {
  inputAmount.value = undefined;
}

function updateValue() {
  emit("update:modelValue", {
    inputChain: inputChain.value,
    inputAmount: inputAmount.value,
  });
}
</script>

<template>
  <div
    class="rounded-lg w-full border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 px-4 py-2"
  >
    <div class="text-sm font-bold text-gray-500 dark:text-gray-200">
      Transfer
    </div>
    <div class="flex">
      <select
        v-model="inputChain"
        @change="updateValue"
        :disabled="locked"
        class="border-none hide-spinners display-none focus:ring-0 text-left flex-auto text-lg bg-transparent text-black dark:text-white focus:outline-none"
        :class="locked ? 'ml-1 mt-[1px] appearance-none' : ''"
      >
        <option value="pchain">P Chain</option>
        <option value="cchain">C Chain</option>
      </select>
      <div
        v-if="!locked"
        class="flex h-full items-center text-lg dark:text-white"
      >
        <input
          v-model="inputAmount"
          :disabled="!wallet.account"
          tabindex="0"
          @blur="verifyInput()"
          @keydown="isNumberKey($event, inputAmount)"
          @paste="isNumberKey($event, inputAmount)"
          @input="updateValue"
          inputmode="decimal"
          autocomplete="off"
          autocorrect="off"
          type="text"
          pattern="^[0-9]*[.,]?[0-9]*$"
          placeholder="0.0"
          minlength="1"
          maxlength="79"
          spellcheck="false"
          :max="wallet.balance['native']"
          min="1"
          class="h-full right-0 border-none display-none focus:ring-0 text-right flex-auto w-full bg-transparent focus:outline-none"
        />
        <span class="pt-[2px] pl-1">FLR</span>
      </div>
      <div v-else class="flex h-full items-center text-lg dark:text-white">
        <input
          :value="
            Number(inputAmount)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6,
            })
          "
          :disabled="true"
          class="h-full right-0 border-none display-none focus:ring-0 text-right flex-auto w-full bg-transparent focus:outline-none"
        />
        <span class="pt-[2px] pl-1">FLR</span>
      </div>
    </div>
    <div class="w-full flex justify-between pt-4">
      <div class="-mt-4 text-xs">Destination Chain</div>
      <div class="-mt-4 text-xs flex">
        Fee:
        <span class="ml-1" v-if="feeEstimate"
          >{{ ethers.utils.formatUnits(feeEstimate, "gwei") }} FLR</span
        >

        <IconSpinner
          v-else
          class="h-3 w-3 text-gray-300 animate-spin mt-[2px] ml-1"
        />
      </div>
    </div>
  </div>
</template>
