<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    class="fill-current"
  >
    <g>
      <path
        d="m475.415 337.02c-31.578 2.927-64.739.426-98.818-8.902-92.752-25.388-167.327-99.96-192.715-192.715-9.328-34.079-11.829-67.24-8.899-98.815 2.595-27.981-28.703-46.51-52.058-30.888-88.043 58.888-140.407 166.862-117.582 284.924 20.845 107.808 108.224 195.187 216.032 216.032 118.063 22.825 226.036-29.538 284.924-117.581 15.625-23.356-2.907-54.651-30.884-52.055z"
      />
    </g>
  </svg>
</template>
