export function useTruncateString(
  fullStr: string,
  strLen: number,
  separator: string
) {
  if (!fullStr) return;
  if (fullStr.length <= strLen) return fullStr;

  separator = separator || "...";

  const sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substring(0, frontChars + 2) +
    separator +
    fullStr.substring(fullStr.length - backChars)
  );
}
