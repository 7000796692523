export function useIsNumberKey(evt: any, input: string | number | undefined) {
  if (evt.type == "paste") {
    const value = evt.clipboardData.getData("Text");
    const data = value;
    if (isNaN(data)) {
      evt.preventDefault();
      return;
    }
    // this.input_amount = data;
    evt.preventDefault();
    return;
  }
  const allowed_keys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
  ];

  // Limit to 1 decimal in number string
  if (input && evt.key === "." && input.toString().includes(".")) {
    evt.preventDefault();
  }

  if (allowed_keys.includes(evt.key)) {
    return;
  }

  evt.preventDefault();
  return;
}
