<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    class="fill-current"
  >
    <path
      clip-rule="evenodd"
      d="m5 9v-2c0-2.76142 2.23858-5 5-5 2.7614 0 5 2.23858 5 5v2c1.1046 0 2 .89543 2 2v5c0 1.1046-.8954 2-2 2h-10c-1.10457 0-2-.8954-2-2v-5c0-1.10457.89543-2 2-2zm8-2v2h-6v-2c0-1.65685 1.34315-3 3-3 1.6569 0 3 1.34315 3 3z"
      fill-rule="evenodd"
    />
  </svg>
</template>
