import { defineStore } from "pinia";

type ErrorLevel = "INFO" | "CRITICAL";

interface ErrorState {
  error: boolean;
  level: ErrorLevel;
  message: string;
}

export const useErrorStore = defineStore({
  id: "error",
  state: (): ErrorState => {
    return {
      error: false,
      level: "INFO",
      message: "",
    };
  },
  getters: {},
  actions: {
    makeError(level: ErrorLevel, message: string) {
      this.error = true;
      this.level = level;
      this.message = message;
    },
    resolveError() {
      this.error = false;
      this.level = "INFO";
      this.message = "";
    },
  },
});
