<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.001 512.001"
    class="fill-current"
  >
    <g>
      <path
        d="m268.078 512c-141.385-.038-255.97-114.684-255.932-256.068s114.684-255.97 256.069-255.932c96.559.026 184.888 54.38 228.428 140.565 7.695 15.91 1.035 35.046-14.875 42.74-15.501 7.497-34.155 1.384-42.213-13.834-47.784-94.661-163.259-132.663-257.921-84.879s-132.664 163.259-84.88 257.921 163.259 132.664 257.921 84.88c36.48-18.414 66.133-47.987 84.646-84.417 8.018-15.753 27.287-22.023 43.04-14.005s22.023 27.287 14.005 43.04c-43.514 86.066-131.847 140.233-228.288 139.989z"
      />
    </g>
  </svg>
</template>
