<script setup lang="ts">
import AppEmblem from "@/components/icons/AppEmblem.vue";
import RoundedContainer from "@/components/RoundedContainer.vue";
import TheModal from "@/components/TheModal.vue";
import { ref } from "vue";
import { useNotificationStore } from "@/stores/notification";

defineExpose({ show, hide, waitAndFail, waitAndSucceed });

defineProps({
  action: {
    required: true,
  },
  message: {
    required: true,
  },
});

const notification = useNotificationStore();

const innerProgressModal = ref(null);

function show() {
  // @ts-ignore
  innerProgressModal.value.show();
}
function hide() {
  // @ts-ignores
  innerProgressModal.value.hide();
}

function waitAndFail(error: any, timeout: number = 1500) {
  console.error(error);
  setTimeout(() => {
    hide();
  }, timeout);

  notification.create({
    type: "error", // 'success' or 'error' or 'alert'
    title: "Transaction Failed",
    message: error.message,
    linkUrl: null,
    linkText: "",
    timeout: 5000,
  });
}
function waitAndSucceed(error: any, timeout: number = 1500) {
  setTimeout(() => {
    hide();
  }, timeout);

  notification.create({
    type: "success", // 'success' or 'error' or 'alert'
    title: "Transaction Succeeded",
    message: error.message,
    linkUrl: null,
    linkText: "",
    timeout: 5000,
  });
}
</script>

<template>
  <TheModal ref="innerProgressModal">
    <div class="relative flex justify-center py-6">
      <div
        class="loader border-t-orange-500 dark:border-t-white dark:border-l-white dark:border-r-white border-l-orange-500 border-r-orange-500 border-b-white dark:border-b-slate-800 ease-linear rounded-full border-8 border-t-8 h-32 w-32"
      ></div>
      <div class="absolute pt-9 animate-pulse">
        <AppEmblem class="w-14 h-14" />
      </div>
    </div>
    <div class="text-center">
      <div class="py-2 text-lg">{{ message }}</div>
      <RoundedContainer class="my-2">{{ action }}</RoundedContainer>
      <div class="py-2 text-sm text-gray-400">
        Follow prompts from your wallet
      </div>
    </div>
  </TheModal>
</template>

<style scoped>
.loader {
  -webkit-animation: spinner 2.5s linear infinite;
  animation: spinner 2.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
