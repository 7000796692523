<script setup lang="ts">
import moment from "moment";
import "moment-duration-format";
import { ref, computed } from "vue";
import type { Ref } from "vue";
// @ts-ignore
import { useIsNumberKey as isNumberKey } from "@/helpers/IsNumberKey";
import { useWalletStore } from "@/stores/wallet";
import { addDelegator } from "@/helpers/flare/addDelegator";
import { BN } from "ethereumjs-util";
import AppEmblem from "./icons/AppEmblem.vue";
import { UnixNow } from "@flarenetwork/flarejs/dist/utils";
import { waitAtomicTxStatus } from "@/helpers/flare/pvmAtomicTx";
import { statusMessages } from "@/helpers/Constants";
import { decimalToInteger } from "@/helpers/Utils";

import IconArrow from "@/components/icons/IconArrow.vue";
import ProgressModalV2 from "@/components/ProgressModalV2.vue";
import StakingValidatorsModal from "@/components/StakingValidatorsModal.vue";
import { PairedValidatorList } from "@/helpers/ValidatorsList";
import { useGtag } from "vue-gtag-next";

const { event } = useGtag();

const wallet = useWalletStore();

const validatorsListModal = ref();
const progressModal = ref();
const status = ref("");
// const nodeId = ref("NodeID-7Xhw2mDxuDS44j42TCB6U5579esbSt3Lg");
const nodeId = ref("NodeID-Bo98cjoi5LvF6jXAMHZZLQYutyeGutBQ1"); //v2
// const nodeId = ref("NodeID-HZwFckGTbucqTtE7qcv6z85mnScUfAeW6"); //v1
const nodeName = ref("FTSO AU 2");
const nodeEndTime = ref("1712962239"); // v2
// const nodeEndTime = ref("1710198000"); // v1

const inputAmount: Ref<string | number | undefined> = ref(undefined);
const inputDays = ref(14);
const inputHours = ref(0);

const confirmSwitch = ref(false);
async function addDelegation() {
  if (!inputAmount.value) throw new Error("Input amount undefined.");

  progressModal.value.show();

  // Debug parameters
  // const startTime = UnixNow().add(new BN(14 * 24 * 60 * 60));
  // const endTime = startTime.add(new BN(days.value * 24 * 60 * 60));

  // Add 60s as buffer to allow time to sign
  const startTime = UnixNow().add(new BN(60));
  const endTime = startTime
    .add(new BN(inputDays.value * 24 * 60 * 60))
    .add(new BN(inputHours.value * 60 * 60));

  inputDays.value * 24 * 60 * 60 + inputHours.value * 60 * 60;

  try {
    const txId = await addDelegator(
      wallet,
      nodeId.value,
      new BN(
        decimalToInteger(inputAmount.value.toString().replace(/,/g, ""), 9)
      ),
      startTime,
      endTime,
      1,
      status
    );

    await waitAtomicTxStatus(wallet, txId);
  } catch (error: any) {
    resetForm();
    progressModal.value.waitAndFail({ message: error.message });
    return (status.value = "EXPORT_ERROR");
  }

  if (
    nodeId.value.toLowerCase() ==
    "NodeID-HZwFckGTbucqTtE7qcv6z85mnScUfAeW6".toLowerCase()
  ) {
    event("stake_complete_au", {
      screen_name: "Stake",
      label: "stake complete",
    });
  } else if (
    nodeId.value.toLowerCase() ==
    "NodeId-Bo98cjoi5LvF6jXAMHZZLQYutyeGutBQ1".toLowerCase()
  ) {
    event("stake_complete_au2", {
      screen_name: "Stake",
      label: "stake complete",
    });
  } else {
    event(`stake_complete_${nodeName.value.replace(" ", "_").toLowerCase()}`, {
      screen_name: "Stake",
      label: "stake complete",
    });
  }

  resetForm();
  progressModal.value.waitAndSucceed({
    message: `Staked ${inputAmount.value} FLR to validator.`,
  });
  status.value = "STAKE_SUCCESS";
}

function verifyAmountInput() {
  if (!inputAmount.value) return;

  if (Number(inputAmount.value) < 50_000)
    return (inputAmount.value = undefined);

  if (Number(inputAmount.value) >= wallet.balance.pnativeUnlocked - 1) {
    if (wallet.balance.pnativeUnlocked < 50_000)
      return (inputAmount.value = undefined);

    inputAmount.value = wallet.balance.pnativeUnlocked - 1;
  }
}

function resetForm() {
  wallet.updateBalancesV2();
  inputDays.value = 14;
  inputHours.value = 0;
  inputAmount.value = undefined;
  confirmSwitch.value = false;
}

const endDate = computed(() => {
  const days = inputDays.value * 24 * 60 * 60;
  const hours = inputHours.value * 60 * 60;
  const now = new Date();
  const addSeconds = days + hours;

  return new Date(now.getTime() + addSeconds * 1000);
});

const maxStakeDuration = computed(() => {
  const now = moment(); // Current time
  const end = moment(Number(nodeEndTime.value) * 1000); // End time
  // Calculate the remaining time
  const duration = moment.duration(end.diff(now));
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  return { days, hours };
});

function setMaxStakeDuration() {
  const { days, hours } = maxStakeDuration.value;
  inputDays.value = days;
  inputHours.value = hours;
}

function selectValidator(validator: PairedValidatorList) {
  inputDays.value = 14;
  inputHours.value = 0;
  nodeId.value = validator.address;
  nodeName.value = validator.name;
  nodeEndTime.value = validator.endTime;
}

function verifyDateInput(type: string) {
  switch (type) {
    case "hours":
      if (inputHours.value < 0) {
        inputHours.value = 0;
      }
      if (inputHours.value > 24) {
        inputHours.value = 24;
      }
      if (!inputHours.value) inputHours.value = 0;
      break;
    case "days":
      console.log(inputDays.value);
      if (inputDays.value < 1) {
        inputDays.value = 14;
      }
      if (inputDays.value > 365) {
        inputDays.value = 365;
      }

      if (!inputDays.value) inputDays.value = 14;
      break;

    default:
      break;
  }
}
</script>

<template>
  <StakingValidatorsModal ref="validatorsListModal" @select="selectValidator" />
  <ProgressModalV2
    ref="progressModal"
    action="Delegating stake..."
    :message="statusMessages[status] || status"
  />
  <div>
    <div
      class="rounded-lg w-full border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 mb-2 py-2 px-4"
    >
      <div>
        <div class="text-sm font-bold text-gray-500 dark:text-gray-200">
          Validator
        </div>
        <div>
          <div class="flex justify-between items-center">
            <div
              class="flex font-semibold items-center gap-x-1"
              v-if="
                nodeId == 'NodeID-HZwFckGTbucqTtE7qcv6z85mnScUfAeW6' ||
                nodeId == 'NodeID-Bo98cjoi5LvF6jXAMHZZLQYutyeGutBQ1'
              "
            >
              <AppEmblem class="w-5 h-5 mb-[2px]" />
              {{ nodeName }}
            </div>
            <div class="font-semibold" v-else>{{ nodeName }}</div>
            <div
              @click="validatorsListModal.show()"
              class="rounded-full bg-gray-400 hover:bg-gray-500 text-xs text-white font-semibold px-2 py-1 mb-2 select-none cursor-pointer"
            >
              Change Validator
            </div>
          </div>
          <p class="text-sm text-gray-500 dark:text-gray-200">
            {{ nodeId }}
          </p>
        </div>
      </div>
    </div>

    <!-- Amount  -->
    <div
      class="rounded-lg w-full border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 mb-2 py-2 px-4"
    >
      <div class="text-sm font-bold text-gray-500 dark:text-gray-200">
        Amount
      </div>
      <div
        class="flex h-full w-full"
        :class="!confirmSwitch ? ' justify-between' : 'justify-end'"
      >
        <div
          v-if="!confirmSwitch"
          @click="
            Number(wallet.balance.pnativeUnlocked) >= 50_001
              ? (inputAmount = wallet.balance.pnativeUnlocked - 1)
              : undefined
          "
          class="justify-self-start self-center text-gray-400 hover:text-gray-500 cursor-pointer"
        >
          MAX
        </div>
        <div class="flex items-center text-lg dark:text-white relative">
          <div class="absolute right-0 text-xs text-gray-400 mb-12">
            Min. 50,000 FLR
          </div>
          <input
            v-if="!confirmSwitch"
            @blur="verifyAmountInput()"
            @keydown="isNumberKey($event, inputAmount)"
            @paste="isNumberKey($event, inputAmount)"
            v-model="inputAmount"
            :disabled="!wallet.account"
            tabindex="0"
            inputmode="decimal"
            autocomplete="off"
            autocorrect="off"
            type="text"
            pattern="^[0-9]*[.,]?[0-9]*$"
            placeholder="0.0"
            minlength="1"
            maxlength="79"
            spellcheck="false"
            class="h-full border-none display-none focus:ring-0 flex-auto w-full bg-transparent focus:outline-none right-0 text-right"
          />
          <input
            v-else
            :value="
              Number(inputAmount)?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 6,
              })
            "
            :disabled="true"
            class="h-full border-none display-none focus:ring-0 flex-auto w-full bg-transparent focus:outline-none right-0 text-right"
          />
          <span class="pt-[2px] pl-1 text-right">FLR</span>
        </div>
      </div>
    </div>

    <!-- Duration -->
    <div
      class="rounded-lg w-full border dark:border-slate-500 bg-gray-50 dark:bg-slate-500 mb-4 py-2 px-4"
      :class="
        inputDays > maxStakeDuration.days || inputHours > maxStakeDuration.hours
          ? 'border-red-400'
          : 'border-gray-100'
      "
    >
      <div class="text-sm font-bold text-gray-500 dark:text-gray-200">
        Stake Duration
      </div>

      <div
        class="flex"
        :class="!confirmSwitch ? 'justify-between' : 'justify-end'"
      >
        <div
          v-if="!confirmSwitch"
          @click="setMaxStakeDuration"
          class="justify-self-start self-center text-gray-400 hover:text-gray-500 cursor-pointer col-span-1"
        >
          MAX
        </div>
        <div v-if="!confirmSwitch" class="flex">
          <div class="flex items-baseline mr-6">
            <div class="self-center pr-2">
              <IconArrow
                @click="inputDays < 365 ? inputDays++ : undefined"
                class="w-[10px] h-[10px] -rotate-90 text-gray-400 mb-[2px] cursor-pointer"
              />
              <IconArrow
                @click="inputDays > 14 ? inputDays-- : undefined"
                class="w-[10px] h-[10px] rotate-90 text-gray-400 cursor-pointer"
              />
            </div>
            <input
              @blur="verifyDateInput('days')"
              inputmode="numeric"
              type="number"
              min="14"
              max="365"
              v-model="inputDays"
              class="pl-2 text-xl bg-transparent h-full outline-none display-none"
            />
            <div class="">Days</div>
          </div>
          <!-- Hours -->
          <div class="flex items-baseline">
            <div class="self-center pr-2">
              <IconArrow
                @click="inputHours < 24 ? inputHours++ : undefined"
                class="w-[10px] h-[10px] -rotate-90 text-gray-400 mb-[2px] cursor-pointer"
              />
              <IconArrow
                @click="inputHours > 0 ? inputHours-- : undefined"
                class="w-[10px] h-[10px] rotate-90 text-gray-400 cursor-pointer"
              />
            </div>
            <input
              @blur="verifyDateInput('hours')"
              inputmode="numeric"
              type="number"
              min="0"
              max="24"
              v-model="inputHours"
              class="pl-2 text-xl bg-transparent h-full outline-none display-none"
            />
            <div class="">Hours</div>
          </div>
        </div>
        <div v-else class="">
          <div class="text-sm text-gray-500 dark:text-gray-200">
            {{ endDate.toLocaleString() }}
          </div>
          <div class="flex">
            <div class="flex items-baseline mr-4">
              <span
                class="pr-1 text-xl bg-transparent h-full outline-none display-none"
                >{{ inputDays }}</span
              >
              <div class="">Days</div>
            </div>
            <!-- Hours -->
            <div class="flex items-baseline">
              <span
                class="pr-1 text-xl bg-transparent h-full outline-none display-none"
                >{{ inputHours }}</span
              >
              <div class="">Hours</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        inputDays > maxStakeDuration.days || inputHours > maxStakeDuration.hours
      "
      class="text-xs text-red-600 pb-2 -mt-2"
    >
      Stake duration is greater than validators end date.
    </div>
    <div v-if="wallet.balance.pnativeUnlocked < 50000">
      <button
        :disabled="true"
        class="w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Min. Stake 50k FLR
      </button>
    </div>

    <div v-else>
      <button
        v-if="!confirmSwitch"
        @click="confirmSwitch = true"
        :disabled="!inputAmount"
        class="w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Confirm
      </button>

      <div class="grid grid-cols-3 gap-x-4">
        <button
          v-if="confirmSwitch"
          @click="confirmSwitch = !confirmSwitch"
          class="w-full rounded-xl text-center font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Cancel
        </button>

        <button
          v-if="confirmSwitch && Number(inputAmount) >= 50_000"
          @click="addDelegation()"
          class="col-span-2 w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Stake
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
