<script setup>
import AppLogo from "../components/icons/AppLogo.vue";
import ConnectWallet from "../components/ConnectWallet.vue";
import RoundedContainer from "../components/RoundedContainer.vue";
import MainDropdownMenu from "../components/MainDropdownMenu.vue";
import IconSgb from "../components/icons/IconSgb.vue";
import IconFlr from "../components/icons/IconFlr.vue";
import NetworkIcon from "../components/icons/NetworkIcon.vue";
import { useWalletStore } from "@/stores/wallet";
import PopupNotification from "@/components/PopupNotification.vue";
import { useNotificationStore } from "@/stores/notification";
const wallet = useWalletStore();
</script>
<template>
  <PopupNotification />
  <div class="w-full">
    <!-- <div class="border border-black p-6 mt-24 text-sm">
      {{ wallet.account }}
      {{ wallet.network }}
    </div> -->
    <!-- Code block starts -->
    <nav role="navigation" class="w-full mx-auto hidden md:block">
      <div
        class="container max-w-7xl mx-auto justify-between h-20 flex items-center md:items-stretch p-8"
      >
        <!-- Desktop Nav -->
        <div class="h-full flex items-center w-full">
          <app-logo class="block h-8 pr-6" />
          <ul class="md:flex items-center h-full hidden pt-2">
            <li>
              <router-link to="/wrap"
                ><span
                  class="focus:outline-none border-b-2 border-transparent font-medium cursor-pointer h-full flex items-center hover:text-orange-600 text-sm dark:text-gray-200 dark:hover:text-orange-400 text-gray-800 tracking-normal px-2 py-1"
                  :class="
                    $route.name == 'wrap' ? 'underline underline-offset-4' : ''
                  "
                  >Wrap</span
                >
              </router-link>
            </li>
            <li>
              <router-link to="/delegate">
                <span
                  class="focus:outline-none border-b-2 border-transparent font-medium cursor-pointer h-full flex items-center hover:text-orange-600 text-sm dark:text-gray-200 dark:hover:text-orange-400 text-gray-800 tracking-normal mx-6 px-2 py-1"
                  :class="
                    $route.name == 'delegate'
                      ? 'underline underline-offset-4'
                      : ''
                  "
                  >Delegate</span
                >
              </router-link>
            </li>
            <li>
              <router-link to="/rewards">
                <span
                  class="focus:outline-none border-b-2 border-transparent font-medium cursor-pointer h-full flex items-center hover:text-orange-600 text-sm dark:text-gray-200 dark:hover:text-orange-400 text-gray-800 tracking-normal mr-6 px-2 py-1"
                  :class="
                    $route.name == 'rewards'
                      ? 'underline underline-offset-4'
                      : ''
                  "
                  >Rewards</span
                >
              </router-link>
            </li>
            <li>
              <router-link to="/stake">
                <span
                  class="focus:outline-none border-b-2 border-transparent font-medium cursor-pointer h-full flex items-center hover:text-orange-600 text-sm dark:text-gray-200 dark:hover:text-orange-400 text-gray-800 tracking-normal px-2 py-1"
                  :class="
                    $route.name == 'stake'
                      ? ' rounded-md underline underline-offset-4'
                      : ''
                  "
                  >Stake</span
                >
              </router-link>
            </li>
          </ul>
          <div class="ml-auto flex items-center gap-x-2">
            <RoundedContainer class="flex items-center gap-x-2">
              <NetworkIcon
                class="w-4 text-pink-600"
                :network="wallet.network ?? 'flare'"
              />
              <div class="capitalize hidden lg:inline-block">
                {{ wallet.network ?? "Flare" }}
              </div>
            </RoundedContainer>
            <ConnectWallet />
            <!-- <dark-mode-toggle class="" /> -->
            <MainDropdownMenu id="desktop" />
          </div>
        </div>
      </div>
    </nav>
    <!-- Mobile Navbar -->
    <nav class="md:hidden">
      <div class="w-full fixed top-0 z-40">
        <div class="flex md:hidden mx-auto container">
          <div class="py-4 flex justify-between mx-4 items-center w-full">
            <div
              aria-label="logo"
              role="img"
              tabindex="0"
              class="focus:outline-none"
            >
              <AppLogo class="block h-7 w-auto" />
            </div>

            <div class="flex md:hidden items-center justify-end gap-x-2">
              <RoundedContainer class="flex items-center gap-x-2">
                <NetworkIcon
                  class="w-4 text-pink-600"
                  :network="wallet.network"
                />
                <div class="capitalize hidden lg:inline-block">
                  {{ wallet.network }}
                </div>
              </RoundedContainer>
              <ConnectWallet />
              <!-- <dark-mode-toggle class="" /> -->
              <main-dropdown-menu id="mobile" />
            </div>
          </div>
        </div>
      </div>
      <!--Mobile responsive footer menu-->
      <div class="absolute w-full bottom-0">
        <rounded-container class="mx-8 mb-4">
          <ul class="flex justify-between items-center h-full rounded-xl">
            <li>
              <router-link to="/wrap"
                ><span
                  class="font-medium h-full flex items-center dark:text-gray-200 text-gray-800 tracking-normal px-2 py-1"
                  :class="
                    $route.name == 'wrap'
                      ? 'dark:bg-slate-500 bg-gray-100 rounded-xl'
                      : ''
                  "
                  >Wrap</span
                >
              </router-link>
            </li>
            <li>
              <router-link to="/delegate"
                ><span
                  class="font-medium h-full flex items-center dark:text-gray-200 text-gray-800 tracking-normal px-2 py-1"
                  :class="
                    $route.name == 'delegate'
                      ? 'dark:bg-slate-500 bg-gray-100 rounded-xl'
                      : ''
                  "
                  >Delegate</span
                >
              </router-link>
            </li>
            <li>
              <router-link to="/rewards"
                ><span
                  class="font-medium h-full flex items-center dark:text-gray-200 text-gray-800 tracking-normal px-2 py-1"
                  :class="
                    $route.name == 'rewards'
                      ? 'dark:bg-slate-500 bg-gray-100 rounded-xl'
                      : ''
                  "
                  >Rewards</span
                >
              </router-link>
            </li>
            <li>
              <router-link to="/stake"
                ><span
                  class="font-medium h-full flex items-center dark:text-gray-200 text-gray-800 tracking-normal px-2 py-1"
                  :class="
                    $route.name == 'stake'
                      ? 'dark:bg-slate-500 bg-gray-100 rounded-xl'
                      : ''
                  "
                  >Stake</span
                >
              </router-link>
            </li>
          </ul>
        </rounded-container>
      </div>
    </nav>
    <!-- Sidebar ends -->
    <!-- Code block ends -->

    <!-- Page Content -->
    <main class="mx-auto h-full max-w-7xl pt-24 md:pt-12 px-4 md:px-8">
      <slot></slot>
    </main>
  </div>
</template>

<style scoped></style>
