import { defineStore } from "pinia";

type Theme = "light" | "dark";

export const useThemeStore = defineStore({
  id: "theme",
  state: () => {
    return { mode: "light" };
  },
  getters: {},
  actions: {
    toggle() {
      if (this.mode == "dark") {
        this.setTheme("light");
      } else {
        this.setTheme("dark");
      }
    },
    setTheme(theme: Theme) {
      const element = document.querySelector("html");
      if (!element) return;
      if (theme == "light") {
        element.classList.remove("dark");
        element.classList.add("light");
        this.mode = "light";
        localStorage.theme = "light";
      } else {
        element.classList.remove("light");
        element.classList.add("dark");
        this.mode = "dark";
        localStorage.theme = "dark";
      }
    },
  },
});
