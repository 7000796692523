<script setup lang="ts">
import { ref } from "vue";

defineExpose({ show, hide });

const modal = ref(null);
const modalVisable = ref(false);

function show() {
  modalHandler(true);
}
function hide() {
  modalHandler(false);
}

function modalHandler(val: boolean) {
  if (val) {
    modalVisable.value = true;
    fadeIn(modal.value);
  } else {
    fadeOut(modal.value);
  }
}
function fadeOut(el: any) {
  el.style.opacity = 1;
  (function fade() {
    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = "none";
    } else {
      requestAnimationFrame(fade);
    }
  })();
}
function fadeIn(el: any, display = "flex") {
  el.style.opacity = 0;
  el.style.display = display || "flex";
  (function fade() {
    let val = parseFloat(el.style.opacity);
    if (!((val += 0.2) > 1)) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();
}
</script>

<template>
  <Teleport to="body">
    <div v-show="modalVisable" class="dark:bg-black bg-gray-300">
      <div
        ref="modal"
        class="py-12 bg-gray-700 bg-opacity-50 transition duration-150 ease-in-out z-40 absolute top-0 right-0 bottom-0 left-0"
      >
        <div
          role="alert"
          class="container m-auto w-11/12 md:w-2/3 max-w-lg pb-24"
        >
          <div
            class="relative rounded-xl shadow-lg p-6 dark:bg-gray-800 bg-white"
          >
            <slot></slot>
            <div
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 dark:text-gray-100 text-gray-600 transition duration-150 ease-in-out"
              @click="modalHandler(false)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Close"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
