import {
  UnsignedTx,
  UTXOSet,
  Tx,
} from "@flarenetwork/flarejs/dist/apis/platformvm";
import { UnixNow } from "@flarenetwork/flarejs/dist/utils";
import { BN } from "@flarenetwork/flarejs";
import { ref } from "vue";
import {
  unPrefix0x,
  expandSignature,
  deserializeExportCP_args,
  serializeUnsignedTx,
  cChainBlockchainID,
  signUnsignedTxHash,
  deserializeUnsignedTx,
  capFeeAt,
} from "../Utils";
import { WalletStore } from "../../types/global";
import { SignedTxJson, UnsignedTxJson, WalletState } from "../../types";
import { EcdsaSignature } from "@flarenetwork/flarejs/dist/common";

type ImportCPParams = [
  UTXOSet,
  string[],
  string,
  string[],
  string[],
  string[],
  Buffer | undefined,
  BN,
  BN,
  number
];

type ExportPCParams = [
  UTXOSet,
  BN,
  string,
  string[],
  string[],
  string[],
  Buffer | undefined,
  BN,
  BN,
  number
];

type TxStatus = "Committed" | "Processing" | "Dropped" | "Unknown";

interface TxStatusResponse {
  status: TxStatus;
}

export async function exportTokens(
  wallet: WalletStore,
  amount: BN,
  status = ref("")
) {
  try {
    status.value = "MAKING_UNSIGNED_PVM_TX";
    const unsignedTxJson = await getUnsignedExportTxPC(wallet, amount);
    const txHash = "0x" + unsignedTxJson.signatureRequests[0].message;

    status.value = "REQUEST_USER_SIGNATURE";
    const signature = await signUnsignedTxHash(
      wallet.accountKeys.addressCchain,
      txHash
    );

    status.value = "MAKING_SIGNED_PVM_TX";
    const signedTxJson = { ...unsignedTxJson, signature };
    status.value = "ISSUED_TX_WAITING_CONFIRM";
    const chainTxId = await issueSignedPvmTx(wallet, signedTxJson);

    return chainTxId;
  } catch (error) {
    status.value = `ERROR_USER`;
    console.error(error);
    throw error; // Throw the error so that the caller knows an error occurred.
  }
}

export async function waitAtomicTxStatus(
  wallet: WalletStore,
  txId: string
): Promise<TxStatus> {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const response: TxStatusResponse = (await wallet.pchain.getTxStatus(
        txId
      )) as TxStatusResponse;

      const { status } = response;

      switch (status) {
        case "Committed":
          clearInterval(interval);
          resolve(status);
          break;
        case "Dropped":
          clearInterval(interval);
          reject(status);
          break;
        default:
          break;
      }
    }, 2000);
    // Timeout if status never resolves
    setTimeout(() => {
      clearInterval(interval);
      reject("Unknown");
    }, 20000);
  });
}

export async function importTokens(
  wallet: WalletStore,
  threshold: number,
  status = ref("")
) {
  const unsignedTxJson = await getUnsignedImportTxCP(
    wallet,
    Number(threshold!)
  );
  const txHash = "0x" + unsignedTxJson.signatureRequests[0].message;

  status.value = "REQUEST_USER_SIGNATURE";
  const signature = await signUnsignedTxHash(
    wallet.accountKeys.addressCchain,
    txHash
  );

  status.value = "MAKING_SIGNED_TX";
  const signedTxJson = { ...unsignedTxJson, signature };

  // use issueSignedPvmTx here
  status.value = "ISSUING_TX";
  const chainTxId = await issueSignedPvmTx(wallet, signedTxJson);

  status.value = "ISSUED_TX_WAITING_CONFIRM";
  return chainTxId;
}

export async function issueSignedPvmTx(
  wallet: WalletState,
  signedTxJson: SignedTxJson
): Promise<string> {
  const signatures = Array(signedTxJson.signatureRequests.length).fill(
    unPrefix0x(signedTxJson.signature)
  );
  const ecdsaSignatures: EcdsaSignature[] = signatures.map(
    (signature: string) => expandSignature(signature)
  );
  const unsignedTx = deserializeUnsignedTx(
    UnsignedTx,
    signedTxJson.serialization
  );
  const tx: Tx = unsignedTx.signWithRawSignatures(
    ecdsaSignatures,
    wallet.cKeychain
  );
  return await wallet.pchain.issueTx(tx);
}

export async function getUnsignedImportTxCP(
  wallet: WalletState,
  threshold?: number
): Promise<UnsignedTxJson> {
  const params = await getImportCPParams(wallet, threshold);
  const unsignedTx: UnsignedTx = await wallet.pchain.buildImportTx(
    // TODO: Type issue resolvable
    // @ts-ignore
    ...params
  );
  return {
    transactionType: "importCP",
    serialization: serializeUnsignedTx(unsignedTx),
    signatureRequests: unsignedTx.prepareUnsignedHashes(wallet.cKeychain),
    unsignedTransactionBuffer: unsignedTx.toBuffer().toString("hex"),
    usedFee: wallet.pchain.getDefaultTxFee().toString(),
  };
}

export async function getImportCPParams(
  wallet: WalletState,
  threshold: number = 1
): Promise<ImportCPParams> {
  const locktime: BN = new BN(0);
  const asOf: BN = UnixNow();
  const platformVMUTXOResponse: any = await wallet.pchain.getUTXOs(
    ["P-" + wallet.accountKeys.addressPchain!],
    cChainBlockchainID
  );
  const utxoSet: UTXOSet = platformVMUTXOResponse.utxos;
  return [
    utxoSet,
    ["P-" + wallet.accountKeys.addressPchain!],
    cChainBlockchainID,
    ["P-" + wallet.accountKeys.addressPchain!],
    ["P-" + wallet.accountKeys.addressPchain!],
    ["P-" + wallet.accountKeys.addressPchain!],
    undefined,
    asOf,
    locktime,
    threshold,
  ];
}

export async function exportTxPC(
  wallet: WalletState,
  amount?: BN,
  threshold?: number
): Promise<{ txid: string; usedFee: string }> {
  const params = await getExportPCParams(wallet, amount, threshold);
  // @ts-ignore
  const unsignedTx: UnsignedTx = await wallet.pchain.buildExportTx(...params);
  const tx: Tx = unsignedTx.sign(wallet.pKeychain);
  const txid: string = await wallet.pchain.issueTx(tx);
  return { txid: txid, usedFee: wallet.pchain.getDefaultTxFee().toString() };
}

export async function getExportPCParams(
  wallet: WalletState,
  amount?: BN,
  threshold: number = 1
): Promise<ExportPCParams> {
  const locktime: BN = new BN(0);
  const asOf: BN = UnixNow();
  const platformVMUTXOResponse: any = await wallet.pchain.getUTXOs([
    "P-" + wallet.accountKeys.addressPchain!,
  ]);
  const utxoSet: UTXOSet = platformVMUTXOResponse.utxos;
  const fee = wallet.pchain.getDefaultTxFee();
  // if amount is not passed, export all funds minus the fee
  if (amount === undefined) {
    const getBalanceResponse: any = await wallet.pchain.getBalance(
      "P-" + wallet.accountKeys.addressPchain!
    );
    const unlocked = new BN(getBalanceResponse.unlocked);
    amount = unlocked.sub(fee);
  }
  return [
    utxoSet,
    amount,
    cChainBlockchainID,
    ["P-" + wallet.accountKeys.addressPchain],
    ["P-" + wallet.accountKeys.addressPchain],
    ["P-" + wallet.accountKeys.addressPchain],
    undefined,
    asOf,
    locktime,
    threshold,
  ];
}

export async function getUnsignedExportTxPC(
  wallet: WalletState,
  amount?: BN,
  threshold?: number
): Promise<UnsignedTxJson> {
  const params = await getExportPCParams(wallet, amount, threshold);
  // @ts-ignore
  const unsignedTx: UnsignedTx = await wallet.pchain.buildExportTx(...params);
  return {
    transactionType: "exportPC",
    serialization: serializeUnsignedTx(unsignedTx),
    signatureRequests: unsignedTx.prepareUnsignedHashes(wallet.cKeychain),
    unsignedTransactionBuffer: unsignedTx.toBuffer().toString("hex"),
    usedFee: wallet.pchain.getDefaultTxFee().toString(),
  };
}
