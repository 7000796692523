<script setup>
import ConnectWalletCover from "../components/ConnectWalletCover.vue";
import RoundedContainer from "../components/RoundedContainer.vue";
import ClaimFtsoRewards from "../components/ClaimFtsoRewards.vue";
import ClaimDropRewards from "../components/ClaimDropRewards.vue";
import { useWalletStore } from "@/stores/wallet";
import { ref } from "vue";

const wallet = useWalletStore();

const activeTab = ref(0);

const claimDropRewardsRef = ref(null);
</script>

<template>
  <main>
    <!-- View -->
    <div class="flex justify-center">
      <RoundedContainer class="w-full md:w-auto relative">
        <ConnectWalletCover />

        <!-- View -->
        <div class="flex justify-center">
          <div class="p-2 w-full md:w-80">
            <p
              class="font-medium text-gray-600 dark:text-gray-100 cursor-pointer select-none"
            >
              Rewards
            </p>

            <div
              class="w-full flex flex-row gap-x-2 bg-gray-50 dark:bg-slate-700 rounded-lg py-1 px-1"
            >
              <!-- FTSO Button -->
              <div
                @click="activeTab = 0"
                class="basis-1/2 rounded-lg p-1 font-light text-center hover:bg-gray-200 dark:hover:bg-slate-600 cursor-pointer"
                :class="activeTab == 0 ? 'bg-gray-200 dark:bg-slate-600' : ''"
              >
                FTSO
              </div>
              <!-- Flare Drop Button -->
              <div
                @click="activeTab = 1"
                class="basis-1/2 rounded-lg p-1 font-light text-center relative hover:bg-gray-200 hover:dark:bg-slate-600 cursor-pointer"
                :class="activeTab == 1 ? 'bg-gray-200 dark:bg-slate-600' : ''"
              >
                <div
                  v-if="claimDropRewardsRef?.nonEmptyClaimableRewards.length"
                  class="absolute rounded-full bg-orange-500 text-white text-[12px] font-bold px-2 right-0 mr-2"
                >
                  {{ claimDropRewardsRef?.nonEmptyClaimableRewards.length }}
                </div>
                FlareDrop
              </div>
            </div>

            <ClaimFtsoRewards v-show="activeTab == 0" />

            <ClaimDropRewards
              v-show="activeTab == 1"
              v-if="wallet.network == 'flare'"
              ref="claimDropRewardsRef"
            />
            <div v-else v-show="activeTab == 1" class="py-2">
              <div class="font-semibold">Connect to Flare</div>
              <div class="text-gray-600 dark:text-gray-300 text-sm">
                To access FlareDrop details, switch your wallets connected
                network to Flare.
              </div>
            </div>
          </div>
        </div>
      </RoundedContainer>
    </div>
  </main>
</template>
