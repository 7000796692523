import { ethers, BigNumber } from "ethers";
export function useFlare() {}

import flareFtso from "@/abi/flare/Ftso.json";
import flareFtsoRegistry from "@/abi/flare/FtsoRegistry.json";
import flareFtsoManager from "@/abi/flare/FtsoManager.json";
import flareFtsoRewardManager from "@/abi/flare/FtsoRewardManager.json";
import flareWNat from "@/abi/flare/WNat.json";
import flareSupply from "@/abi/flare/Supply.json";
import flarePriceSubmitter from "@/abi/flare/PriceSubmitter.json";
import flareDistributionToDelegators from "@/abi/flare/DistributionToDelegators.json";

import flareAddressBinder from "@/abi/flare/AddressBinder.json";
import flareValidatorRewardManager from "@/abi/flare/ValidatorRewardManager.json";
import flareFlareContractRegistry from "@/abi/flare/FlareContractRegistry.json";
import flarePChainStakeMirror from "@/abi/flare/PChainStakeMirror.json";

import songbirdFtso from "@/abi/songbird/Ftso.json";
import songbirdFtsoRegistry from "@/abi/songbird/FtsoRegistry.json";
import songbirdFtsoManager from "@/abi/songbird/FtsoManager.json";
import songbirdFtsoRewardManager from "@/abi/songbird/FtsoRewardManager.json";
import songbirdWNat from "@/abi/songbird/WNat.json";
import songbirdSupply from "@/abi/songbird/Supply.json";
import songbirdPriceSubmitter from "@/abi/songbird/PriceSubmitter.json";
import { useWalletStore as _wallet } from "@/stores/wallet";
import { providersList } from "@/helpers/ProvidersList";

// function getProvider() {
//   const _wallet = useWalletStore();
//   return _wallet.provider;
// }

export const contract = {
  flare: {
    address: {
      Ftso: "0x8ABFc110d4174C8B9c582A6E4B5f2Da335aF4bA5",
      FtsoRegistry: "0x13dc2b5053857ae17a4f95aff55530b267f3e040",
      FtsoManager: "0x2E99a4543F9ea708Cf8CCaC447515e61706D9DE9",
      FtsoRewardManager: "0x85627d71921AE25769f5370E482AdA5E1e418d37",
      WNat: "0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d",
      Supply: "0x8577D2D50827bCE2EC1dc9Cf9F1199826781D5AF",
      PriceSubmitter: "0x1000000000000000000000000000000000000003",
      DistributionToDelegators: "0x9c7A4C83842B29bB4A082b0E689CB9474BD938d0",
      AddressBinder: "0x5d931b4b7e408278F417ec887995696DA5Bd6ca2",
      ValidatorRewardManager: "0xc0cf3aaf93bd978c5bc662564aa73e331f2ec0b5",
      FlareContractRegistry: "0xaD67FE66660Fb8dFE9d6b1b4240d8650e30F6019",
      PChainStakeMirror: "0x7b61F9F27153a4F2F57Dc30bF08A8eb0cCB96C22",
    },
    abi: {
      Ftso: flareFtso,
      FtsoRegistry: flareFtsoRegistry,
      FtsoManager: flareFtsoManager,
      FtsoRewardManager: flareFtsoRewardManager,
      WNat: flareWNat,
      Supply: flareSupply,
      PriceSubmitter: flarePriceSubmitter,
      DistributionToDelegators: flareDistributionToDelegators,
      AddressBinder: flareAddressBinder,
      ValidatorRewardManager: flareValidatorRewardManager,
      FlareContractRegistry: flareFlareContractRegistry,
      PChainStakeMirror: flarePChainStakeMirror,
    },
  },
  songbird: {
    address: {
      Ftso: "0x2Be2A30c312F02B4a43327E36acc9a2e8FA94F81",
      FtsoRegistry: "0xEf4c203470553C654B3870f37ce4DE1fA862Dee0",
      FtsoManager: "0x7A6B15E17b7691ba687ad182bD12cb4a91D533ae",
      FtsoRewardManager: "0x13F7866568dC476cC3522d17C23C35FEDc1431C5",
      WNat: "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED",
      Supply: "0x5059bA6272Fa598efAaCC9b6FCeFef7366980aD7",
      PriceSubmitter: "0x1000000000000000000000000000000000000003",
    },
    abi: {
      Ftso: songbirdFtso,
      FtsoRegistry: songbirdFtsoRegistry,
      FtsoManager: songbirdFtsoManager,
      FtsoRewardManager: songbirdFtsoRewardManager,
      WNat: songbirdWNat,
      Supply: songbirdSupply,
      PriceSubmitter: songbirdPriceSubmitter,
    },
  },
};

export function getFtsoProviderMeta(address) {
  let list = providersList.providers;
  list = list.filter((i) => i.chainId == _wallet().ethereum.chainId);
  for (let i = 0; i < list.length; i++) {
    let provider = list[i];
    if (provider.address.toLowerCase() == address.toLowerCase()) {
      return provider;
    }
  }
  return {
    chainId: _wallet().ethereum.chainId,
    name: "Unknown Provider",
    description: "This provider hasn't been publically registered.",
    url: "",
    address: address,
    logoURI: `https://avatars.dicebear.com/api/pixel-art-neutral/${address}.png`,
  };
}

function getContract(name, network, provider) {
  if (!["flare", "songbird"].includes(network))
    throw new Error(`Must specify correct network, ${network} is not valid.`);
  return new ethers.Contract(
    contract[network].address[name],
    contract[network].abi[name],
    provider
  );
}

export function bindAddressPchain(wallet) {
  const { publicKey, addressPchainEncoded, addressCchain } = wallet.accountKeys;
  const contract = getContract("AddressBinder", wallet.network, wallet.signer);

  return contract.registerAddresses(
    publicKey,
    addressPchainEncoded,
    addressCchain
  );
}

// Gets P Chain address (encoded) from AddressBinder
export function getPChainAddressBound(wallet) {
  const { addressCchain } = wallet.accountKeys;
  const contract = getContract("AddressBinder", wallet.network, wallet.signer);
  return contract.cAddressToPAddress(addressCchain);
}

export async function getNativeBalance(wallet) {
  const value = await wallet.signer.getBalance();
  return ethers.utils.formatEther(BigNumber.from(value));
}

export async function getWrappedNativeBalance(wallet) {
  const contract = getContract("WNat", wallet.network, wallet.signer);
  const value = await contract.balanceOf(wallet.account);
  return ethers.utils.formatEther(BigNumber.from(value));
}

export function wrapNativeTokens(wallet, value) {
  const contract = getContract("WNat", wallet.network, wallet.signer);
  const overrides = {
    value: ethers.utils.parseEther(value.toString()),
  };
  return contract.deposit(overrides);
}
export function unwrapNativeTokens(wallet, value) {
  const contract = getContract("WNat", wallet.network, wallet.signer);
  const overrides = {
    value: ethers.utils.parseEther(value.toString()),
  };
  return contract.withdraw(overrides.value);
}

export function setDelegation(wallet, params) {
  const contract = getContract("WNat", wallet.network, wallet.signer);
  return contract.delegate(params.address, params.bips);
}

export function getCurrentRewardEpoch() {
  const contract = getContract(
    "FtsoManager",
    _wallet().network,
    _wallet().signer
  );
  return contract.getCurrentRewardEpoch();
}

export async function getRewardEpochVotePowerBlock() {
  const contract = getContract(
    "FtsoManager",
    _wallet().network,
    _wallet().provider
  );
  return contract.getRewardEpochVotePowerBlock(await getCurrentRewardEpoch());
}

export async function getRewardEpochTimestamps() {
  const contract = getContract(
    "FtsoManager",
    _wallet().network,
    _wallet().provider
  );

  let result = await Promise.all([
    contract.rewardEpochDurationSeconds(),
    contract.getCurrentRewardEpoch(),
    contract.rewardEpochsStartTs(),
  ]);

  let rewardEpochDurationSeconds = parseInt(result[0]);
  let currentRewardEpoch = parseInt(result[1]);
  let rewardEpochStartTs = parseInt(result[2]);
  return {
    start: rewardEpochDurationSeconds * currentRewardEpoch + rewardEpochStartTs,
    end:
      rewardEpochDurationSeconds * currentRewardEpoch +
      rewardEpochStartTs +
      rewardEpochDurationSeconds,
  };
}

export async function getAccountLockedVotePower(wallet) {
  const contract = getContract("WNat", wallet.network, wallet.signer);
  const value = await contract.balanceOfAt(
    wallet.account,
    Number(await getRewardEpochVotePowerBlock())
  );
  return ethers.utils.formatEther(BigNumber.from(value));
}

export async function getAccountDelegations(wallet) {
  const contract = getContract("WNat", wallet.network, wallet.signer);
  const value = await contract.delegatesOf(wallet.account);
  return {
    delegateAddresses: value[0],
    bips: value[1].map((bip) => parseInt(bip)),
    count: parseInt(value[2]),
    delegationMode: parseInt(value[3]),
  };
}
export async function getStateOfRewards(wallet, rewardEpoch) {
  const contract = getContract(
    "FtsoRewardManager",
    wallet.network,
    wallet.signer
  );
  const value = await contract.getStateOfRewards(wallet.account, rewardEpoch);
  return {
    dataProviders: value[0],
    rewardAmounts: value[1].map((bn) =>
      parseFloat(ethers.utils.formatEther(BigNumber.from(bn)))
    ),
    claimed: value[2],
    claimable: value[3],
  };
}
export async function getStateOfValidatorRewards(wallet) {
  const contract = getContract(
    "ValidatorRewardManager",
    wallet.network,
    wallet.signer
  );
  const value = await contract.getStateOfRewards(wallet.account);
  return {
    totalReward: value[0],
    claimedReward: value[1],
  };
}

export async function claimValidatorReward(amount, wrap) {
  const contract = getContract(
    "ValidatorRewardManager",
    _wallet().network,
    _wallet().signer
  );
  return await contract.claim(
    _wallet().account,
    _wallet().account,
    amount,
    wrap
  );
}

export function bnToFloat(bn) {
  return parseFloat(ethers.utils.formatEther(BigNumber.from(bn)));
}

export async function getEpochsWithUnclaimedRewards(wallet) {
  const contract = getContract(
    "FtsoRewardManager",
    wallet.network,
    wallet.signer
  );
  return (await contract.getEpochsWithUnclaimedRewards(wallet.account)).map(
    (item) => Number(item)
  );
}
export async function claimFtsoReward(epoch, wrap) {
  const contract = getContract(
    "FtsoRewardManager",
    _wallet().network,
    _wallet().signer
  );
  return await contract.claim(
    _wallet().account,
    _wallet().account,
    epoch,
    wrap
  );
}
export async function getFtsoNextClaimableRewardEpoch() {
  const contract = getContract(
    "FtsoRewardManager",
    _wallet().network,
    _wallet().provider
  );
  return await contract.nextClaimableRewardEpoch(_wallet().account);
}
export async function claimFtsoRewardLegacy(epochs) {
  const contract = getContract(
    "FtsoRewardManager",
    _wallet().network,
    _wallet().signer
  );
  return await contract.claimReward(_wallet().account, epochs);
}
export async function getBalance(address) {
  let balance = await _wallet().provider.getBalance(address);
  return parseFloat(ethers.utils.formatEther(BigNumber.from(balance)));
}
export async function getFtsoPrice(symbol) {
  const contract = getContract(
    "FtsoRegistry",
    _wallet().network,
    _wallet().provider
  );
  try {
    return parseFloat(
      parseInt((await contract.getCurrentPrice(symbol))._price) / 10 ** 5
    );
  } catch (error) {
    console.error(`Requested token '${symbol}' is not supported by FTSO yet.`);
    return 0;
  }
}

// Flare Exclusive
export async function getFlareDropNextDropDate() {
  const contract = getContract(
    "DistributionToDelegators",
    "flare",
    _wallet().provider
  );
  try {
    const [currentMonth, entitlementStartTs] = await Promise.all([
      contract.getCurrentMonth(),
      contract.entitlementStartTs(),
    ]);
    // Months (including 0 index), * banking month (30), * day * to minutes * to seconds
    let monthsToSeconds = (Number(currentMonth) + 1) * 30 * 24 * 60 * 60;
    let nextClaimableDaySeconds = Number(entitlementStartTs) + monthsToSeconds;
    return nextClaimableDaySeconds;
  } catch (error) {
    throw new Error(`Failed to get Flare Drop next date: ${error}`);
  }
}

// Flare Exclusive
export async function getFlareDropClaimableMonths() {
  if (_wallet().network !== "flare")
    throw new Error(
      `FlareDrop only climabale on Flare. Current network: "${
        _wallet().network
      }"`
    );
  const contract = getContract(
    "DistributionToDelegators",
    "flare",
    _wallet().provider
  );
  try {
    return await contract.getClaimableMonths();
  } catch (error) {
    throw new Error(`Failed to get Flare Drop claimabale months: ${error}`);
  }
}

// Flare Exclusive
export async function getFlareDropNextClaimableMonth() {
  if (_wallet().network !== "flare")
    throw new Error(
      `FlareDrop only climabale on Flare. Current network: "${
        _wallet().network
      }"`
    );
  const contract = getContract(
    "DistributionToDelegators",
    "flare",
    _wallet().provider
  );
  try {
    return await contract.nextClaimableMonth(
      _wallet().account // claimer
    );
  } catch (error) {
    throw new Error(`Failed to get Flare Drop claimabale months: ${error}`);
  }
}
// Flare Exclusive
export async function getFlareDropClaimableAmount(month) {
  if (_wallet().network !== "flare")
    throw new Error(
      `FlareDrop only climabale on Flare. Current network: "${
        _wallet().network
      }"`
    );
  const contract = getContract(
    "DistributionToDelegators",
    "flare",
    _wallet().provider
  );
  try {
    return contract.getClaimableAmountOf(
      _wallet().account, // claimer
      month
    );
  } catch (error) {
    throw new Error(`Failed to get Flare Drop claimabale amounts: ${error}`);
  }
}
// Flare Exclusive
export async function claimFlareDrop(month, wrap) {
  if (_wallet().network !== "flare")
    throw new Error(
      `FlareDrop only climabale on Flare. Current network: "${
        _wallet().network
      }"`
    );
  const contract = getContract(
    "DistributionToDelegators",
    "flare",
    _wallet().signer
  );
  try {
    return contract.claim(
      _wallet().account, // claimer
      _wallet().account, // recipient
      month, // month of drop to claim
      wrap // wrap tokens bool
    );
  } catch (error) {
    throw new Error("Failed to claim Flare Drop.", error);
  }
}

//   async getSymbolPrice(symbol) {
//     let contract = this.getContract("FTSO_REGISTRY", this.provider);
//     try {
//       let response = await contract.getCurrentPrice(symbol);
//       return { price: response[0] / 100000, timestamp: response[1] };
//     } catch (error) {
//       throw error;
//     }
//   }
