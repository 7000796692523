import { UnsignedTx, UTXOSet } from "@flarenetwork/flarejs/dist/apis/evm";
import { costImportTx, costExportTx } from "@flarenetwork/flarejs/dist/utils";
import { BN } from "@flarenetwork/flarejs";
import { ref } from "vue";
import {
  unPrefix0x,
  expandSignature,
  deserializeExportCP_args,
  serializeExportCP_args,
  pChainBlockchainID,
  avaxAssetID,
  signUnsignedTxHash,
  serializeImportPC_args,
  deserializeImportPC_args,
  capFeeAt,
} from "../Utils";
import { WalletStore } from "../../types/global";
import {
  SignedTxJson,
  UnsignedTxJson,
  VMChains,
  WalletState,
} from "../../types";

type ExportCPParams = [
  BN,
  string,
  string,
  string,
  string,
  string[],
  number,
  BN,
  number,
  BN
];

type ImportPCParams = [UTXOSet, string, string[], string, string[], BN];

type AtomicTxStatus = "Accepted" | "Processing" | "Dropped" | "Unknown";

export async function waitAtomicTxStatus(
  wallet: WalletStore,
  txId: string
): Promise<AtomicTxStatus> {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const status: AtomicTxStatus = (await wallet.cchain.getAtomicTxStatus(
        txId
      )) as AtomicTxStatus;

      switch (status) {
        case "Accepted":
          clearInterval(interval);
          resolve(status);
          break;
        case "Dropped":
          clearInterval(interval);
          reject(status);
          break;
        default:
          break;
      }
    }, 2000);
    // Timeout if status never resolves
    setTimeout(() => {
      clearInterval(interval);
      reject("Unknown");
    }, 20000);
  });
}

export async function calculateFeeEstimate(
  wallet: WalletStore,
  amount: BN,
  type: "import" | "export"
): Promise<number> {
  const locktime = new BN(0);
  const importFee = wallet.pchain.getDefaultTxFee();
  const baseFeeResponse = await wallet.cchain.getBaseFee();
  const baseFee = new BN(parseInt(baseFeeResponse, 16) / 1e9).add(new BN(30));

  const params: ExportCPParams = [
    amount.add(importFee),
    avaxAssetID,
    pChainBlockchainID,
    wallet.accountKeys.addressCchain,
    "C-" + wallet.accountKeys.addressPchain,
    ["P-" + wallet.accountKeys.addressPchain],
    1,
    locktime,
    1,
    baseFee,
  ];

  const unsignedTx = await wallet.cchain.buildExportTx(...params);
  let txCost = undefined;
  if (type == "export") txCost = costExportTx(unsignedTx);
  if (type == "import") txCost = costImportTx(unsignedTx);

  if (!txCost) throw new Error("Import/Export cost not resolved.");

  return Number(baseFee.mul(new BN(txCost)));
}

export async function exportTokens(
  wallet: WalletStore,
  amount: BN,
  fee?: BN,
  status = ref("")
) {
  try {
    status.value = "MAKING_UNSIGNED_TX";
    const unsignedTxJson = await getUnsignedExportTxCP(wallet, amount);
    capFeeAt(unsignedTxJson.usedFee, fee?.toString());
    const txHash = "0x" + unsignedTxJson.signatureRequests[0].message;

    status.value = "REQUEST_USER_SIGNATURE";
    const signature = await signUnsignedTxHash(
      wallet.accountKeys.addressCchain,
      txHash
    );

    status.value = "MAKING_SIGNED_TX";
    const signedTxJson = { ...unsignedTxJson, signature };
    status.value = "ISSUED_TX_WAITING_CONFIRM";
    const chainTxId = await issueSignedEvmTxCPExport(wallet, signedTxJson);

    return chainTxId;
  } catch (error) {
    status.value = `ERROR_USER`;
    console.error(error);
    throw error; // Throw the error so that the caller knows an error occurred.
  }
}

export async function issueSignedEvmTxCPExport(
  wallet: WalletState,
  signedTxJson: SignedTxJson
): Promise<string> {
  return issueSignedEvmTx(wallet, signedTxJson, async (serialization: string) =>
    wallet.cchain.buildExportTx(...deserializeExportCP_args(serialization))
  );
}

export async function getUnsignedExportTxCP(
  wallet: WalletStore,
  amount: BN,
  fee?: BN,
  nonce?: number,
  threshold = 1
): Promise<UnsignedTxJson> {
  const params = await getExportCPParams(wallet, amount, fee, nonce, threshold);
  const unsignedTx = await wallet.cchain.buildExportTx(...params);
  return {
    transactionType: "exportCP",
    serialization: serializeExportCP_args(params),
    signatureRequests: unsignedTx.prepareUnsignedHashes(wallet.cKeychain),
    unsignedTransactionBuffer: unsignedTx.toBuffer().toString("hex"),
    usedFee: params[9].toString(),
  };
}

export async function getExportCPParams(
  wallet: WalletStore,
  amount: BN,
  fee?: BN,
  nonce?: number,
  threshold = 1
): Promise<ExportCPParams> {
  const txcount = await wallet.provider.getTransactionCount(
    wallet.accountKeys.addressCchain
  );
  const locktime = new BN(0);
  const importFee = wallet.pchain.getDefaultTxFee();
  const baseFeeResponse = await wallet.cchain.getBaseFee();
  const baseFee = new BN(parseInt(baseFeeResponse, 16) / 1e9).add(new BN(30));

  const params: ExportCPParams = [
    amount.add(importFee),
    avaxAssetID,
    pChainBlockchainID,
    wallet.accountKeys.addressCchain,
    "C-" + wallet.accountKeys.addressPchain,
    ["P-" + wallet.accountKeys.addressPchain],
    nonce ?? txcount,
    locktime,
    threshold,
    fee ?? baseFee,
  ];

  const unsignedTx = await wallet.cchain.buildExportTx(...params);
  const exportCost = costExportTx(unsignedTx);
  // if fee not passed -> use the default
  if (!fee) {
    params[9] = baseFee.mul(new BN(exportCost));
  }
  // else use the custom fees passed by the user
  else {
    params[9] = fee;
  }

  return params;
}

export async function issueSignedEvmTx(
  wallet: WalletState,
  signedTxJson: SignedTxJson,
  txBuilder: (serialization: string) => Promise<UnsignedTx>
): Promise<string> {
  const signatures = Array(signedTxJson.signatureRequests.length).fill(
    unPrefix0x(signedTxJson.signature)
  );
  const ecdsaSignatures = signatures.map((signature) =>
    expandSignature(signature)
  );

  const unsignedTx = await txBuilder(signedTxJson.serialization);

  const tx = unsignedTx.signWithRawSignatures(
    ecdsaSignatures,
    wallet.cKeychain
  );
  return await wallet.cchain.issueTx(tx);
}

export async function importTokens(
  wallet: WalletStore,
  fee?: BN,
  status = ref("")
) {
  const unsignedTxJson = await getUnsignedImportTxPC(wallet, fee);
  capFeeAt(unsignedTxJson.usedFee, fee?.toString());
  const txHash = "0x" + unsignedTxJson.signatureRequests[0].message;

  status.value = "REQUEST_USER_SIGNATURE";
  const signature = await signUnsignedTxHash(
    wallet.accountKeys.addressCchain,
    txHash
  );

  status.value = "MAKING_SIGNED_TX";
  const signedTxJson = { ...unsignedTxJson, signature };

  // use issueSignedPvmTx here
  status.value = "ISSUING_TX";
  const chainTxId = await issueSignedEvmTxPCImport(wallet, signedTxJson);

  status.value = "ISSUED_TX_WAITING_CONFIRM";
  return chainTxId;
}

export async function issueSignedEvmTxPCImport(
  wallet: WalletState,
  signedTxJson: SignedTxJson
): Promise<string> {
  return issueSignedEvmTx(wallet, signedTxJson, async (serialization: string) =>
    wallet.cchain.buildImportTx(...deserializeImportPC_args(serialization))
  );
}

export async function getUnsignedImportTxPC(
  wallet: WalletState,
  fee?: BN
): Promise<UnsignedTxJson> {
  const params = await getImportPCParams(wallet, fee);
  const unsignedTx: UnsignedTx = await wallet.cchain.buildImportTx(...params);
  return {
    transactionType: "importPC",
    serialization: serializeImportPC_args(params),
    signatureRequests: unsignedTx.prepareUnsignedHashes(wallet.cKeychain),
    unsignedTransactionBuffer: unsignedTx.toBuffer().toString("hex"),
    usedFee: params[5].toString(),
  };
}

export async function getImportPCParams(
  wallet: WalletState,
  fee?: BN
): Promise<ImportPCParams> {
  const baseFeeResponse: string = await wallet.cchain.getBaseFee();
  const baseFee = new BN(parseInt(baseFeeResponse, 16) / 1e9).add(new BN(30));
  const evmUTXOResponse: any = await wallet.cchain.getUTXOs(
    ["C-" + wallet.accountKeys.addressPchain!],
    pChainBlockchainID
  );
  const utxoSet: UTXOSet = evmUTXOResponse.utxos;
  const params: ImportPCParams = [
    utxoSet,
    wallet.accountKeys.addressCchain!, // 0x0...
    ["C-" + wallet.accountKeys.addressPchain!], // C-flare...
    pChainBlockchainID,
    ["C-" + wallet.accountKeys.addressPchain!], // C-flare...
    baseFee,
  ];
  const unsignedTx: UnsignedTx = await wallet.cchain.buildImportTx(...params);
  const importCost: number = costImportTx(unsignedTx);
  if (!fee) {
    params[5] = baseFee.mul(new BN(importCost));
  } else {
    params[5] = fee;
  }

  return params;
}
