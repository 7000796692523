import { defineStore } from "pinia";

interface NotificationInterface {
  type: "success" | "error" | "alert"; // 'success' or 'error' or 'alert'
  title: string;
  message: string;
  linkUrl: string | null;
  linkText: string;
  timeout: number;
}

interface NotificationQueue {
  queue: Array<NotificationInterface>;
}

export const useNotificationStore = defineStore({
  id: "notification",
  state: (): NotificationQueue => {
    return { queue: [] };
  },
  getters: {},
  actions: {
    create(params: NotificationInterface) {
      const notification: NotificationInterface = {
        type: params.type ?? "success", // 'success' or 'error' or 'alert'
        title: params.title ?? "Default Title",
        message: params.message ?? "Default Message",
        linkUrl: params.linkUrl ?? null,
        linkText: params.linkText ?? "Default Link Text",
        timeout: params.timeout ?? 5000,
      };
      this.queue.push(notification);
    },
    processQueue() {
      this.queue.shift();
    },
  },
});
