<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
    viewBox="0 0 66 66"
  >
    <path
      d="m63.18 51.45-25.1-42.85c-2.27-3.88-7.88-3.88-10.16 0l-25.1 42.85c-2.3 3.92.53 8.86 5.08 8.86h50.21c4.54 0 7.37-4.93 5.07-8.86zm-30.18-32.52c2.19 0 3.93 1.85 3.81 4.04l-1.11 18.88h-5.4l-1.11-18.88c-.12-2.19 1.62-4.04 3.81-4.04zm0 35.05c-2.23 0-4.04-1.81-4.04-4.04s1.81-4.04 4.04-4.04 4.04 1.81 4.04 4.04-1.81 4.04-4.04 4.04z"
    />
  </svg>
</template>
