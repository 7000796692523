<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 316.69 511.99">
    <g class="hidden dark:block">
      <path
        d="M315.71,333.1A226.54,226.54,0,0,0,296,261.53a275.08,275.08,0,0,0-37.5-59.82c-4.29-5.26-8.64-10.3-10.89-12.62l-5.18-5.66c-1.27-1.45-2.33-2.62-3.38-3.65l-1.55-1.57c-1.7-1.7-3.44-3.47-4.89-5.24l-.36-.42c-.71-.77-1.46-1.54-2.31-2.4a45.59,45.59,0,0,1-3.46-3.76l-.44-.52c-7.89-8.68-14.53-16.56-20.29-24.1a160.35,160.35,0,0,1-15.46-23.86A109.57,109.57,0,0,1,180.09,89a176.64,176.64,0,0,1-3.58-32.43,272.07,272.07,0,0,1,1-29.53c.49-5.7,1-10.75,1.58-15.21A10.59,10.59,0,0,0,169.82.07a10.72,10.72,0,0,0-3.91.27,119.89,119.89,0,0,0-15.35,5.18A139.78,139.78,0,0,0,119.1,23.67a148.75,148.75,0,0,0-33.63,36A156.86,156.86,0,0,0,61.87,118c-4.45,23.08-4,49.13,1.38,73.3,5.06,23.4,14.86,47.29,28.39,69.17a250.3,250.3,0,0,0,20.78,28.91c2,2.51,7.59,9,7.65,9.08l2.22,2.55c1.55,1.76,2.66,3.09,3.8,4.44s1.91,2.28,2.87,3.3l.16.17c10.59,12.66,18,21.95,24.2,30.17,6.81,9.05,12.79,17.82,18.29,26.81a152,152,0,0,1,13.16,26.37A123.52,123.52,0,0,1,191.69,423a183.45,183.45,0,0,1,0,32.81c-1,11.3-2.74,21.61-4.13,29.16-1,5.17-2.05,9.93-3,14.05A10.6,10.6,0,0,0,197,511.78a110.07,110.07,0,0,0,14.49-3.85,141.29,141.29,0,0,0,32.39-15.51,149.12,149.12,0,0,0,36.27-32.56A163.82,163.82,0,0,0,309,405,185.64,185.64,0,0,0,315.71,333.1Z"
        fill="#f7f7f7"
      />
      <path
        d="M108.25,397.9a125.8,125.8,0,0,0-17.11-27.31c-1.75-2.14-3.86-4.6-4.89-5.67l-2.44-2.66c-.63-.72-1.09-1.22-1.58-1.71l-.67-.67c-.77-.77-1.54-1.56-2.2-2.34-.11-.14-.24-.28-.36-.42l-.92-.95c-.53-.55-1.06-1.08-1.51-1.64a4.82,4.82,0,0,0-.36-.43c-3.49-3.82-6.49-7.38-9.13-10.83a75.61,75.61,0,0,1-7.1-11,49.87,49.87,0,0,1-4.65-13.2,79.91,79.91,0,0,1-1.62-14.79q-.14-5.85.32-11.7a10.58,10.58,0,0,0-15.1-10.31,67.27,67.27,0,0,0-11.44,7,68,68,0,0,0-15.33,16.41A71.42,71.42,0,0,0,1.38,332.34,84.44,84.44,0,0,0,2,365.82a101.93,101.93,0,0,0,13,31.63,114.16,114.16,0,0,0,9.39,13.08c.89,1.1,2.8,3.31,3.65,4.3h0L29,415.9c.75.84,1.27,1.46,1.78,2.09s.83,1,1.18,1.36l.17.18c3.26,3.9,7.47,9,11.06,13.79,3.12,4.14,5.85,8.14,8.34,12.23a68.62,68.62,0,0,1,6,12,56.93,56.93,0,0,1,3.17,14.1,84.15,84.15,0,0,1,0,14.89c-.28,3.34-.75,6.86-1.42,10.77a10.59,10.59,0,0,0,14.4,11.59,65.57,65.57,0,0,0,10.77-5.61A68.19,68.19,0,0,0,101,488.44a74.68,74.68,0,0,0,13.17-25,85.9,85.9,0,0,0,3.09-32.83A103.81,103.81,0,0,0,108.25,397.9Z"
        fill="#f7f7f7"
      />
    </g>

    <g class="dark:hidden block" id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M315.71,333.1A226.54,226.54,0,0,0,296,261.53a275.08,275.08,0,0,0-37.5-59.82c-4.29-5.26-8.64-10.3-10.89-12.62l-5.18-5.66c-1.27-1.45-2.33-2.62-3.38-3.65l-1.55-1.57c-1.7-1.7-3.44-3.47-4.89-5.24l-.36-.42c-.71-.77-1.46-1.54-2.31-2.4a45.59,45.59,0,0,1-3.46-3.76l-.44-.52c-7.89-8.68-14.53-16.56-20.29-24.1a160.35,160.35,0,0,1-15.46-23.86A109.5,109.5,0,0,1,180.09,89a176.73,176.73,0,0,1-3.58-32.43,272.07,272.07,0,0,1,1-29.53c.49-5.7,1-10.75,1.58-15.21A10.58,10.58,0,0,0,169.82.07a10.74,10.74,0,0,0-3.91.27,119.89,119.89,0,0,0-15.35,5.18A140,140,0,0,0,119.1,23.67a148.76,148.76,0,0,0-33.63,36A157,157,0,0,0,61.87,118c-4.45,23.08-4,49.13,1.38,73.3,5.06,23.4,14.86,47.29,28.39,69.17a250.3,250.3,0,0,0,20.78,28.91c2,2.51,7.59,9,7.65,9.08l2.22,2.55c1.55,1.76,2.66,3.09,3.8,4.44s1.91,2.28,2.87,3.3l.16.17c10.59,12.66,18,21.95,24.2,30.17,6.81,9.05,12.79,17.82,18.29,26.81a152,152,0,0,1,13.16,26.37A123.66,123.66,0,0,1,191.69,423a183.45,183.45,0,0,1,0,32.81c-1,11.3-2.74,21.61-4.13,29.16-1,5.17-2.05,9.93-3,14A10.61,10.61,0,0,0,197,511.78a110.07,110.07,0,0,0,14.49-3.85,141.09,141.09,0,0,0,32.39-15.51,149.12,149.12,0,0,0,36.27-32.56A163.78,163.78,0,0,0,309,405,185.69,185.69,0,0,0,315.71,333.1Z"
          fill="#ff7500"
        />
        <path
          d="M108.25,397.9a125.8,125.8,0,0,0-17.11-27.31c-1.75-2.14-3.86-4.6-4.89-5.67l-2.44-2.66c-.63-.72-1.09-1.22-1.58-1.71l-.67-.67c-.77-.77-1.54-1.56-2.2-2.34-.11-.14-.24-.28-.36-.42l-.92-.95c-.53-.55-1.06-1.08-1.51-1.64a4.82,4.82,0,0,0-.36-.43c-3.49-3.82-6.49-7.38-9.13-10.83a74.78,74.78,0,0,1-7.1-11,49.87,49.87,0,0,1-4.65-13.2,79.91,79.91,0,0,1-1.62-14.79,114.28,114.28,0,0,1,.32-11.7,10.58,10.58,0,0,0-15.1-10.31,67.27,67.27,0,0,0-11.44,7,68.16,68.16,0,0,0-15.33,16.41A71.52,71.52,0,0,0,1.38,332.34,84.44,84.44,0,0,0,2,365.82a102,102,0,0,0,13,31.63,114.16,114.16,0,0,0,9.39,13.08c.89,1.1,2.8,3.31,3.65,4.3h0l1,1.07c.75.84,1.27,1.46,1.78,2.09s.83,1,1.18,1.36l.17.18c3.26,3.9,7.47,9,11.06,13.79,3.12,4.14,5.85,8.14,8.34,12.23a68.27,68.27,0,0,1,6,12,56.93,56.93,0,0,1,3.17,14.1,84.15,84.15,0,0,1,0,14.89c-.28,3.34-.75,6.86-1.42,10.77a10.59,10.59,0,0,0,14.4,11.59,65.17,65.17,0,0,0,10.77-5.61A68.3,68.3,0,0,0,101,488.44a74.68,74.68,0,0,0,13.17-25,85.9,85.9,0,0,0,3.09-32.83A103.81,103.81,0,0,0,108.25,397.9Z"
          fill="#ff7500"
        />
      </g>
    </g>
  </svg>
</template>
