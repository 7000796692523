import moment from "moment";
import "moment-duration-format";
import { ec as EllipticCurve } from "elliptic";
import { bech32 } from "bech32";
import { keccak256 } from "ethers/lib/utils";
import { sha256 } from "js-sha256";
import crypto from "crypto-js";
import { BN } from "@flarenetwork/flarejs/dist";
import {
  UnsignedTx as EvmUnsignedTx,
  UTXOSet,
} from "@flarenetwork/flarejs/dist/apis/evm";
import { UnsignedTx as PvmUnsignedTx } from "@flarenetwork/flarejs/dist/apis/platformvm";

const ec = new EllipticCurve("secp256k1");
import { Defaults } from "@flarenetwork/flarejs/dist/utils";
import { WalletState } from "@/types";
import { WalletStore } from "@/types/global";

// TODO: Change to getter function?
const networkIDs = {
  // flare mainnet
  14: {
    xChainBlockchainID: "ecxi7p3JMYsx6abaYt7b9YiGbj6okQUs8QpqSxMKsFwEioff1",
    cChainBlockchainID: Defaults.network[14].C.blockchainID,
    pChainBlockchainID: Defaults.network[14].P.blockchainID,
    avaxAssetID: "2MxKSeEWXViLdYyDhW1SQ46AECZEbE2bnVRZptv42JrxqyUX5k",
  },
  // localflare
  162: {
    xChainBlockchainID: "2B32MMBcusfz3D5iciRoVzkMV7JPRBECyzoNwZUAjYj8F58BtP",
    cChainBlockchainID: "2rVfeJD9Y656bYJVvXei3a98iaivuP89z5i6y2Z7Wutp7rU8Yt",
    pChainBlockchainID: "11111111111111111111111111111111LpoYY",
    avaxAssetID: "2YxgZgcD6RRKycozwzY6Kce8ZqEHConLKhHRPtkB4ZqzmLD5qA",
  },
};
const chainID = 14;
export const xChainBlockchainID = networkIDs[chainID].xChainBlockchainID;
export const cChainBlockchainID = networkIDs[chainID].cChainBlockchainID;
export const pChainBlockchainID = networkIDs[chainID].pChainBlockchainID;
export const avaxAssetID = networkIDs[chainID].avaxAssetID;

const FLR = 1e9; // one FLR in nanoFLR
const MAX_TRANSCTION_FEE = FLR;

export function signUnsignedTxHash(address: string, txHash: string) {
  // Sign the transaction
  // @ts-ignore
  return window.ethereum.request({
    method: "eth_sign",
    params: [address, txHash],
  });
}

export function capFeeAt(usedFee?: string, specifiedFee?: string): void {
  if (usedFee !== undefined && usedFee !== specifiedFee) {
    // if usedFee was specified by the user, we don't cap it
    const usedFeeNumber = Number(usedFee); // if one of the fees is defined, usedFee is defined
    if (usedFeeNumber > MAX_TRANSCTION_FEE)
      throw new Error(
        `Used fee of ${
          usedFeeNumber / FLR
        } FLR is higher than the maximum allowed fee of ${
          MAX_TRANSCTION_FEE / FLR
        } FLR`
      );
  }
}

export function decimalToInteger(dec: string, offset: number): string {
  let ret = dec;
  if (ret.includes(".")) {
    const split = ret.split(".");
    ret = split[0] + split[1].slice(0, offset).padEnd(offset, "0");
  } else {
    ret = ret + "0".repeat(offset);
  }
  return ret;
}

export function integerToDecimal(int: string, offset: number): string {
  if (int === "0") {
    return "0";
  }
  int = int.padStart(offset, "0");
  const part1 = int.slice(0, -offset) || "0";
  const part2 = int.slice(-offset).replace(/0+$/, "");
  return part1 + (part2 === "" ? "" : "." + part2);
}

export function toBN(num: number | string | BN | undefined): BN | undefined {
  return num ? new BN(num) : undefined;
}

export function unPrefix0x(input: string) {
  return input.startsWith("0x") ? input.slice(2) : input;
}

function decodePublicKey(publicKey: string) {
  publicKey = unPrefix0x(publicKey);
  if (publicKey.length === 128) {
    publicKey = "04" + publicKey;
  }

  const keyPair = ec.keyFromPublic(publicKey, "hex").getPublic();

  const x = new Uint8Array(keyPair.getX().toArray(undefined, 32));
  const y = new Uint8Array(keyPair.getY().toArray(undefined, 32));

  return [x, y];
}

function uint8ArrayToHex(array: number[]): string {
  return Array.from(array)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
}

export function compressPublicKey(x: any, y: any): string {
  const publicKey = ec.keyFromPublic({
    x: uint8ArrayToHex(x),
    y: uint8ArrayToHex(y),
  });

  return publicKey.getPublic(true, "hex"); // 'true' means compressed
}

function publicKeyToBech32AddressBuffer(x: any, y: any) {
  const compressed = compressPublicKey(x, y);
  const match = compressed.match(/.{1,2}/g);
  if (match === null) {
    throw new Error("No matches found in the compressed public key.");
  }
  const compressedKeyArray = new Uint8Array(
    match.map((byte) => parseInt(byte, 16))
  );
  // Compute the SHA256 hash
  const sha256Hash = sha256(compressedKeyArray); // If compressed is a hex string, this will work. Otherwise, adjust accordingly.

  // Compute the RIPEMD160 hash using crypto-js
  const ripemd160Hash = crypto
    .RIPEMD160(crypto.enc.Hex.parse(sha256Hash))
    .toString(crypto.enc.Hex);

  return ripemd160Hash;
}

export function publicKeyToBech32AddressString(publicKey: string, hrp: string) {
  const [pubX, pubY] = decodePublicKey(publicKey);

  const addressBuffer = publicKeyToBech32AddressBuffer(pubX, pubY);

  const match = addressBuffer.match(/.{1,2}/g);
  if (match === null) {
    throw new Error("No matches found in the compressed public key.");
  }

  const addressBufferKeyArray = new Uint8Array(
    match.map((byte) => parseInt(byte, 16))
  );

  return `${bech32.encode(hrp, bech32.toWords(addressBufferKeyArray))}`;
}

export function pAddressToBytes20(pAddress: string) {
  return (
    "0x" +
    Buffer.from(bech32.fromWords(bech32.decode(pAddress).words)).toString("hex")
  );
}

export function publicKeyToPchainEncodedAddressString(publicKey: string) {
  const [pubX, pubY] = decodePublicKey(publicKey);
  const addressBuffer = publicKeyToBech32AddressBuffer(pubX, pubY);
  const match = addressBuffer.match(/.{1,2}/g);

  if (match === null) {
    throw new Error("No matches found in the compressed public key.");
  }

  const addressBufferKeyArray = new Uint8Array(
    match.map((byte) => parseInt(byte, 16))
  );

  return (
    "0x" +
    Array.from(addressBufferKeyArray)
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("")
  );
}

export function prefix0x(input: string) {
  if (input.startsWith("0x")) {
    return input;
  }
  return "0x" + input;
}

export function publicKeyToEthereumAddressString(publicKey: string) {
  const [pubX, pubY] = decodePublicKey(publicKey);

  // Concatenate the x and y coordinates
  const decompressedPubk = new Uint8Array([...pubX, ...pubY]);

  // Convert Uint8Array to hex string
  const pubkHex = Array.from(decompressedPubk)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  // Keccak-256 hash of the public key
  const keccakPubKey = keccak256("0x" + pubkHex);

  // Ethereum address is the last 20 bytes of the Keccak-256 hash
  const ethAddress = "0x" + keccakPubKey.slice(-40);

  return ethAddress;
}

export function expandSignature(signature: string) {
  let recoveryParam = parseInt(signature.slice(128, 130), 16);
  if (recoveryParam === 27 || recoveryParam === 28) recoveryParam -= 27;
  return {
    r: new BN(signature.slice(0, 64), "hex"),
    s: new BN(signature.slice(64, 128), "hex"),
    recoveryParam: recoveryParam,
  };
}

export function deserializeExportCP_args(
  serargs: string
): [BN, string, string, string, string, string[], number, BN, number, BN?] {
  const args = JSON.parse(serargs);
  [0, 7, 9].map((i) => (args[i] = new BN(args[i], 16)));
  return args;
}

export function serializeExportCP_args(
  args: [BN, string, string, string, string, string[], number, BN, number, BN?]
): string {
  return JSON.stringify(args, null, 2);
}

export function serializeUnsignedTx(
  unsignedTx: EvmUnsignedTx | PvmUnsignedTx
): string {
  return JSON.stringify(unsignedTx.serialize("hex"), null, 2);
}

export function deserializeImportPC_args(
  serargs: string
): [UTXOSet, string, string[], string, string[], BN] {
  const args = JSON.parse(serargs);
  const utxoSet = new UTXOSet();
  utxoSet.deserialize(args[0]);
  args[0] = utxoSet;
  args[5] = new BN(args[5], 16);
  return args;
}

export function deserializeUnsignedTx<
  UnsignedTx extends EvmUnsignedTx | PvmUnsignedTx
>(type: { new (): UnsignedTx }, serialized: string): UnsignedTx {
  const unsignedTx: UnsignedTx = new type();
  unsignedTx.deserialize(JSON.parse(serialized));
  return unsignedTx;
}

export function serializeImportPC_args(
  args: [UTXOSet, string, string[], string, string[], BN]
): string {
  return JSON.stringify([args[0].serialize("hex"), ...args.slice(1)], null, 2);
}

// Delegations

export async function delegationAddressCount(wallet: WalletState) {
  const current = await wallet.pchain.getCurrentValidators();
  const pending = await wallet.pchain.getPendingValidators();
  const pendingValidtaor = JSON.parse(JSON.stringify(pending));
  const pCurrent = JSON.parse(JSON.stringify(current));
  const count =
    countpAddressInDelegation(
      pCurrent.validators,
      wallet.accountKeys.addressPchain!
    ) +
    countpAddressInDelegation(
      pendingValidtaor.validators,
      wallet.accountKeys.addressPchain!
    );
  return count;
}

function countpAddressInDelegation(
  validators: any[],
  addressPchain: string
): number {
  let count = 0;
  for (const item of validators) {
    if (item.delegators) {
      for (const delegator of item.delegators) {
        count += delegator.rewardOwner.addresses.filter((addr: string) => {
          return addr.toLowerCase() === addressPchain.toLowerCase();
        }).length;
      }
    }
  }
  return count;
}

export async function accountDelegations(
  wallet: WalletStore
): Promise<AccountDelegations> {
  const current = await wallet.pchain.getCurrentValidators();
  const pending = await wallet.pchain.getPendingValidators();
  const { validators: pValidators }: { validators: Array<ValidatorItem> } =
    JSON.parse(JSON.stringify(pending));
  const { validators: cValidators }: { validators: Array<ValidatorItem> } =
    JSON.parse(JSON.stringify(current));

  const accountDelegations: Array<DelegatorItem> = new Array();

  for (const validator of cValidators) {
    if (!validator.delegators) continue;
    const foundDelegations = extractDelegation(
      validator.delegators,
      "P-" + wallet.accountKeys.addressPchain
    );
    accountDelegations.push(...foundDelegations);
  }

  for (const validator of pValidators) {
    if (!validator.delegators) continue;
    const foundDelegations = extractDelegation(
      validator.delegators,
      "P-" + wallet.accountKeys.addressPchain
    );
    accountDelegations.push(...foundDelegations);
  }

  const stakeSum = accountDelegations.reduce((accumulator, currentObj) => {
    const stakeAmount = integerToDecimal(currentObj.stakeAmount, 9);
    return accumulator + Number(stakeAmount);
  }, 0);

  const nodes =
    accountDelegations.map((delegation) => ({
      nodeID: delegation.nodeID,
      startTime: delegation.startTime,
      endTime: delegation.endTime,
      rewardAddress: delegation.rewardOwner.addresses[0],
      stakeAmount: integerToDecimal(delegation.stakeAmount, 9),
    })) || null;

  return { stakeSum, nodes };
}

function extractDelegation(
  delegations: Array<DelegatorItem>,
  addressPchain: string
) {
  const accountDelegations: Array<DelegatorItem> = [];
  for (const delegator of delegations) {
    const isUser = delegator.rewardOwner.addresses.filter((addr: string) => {
      if (addr.toLowerCase() === addressPchain.toLowerCase())
        return addr.toLowerCase() === addressPchain.toLowerCase();
    });
    if (isUser.length) {
      accountDelegations.push(delegator);
    }
  }
  return accountDelegations;
}

export interface AccountDelegations {
  stakeSum: number;
  nodes: Array<StakedNodes> | null;
}

export interface StakedNodes {
  nodeID: string;
  startTime: string;
  endTime: string;
  rewardAddress: string;
  stakeAmount: string;
}

interface DelegatorItem {
  txID: string;
  startTime: string;
  endTime: string;
  stakeAmount: string;
  nodeID: string;
  rewardOwner: {
    locktime: number;
    threshold: number;
    addresses: Array<string>;
  };
  potentialReward: string;
}

export interface ValidatorItem extends DelegatorItem {
  delegationFee: string;
  uptime: string;
  connected: boolean;
  delegators: Array<DelegatorItem> | null;
}

export function getTimeRemaining(endtime: number) {
  const now = moment(); // Current time
  const end = moment(endtime); // End time

  // Ensure the target date is in the future
  if (end.isBefore(now)) {
    return "Past";
  }

  // Calculate the remaining time
  const duration = moment.duration(end.diff(now));

  // Calculate each time component
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();

  // Build the time string manually
  let timeString = "";

  if (days > 0) {
    timeString += days + "d";
  }
  if (hours > 0) {
    timeString += (timeString ? ", " : "") + hours + "hr";
  } else if (hours == 0) {
    timeString += ", 0hr";
  }
  if (days == 0 && hours == 0) {
    timeString = duration.minutes().toString() + "min";
  }

  // If somehow both days and hours are zero here, you might want to add further checks for minutes, etc.
  if (!timeString) {
    timeString = "< 1 hour";
  }
  return timeString;
}
