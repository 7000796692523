<script setup lang="ts">
import "animate.css";
import { isMobile } from "mobile-device-detect";
import { ref } from "vue";
import { useNotificationStore } from "@/stores/notification";

const notification = useNotificationStore();
const screenType = ref("desktop");
const activeNotification = ref();
const locked = ref(false);

notification.$subscribe((mutation) => {
  // @ts-ignore
  if (mutation.events.type === "add") {
    newNotification();
  }
});

function newNotification() {
  if (!locked.value) {
    locked.value = true;

    activeNotification.value = notification.queue[0];
    showNotification();

    setTimeout(() => {
      closeNotification();
      setTimeout(() => {
        notification.processQueue();
        locked.value = false;
        if (notification.queue.length) {
          newNotification();
        }
      }, 501);
    }, activeNotification.value.timeout);
  }
}

function showNotification() {
  if (isMobile) {
    screenType.value = "mobile";
  } else {
    screenType.value = "desktop";
  }
  let notification = document.getElementById(`notification-banner`);
  if (isMobile) {
    window.scrollTo(0, 150);
  } else {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  if (!notification) return;
  notification.classList.remove("hidden");
  notification.classList.add(
    "animate__animated",
    "animate__fadeInRight",
    "animate__faster"
  );
}
function closeNotification() {
  let notification = document.getElementById(`notification-banner`);
  if (!notification) return;
  notification.classList.add("animate__animated", "animate__fadeOutRight");
  setTimeout(() => {
    if (!notification) return;
    notification.classList.add("hidden");
    notification.classList.remove(
      "animate__animated",
      "animate__fadeOutRight",
      "animate__fadeInRight",
      "animate__faster"
    );
  }, 500);
}
</script>

<template>
  <!-- Debugging -->
  <!-- N: {{ notification.queue.length }} N: {{ notification.queue[0] }}
  <div>Locked: {{ locked }}</div>
  <div v-for="n of notification.queue">{{ n.title }}</div> -->
  <div
    v-if="screenType == 'desktop'"
    class="bg-gray-200 dark:bg-gray-900 h-full z-20"
  >
    <div
      class="z-20 hidden xl:w-3/12 mx-auto sm:mx-0 sm:w-6/12 md:w-6/12 w-10/12 bg-gradient-to-r from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 shadow-lg rounded flex pr-4 absolute left-0 sm:left-auto right-0 sm:top-0 sm:mr-6 mt-16 sm:mt-6 mb-6 sm:mb-0 transition duration-150 ease-in-out"
      :id="`notification-banner`"
    >
      <div
        role="alert"
        aria-label="Close"
        class="cursor-pointer absolute right-0 mr-2 mt-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500 transition duration-150 ease-in-out"
        @click="closeNotification()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-x"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="2.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </div>
      <!-- Success Icon -->
      <div
        v-if="activeNotification?.type == 'success'"
        class="px-5 border-r border-gray-300 dark:border-gray-700 flex items-center justify-center text-green-400"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-7 w-7"
          fill="currentColor"
        >
          <path
            class="heroicon-ui"
            d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
          ></path>
        </svg>
      </div>
      <!-- Error Icon -->
      <div
        v-if="activeNotification?.type == 'error'"
        class="px-5 border-r border-gray-300 dark:border-gray-700 flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-7 w-7 text-red-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <!-- Alert Icon -->
      <div
        v-if="activeNotification?.type == 'alert'"
        class="px-5 border-r border-gray-300 dark:border-gray-700 flex items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-7 w-7 text-yellow-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
      </div>
      <!--  -->
      <div class="flex flex-col justify-center pl-4 py-4">
        <p class="text text-gray-800 dark:text-gray-100 font-semibold">
          {{ activeNotification?.title }}
        </p>
        <p class="text-sm text-gray-600 dark:text-gray-400 font-normal">
          {{ activeNotification?.message }}
        </p>
        <a
          target="_blank"
          class="text-sm text-orange-600 capitalize"
          v-if="activeNotification?.linkUrl"
          :href="activeNotification?.linkUrl"
          >{{ activeNotification?.linkText }}</a
        >
      </div>
    </div>
  </div>
  <div
    v-if="screenType == 'mobile'"
    class="z-50 w-full bg-gradient-to-r from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 shadow-lg rounded flex pr-4 absolute left-0 right-0 transition duration-150 ease-in-out"
    :id="`notification-banner`"
  >
    <div
      role="alert"
      aria-label="Close"
      class="cursor-pointer absolute right-0 mr-2 mt-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500 transition duration-150 ease-in-out"
      @click="closeNotification()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-x"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        stroke-width="2.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </div>
    <!-- Success Icon -->
    <div
      v-if="activeNotification?.type == 'success'"
      class="px-5 border-r border-gray-300 dark:border-gray-700 flex items-center justify-center text-green-400"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="h-7 w-7"
        fill="currentColor"
      >
        <path
          class="heroicon-ui"
          d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
        ></path>
      </svg>
    </div>
    <!-- Error Icon -->
    <div
      v-if="activeNotification?.type == 'error'"
      class="px-5 border-r border-gray-300 dark:border-gray-700 flex items-center justify-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-7 w-7 text-red-600"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </div>
    <!-- Alert Icon -->
    <div
      v-if="activeNotification?.type == 'alert'"
      class="px-5 border-r border-gray-300 dark:border-gray-700 flex items-center justify-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-7 w-7 text-yellow-500"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        />
      </svg>
    </div>
    <!--  -->
    <div class="flex flex-col justify-center pl-4 py-4">
      <p class="text-sm text-gray-800 dark:text-gray-100 font-semibold">
        {{ activeNotification?.title }}
      </p>
      <p class="text-xs text-gray-600 dark:text-gray-400 font-normal">
        {{ activeNotification?.message }}
      </p>
      <a
        class="text-sm text-orange-600 capitalize"
        target="_blank"
        v-if="activeNotification?.linkUrl"
        :href="activeNotification?.linkUrl"
        >{{ activeNotification?.linkText }}</a
      >
    </div>
  </div>
</template>
