<script setup lang="ts">
// @ts-ignore
import TheModal from "@/components/TheModal.vue";
import RoundedContainer from "@/components/RoundedContainer.vue";
import { useTruncateString as truncate } from "@/helpers/TruncateString";
import { ValidatatorListItem } from "@/helpers/ValidatorsList";
import { ref, onMounted, computed } from "vue";
import type { Ref } from "vue";
import { useNotificationStore } from "@/stores/notification";
import AppEmblem from "./icons/AppEmblem.vue";
import { useWalletStore } from "@/stores/wallet";
import {
  getValidatorList,
  PairedValidatorList,
} from "@/helpers/ValidatorsList";
import { getTimeRemaining } from "@/helpers/Utils";

const notification = useNotificationStore();
const wallet = useWalletStore();

defineExpose({ show, hide });
onMounted(async () => {
  hide();
  providersList.value = await getValidatorList(wallet);
  if (!providersList.value.length) return;
  let ftsoau = providersList.value.filter((i) => i.name == "FTSO AU 1")[0];
  let ftsoau2 = providersList.value.filter((i) => i.name == "FTSO AU 2")[0];

  providersList.value = providersList.value.filter(
    (i) => i.name != "FTSO AU 1" && i.name != "FTSO AU 2"
  );

  if (ftsoau2) providersList.value.unshift(ftsoau2);
  if (ftsoau) providersList.value.unshift(ftsoau);
});

const emit = defineEmits(["select"]);

const modal = ref();
const providersList: Ref<Array<PairedValidatorList>> = ref([]);
const search = ref("");
const customAddress = ref("");
const stakedNodeIds = wallet.staking.nodes
  ? wallet.staking.nodes.map((node) => node.nodeID.toLowerCase())
  : [];

const filteredList = computed((): Array<PairedValidatorList> => {
  if (!providersList.value.length) return [];
  return providersList.value.filter((i) =>
    i.name.toLowerCase().includes(search.value.toLowerCase())
  );
});

function select(provider: ValidatatorListItem) {
  emit("select", provider);
  hide();
}
function show() {
  modal.value.show();
}
function hide() {
  modal.value.hide();
}

function useCustomAddress() {
  const valid =
    customAddress.value.length == 40 && customAddress.value.includes("NodeID-");
  if (!valid) {
    return notification.create({
      type: "error", // 'success' or 'error' or 'alert'
      title: "Problem with custom address",
      message: "The provided address is not valid.",
      linkUrl: null,
      linkText: "",
      timeout: 5000,
    });
  }
  let provider = {
    name: "Custom Validator",
    address: customAddress.value,
    logoURI: null,
  };
  emit("select", provider);
  hide();
  customAddress.value = "";
}

function getValidatorCapacity(validator: any) {
  const validatorStakeAmount = Number(validator.stakeAmount) / 10 ** 9;
  const validatorStakeCapacity = Number(validatorStakeAmount) * 15;
  const delegatedAmount = !validator.delegators
    ? 0
    : validator.delegators.reduce(
        (acc: any, obj: any) => acc + Number(obj.stakeAmount) / 10 ** 9,
        0
      );

  return validatorStakeCapacity - (delegatedAmount + validatorStakeAmount);
}

function formatNumber(num: number) {
  if (num < 1000) {
    return num.toFixed(2); // return the same number if it's less than 1000
  } else if (num < 1000000) {
    return (num / 1000).toFixed(2) + "k"; // convert to 'k' for thousands
  } else {
    return (num / 1000000).toFixed(2) + "m"; // convert to 'm' for millions
  }
}
</script>

<template>
  <TheModal ref="modal" id="delegationProviderModal">
    <div class="relative">
      <p
        class="relative font-semibold text-gray-600 dark:text-gray-100 select-none pb-6"
      >
        Validators
      </p>
    </div>
    <div>
      <RoundedContainer class="mb-4">
        <input
          v-model="search"
          type="text"
          placeholder="Search"
          class="h-8 w-full focus:outline-none bg-transparent"
        />
      </RoundedContainer>
    </div>
    <div class="overflow-y-scroll md:scrollbox h-96">
      <!-- todo: optimise loading (all images are loaded, load providers in chunks) -->
      <table class="w-full">
        <tr>
          <th class="font-medium text-left pl-2">Node</th>
          <th class="font-medium text-right">Stake Duration</th>
          <th class="font-medium text-right pr-2">Free Stake</th>
        </tr>
        <tr
          v-for="provider in filteredList"
          :key="provider.address"
          @click="select(provider)"
          class="border-b border-gray-100 h-20 md:h-16 hover:bg-gray-50 dark:hover:bg-slate-700 cursor-pointer select-none"
        >
          <td class="pl-2">
            <div class="flex items-center relative">
              <AppEmblem
                v-if="provider.name.includes('FTSO AU')"
                class="w-5 h-5 mr-1"
              />
              {{ provider.name }}

              <span
                v-if="
                  stakedNodeIds.includes(
                    'nodeid-' + provider.address.toLowerCase()
                  )
                "
                class="text-gray-400 text-xs pt-1 pl-4"
                >STAKED</span
              >
            </div>
            <div class="text-sm font-['Courier'] opacity-60">
              {{ truncate(provider.address.replace("NodeID-", ""), 14, "...") }}
            </div>
          </td>
          <td class="text-right font-['Courier'] text-sm">
            {{ getTimeRemaining(Number(provider.endTime) * 1000) }}
          </td>
          <td class="text-right font-['Courier'] text-sm">
            {{ formatNumber(getValidatorCapacity(provider)) }} FLR
          </td>

          <!-- <td class="text-right pr-2 pl-4">
            <div class="text-sm font-['Courier']">
              {{ truncate(provider.address.replace("NodeID-", ""), 14, "...") }}
              <div class="text-xs pt-1 opacity-60">
                <span>Free Stake </span
                ><span class="font-semibold"
                  >{{ formatNumber(getValidatorCapacity(provider)) }} FLR
                </span>
              </div>
            </div>
          </td> -->
        </tr>
      </table>
      <div
        @click="select(provider)"
        class="hidden flex items-center justify-between border-b border-gray-100 h-20 md:h-16 px-3 hover:bg-gray-50 dark:hover:bg-slate-700 cursor-pointer select-none"
        v-for="provider in filteredList"
        :key="provider.address"
      >
        <div class="flex items-center">
          <AppEmblem
            v-if="provider.name.includes('FTSO AU')"
            class="w-5 h-5 mr-1"
          />
          <!-- <img
            class="w-6 mr-4"
            :src="provider.logoURI"
            alt=""
            loading="lazy"
          /> -->
          {{ provider.name }}

          <span
            v-if="
              stakedNodeIds.includes('nodeid-' + provider.address.toLowerCase())
            "
            class="text-gray-400 text-xs pt-1 pl-4"
            >STAKED</span
          >
        </div>
        <div class="hidden md:block text-sm text-gray-400 font-['Courier']">
          {{ provider.endTime }}
        </div>
        <div class="hidden md:block text-sm text-gray-400 font-['Courier']">
          NodeID-{{ truncate(provider.address, 14, "...") }}
        </div>
        <div class="md:hidden text-sm text-gray-400 font-['Courier']">
          {{ truncate(provider.address, 12, "...") }}
        </div>
      </div>
      <div class="mt-4 hidden">
        <div class="text-xs text-gray-400 pb-2">Use Custom Staking Address</div>
        <div class="flex">
          <RoundedContainer class="w-full mr-2">
            <input
              placeholder="NodeID-HZwFckGTbucqTtE7qcv6z85mnScUfAeW6"
              v-model="customAddress"
              type="text"
              class="h-8 w-full focus:outline-none bg-transparent"
            />
          </RoundedContainer>
          <button
            @click="useCustomAddress()"
            class="w-1/4 rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold px-2 text-sm md:text-base"
          >
            Stake
          </button>
        </div>
      </div>
    </div>
  </TheModal>
</template>

<style scoped>
.scrollbox {
  overflow: scroll;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background: /* Shadow covers */ linear-gradient(
      white 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    /* Shadows */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 200px, 100% 200px, 100% 10px, 100% 10px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}
</style>
